import { render, staticRenderFns } from "./PanelTrends.vue?vue&type=template&id=6d6ba411&"
import script from "./PanelTrends.vue?vue&type=script&lang=ts&"
export * from "./PanelTrends.vue?vue&type=script&lang=ts&"
import style0 from "./PanelTrends.vue?vue&type=style&index=0&id=6d6ba411&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports