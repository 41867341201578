
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Tooltip from "./Tooltip.vue";
import { longHover } from "./mixins/longHover";
import TagsInput from "./TagsInput.vue";

/**
 * Regexp for replacing all spaces in a string. Used for automatically generating input `name` attributes if one
 * isn't provided.
 */
const spaceRegexp = / /g;

/**
 * Component for a material-style text input (with coloured underline, floating/shrinking label text, etc)
 *
 * ![](media://textinput.png)
 */
@Component({
  name: "text-input",
  components: { TagsInput, Tooltip },
  // longHover provided logic for tooltip hover
  mixins: [longHover()],
})
export default class TextInput extends Vue {
  /**
   * Current value of the input. Most input [[type]]s expect strings, except `tags`  with expects an array.
   * @category Vue Prop
   */
  @Prop({ type: [String, Array], required: true }) readonly value!:
    | string
    | string[];

  /**
   * Label to be disabled on the input, and above it when it has text
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly label!: string;
  /**
   * [HTML input `name` attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
   * (makes autocomplete work)
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly name!: string;
  /**
   * Whether to disable this input and make it read-only
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  /**
   * Whether to show an underline when disabled. Used by the additional info panel when editing is disabled.
   * Defaults to `true`, meaning the underline is shown all the time.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: true }) readonly disabledUnderline!: boolean;
  /**
   * Whether the label should be pinned to the top, even when there isn't any [[value]] defined.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly pinned!: boolean;
  /**
   * Whether the width of the input should be determined by the [[value]], automatically resizing based on the content.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly dynamicWidth!: boolean;

  /**
   * Type of this input, determining what data (if any) can be entered into it. `text`, `email`, `password` and `date`
   * all use the HTML `<input>` tag with the
   * [`type` attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#%3Cinput%3E_types) set to
   * [[type]]. `tags` will use [[TagsInput]]. `static` will just display [[value]], but no allow it to be edited.
   * Defaults to `text`.
   * @category Vue Prop
   */
  @Prop({
    type: String,
    default: "text",
    validator(value: any) {
      // runtime check for input type (TypeScript (currently) doesn't validate these)
      return ["text", "email", "password", "tags", "date", "static"].includes(
        value
      );
    },
  })
  readonly type!: "text" | "email" | "password" | "tags" | "date" | "static";

  /**
   * Error to be displayed underneath the line. If this is set, the input will be red coloured not blue.
   *
   * ![](media://textinputerror.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly error!: string;

  /**
   * Whether this input has focus. When it does, the label will be transformed up and to the left, allowing
   * the user to enter text without it overlapping the label.
   * @category Vue Data
   */
  focused: boolean = false;
  /**
   * If this is a tags input, whether the new tag text input has text. When it does, the label will be transformed
   * as it would be if it were [[focused]].
   * @category Vue Data
   */
  tagsInputHasText: boolean = false;

  /**
   * Gets the `name` attribute for the input. If one isn't defined, defaults to the label in `kebab-case`. 🥙
   * @returns `name` attribute for the input
   * @category Vue Computed
   */
  get computedName() {
    return this.name || this.label.toLowerCase().replace(spaceRegexp, "-");
  }

  /**
   * Gets the `maxWidth` style for this input. If [[type]] is `date`, this is fixed at `140px`, otherwise it's dependent
   * on the content of the input. Returns `undefined` if [[dynamicWidth]] is disabled.
   * @returns `maxWidth` style for this input
   * @category Vue Computed
   */
  get computedDynamicMaxWidth() {
    if (this.dynamicWidth) {
      if (this.type === "date") {
        // dates should always have a fixed width
        return "140px";
      } else {
        // otherwise the width should be based on the content
        // ("1ch" is equal to the width of the "0" character in the current font)
        return Math.max(this.value.length, this.label.length * 0.7, 14) + "ch";
      }
    } else {
      // undefined will mean this style isn't rendered
      return undefined;
    }
  }

  /**
   * Event handler for key presses that emits `enter` events when the enter key is pressed. This is intended to be used
   * for submitting forms.
   * @param e - Event object containing the key that was pressed
   */
  onKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      this.$emit("enter");
    }
  }
}
