
import Vue from "vue";
import CardDialog from "../../../components/CardDialog.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Generic dialog for an informative notification. May be [[negative]] or [[info]]mative, determining which icon
 * will be displayed. Defaults to a green check. Accepts 3 named slots, `title`, `content`, and `button` which are for
 * the dialog title, body, and button text respectively. Emits a `next` event when the button is clicked. See also
 * [[CardDialog]] for the actual modal dialog component.
 *
 * ![](media://errorinfo.png)
 */
@Component({
  name: "dialog-information",
  components: { CardDialog },
})
export default class DialogInformation extends Vue {
  /**
   * Whether to display a blue information icon
   *
   * ![](media://dialoginfoicon.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly info!: boolean;
  /**
   * Whether to display a yellow warning icon
   *
   * ![](media://dialogwarningicon.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly warning!: boolean;
  /**
   * Whether to display a red cross icon
   *
   * ![](media://dialogcrossicon.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly negative!: boolean;

  /**
   * Whether to logout the user if they dismiss the error
   *
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly logout!: boolean;

  beforeDestroy() {
    if (this.logout) this.$router.push({ name: "logout" });
  }
}
