import Vue from "vue";
import Router, { Route } from "vue-router";

import ViewDashboard from "@/views/common/ViewDashboard.vue";
import PanelDetail from "@/views/panels/dashboard/detail/PanelDetail.vue";
import PanelList from "@/views/panels/dashboard/list/PanelList.vue";
import PanelDocumentation from "@/views/panels/dashboard/documentation/PanelDocumentation.vue";
import ViewLoginWrapper from "@/views/ViewLoginWrapper.vue";
import ViewUITest from "@/views/ViewUITest.vue";
import PanelLogin from "@/views/panels/login/PanelLogin.vue";
import PanelForgottenPassword from "@/views/panels/login/PanelForgottenPassword.vue";
import PanelRegister from "@/views/panels/login/PanelRegister.vue";
import PanelPasswordReset from "@/views/panels/login/PanelPasswordReset.vue";
import PanelPrivacy from "@/views/panels/dashboard/privacy/PanelPrivacy.vue";
import ProductSelection from "@/views/common/ProductSelection.vue";
import NtdInstructions from "@/views/ntd/NtdInstructions.vue";
import SelectHandset from "@/views/ntd/SelectHandset.vue";
import StartTest from "@/views/ntd/StartTest.vue";
import LoadingTest from "@/views/ntd/LoadingTest.vue";
import ResultsPage from "@/views/ntd/ResultsPage.vue";
import ResultsHistory from "@/views/ntd/ResultsHistory.vue";
import LogoutRedirect from "@/views/LogoutRedirect.vue";
import PrintPage from "@/views/ntd/PrintPage.vue";

Vue.use(Router);

/**
 * Main application router. See [Vue Router docs](https://router.vuejs.org/) for more information. Within each routes
 * `meta`data, there are 4 possible options.
 *
 * If `requiresLogin` is true, and the user isn't logged in, they'll be redirected to the login page. Similarly, if
 * it's false and the user is logged in, they'll be redirected to the list view.
 *
 * The `order` property is required and used to ensure route transitions work correctly:
 * if two routes have a different order property, there will be a transition between them. See [[App.onRouteChange]]
 * for more details.
 *
 * The `title` function is required and used to generate page titles, it gets passed the currentRoute as
 * the first argument. Again, see [[App.onRouteChange]] for more details.
 *
 * The `selectedProduct` property indicates the product being used. This is either ntc, unknown, or ntd
 */

// * vue-router 3 does not make it easy to namespace routes with
// prefixes (https://github.com/vuejs/vue-router/issues/2105)
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/", redirect: { name: "login" } },
    {
      path: "/logout",
      name: "logout",
      meta: { title: () => "Logout" },
      component: LogoutRedirect,
    },
    {
      path: "/login",
      component: ViewLoginWrapper,
      children: [
        {
          path: "",
          name: "login",
          component: PanelLogin,
          meta: {
            order: 0,
            title: () => "Login",
          },
        },
        {
          path: "register",
          name: "register",
          component: PanelRegister,
          meta: {
            order: 0,
            title: () => "Register",
          },
        },
        {
          path: "forgotten",
          name: "forgotten-password",
          component: PanelForgottenPassword,
          meta: {
            order: 0,
            title: () => "Forgotten Password",
          },
        },
        {
          path: "reset",
          name: "reset-password",
          component: PanelPasswordReset,
          meta: {
            order: 0,
            title: (route: Route) =>
              route.query.set ? "Set Password" : "Reset Password",
          },
        },
      ],
    },
    {
      path: "/ui",
      name: "ui",
      component: ViewUITest,
      meta: {
        requiresLogin: true,
        order: 2,
        title: () => "UI Components",
      },
    },
    {
      path: "/product",
      component: ViewDashboard,
      meta: { requiresLogin: true },
      children: [
        {
          path: "",
          component: ProductSelection,
          name: "product",
          meta: { title: () => "Product Selection", order: 1 },
        },
      ],
    },
    {
      path: "/eula-consent",
      name: "eulaConsent",
      component: ViewDashboard,
      meta: {
        requiresLogin: true,
        order: 1,
        title: () => "Consent to Eula",
      },
    },
    {
      path: "/",
      component: ViewDashboard,
      meta: { requiresRwDash: true, selectedProduct: "ntc" },
      children: [
        {
          path: "detail",
          name: "dashboard",
          component: PanelDetail,
          meta: {
            requiresLogin: true,
            requiresDetailPermission: true,
            order: 1,
            title: () => "",
          },
        },
        {
          path: "detail/:duid",
          name: "dashboard-with-duid",
          component: PanelDetail,
          meta: {
            requiresLogin: true,
            requiresDetailPermission: true,
            order: 1,
            title: (route: Route) => route.params.duid,
          },
        },
        {
          path: "list",
          name: "list",
          component: PanelList,
          meta: {
            requiresLogin: true,
            order: 1,
            title: () => "List",
          },
        },
        {
          path: "documentation",
          name: "documentation",
          component: PanelDocumentation,
          meta: {
            requiresLogin: true,
            order: 1,
            title: () => "Documentation",
          },
        },
        {
          path: "privacy",
          name: "privacy",
          component: PanelPrivacy,
          meta: {
            requiresLogin: true,
            order: 1,
            title: () => "Privacy Policy",
          },
        },
      ],
    },
    {
      path: "/nts",
      component: ViewDashboard,
      meta: {
        requiresLogin: true,
        requiresNtdUiPermission: true,
        selectedProduct: "ntd",
      },
      children: [
        { path: "", name: "ntd", redirect: { name: "ntd-handset" } },
        {
          path: "instructions",
          name: "ntd-instructions",
          component: NtdInstructions,
          meta: { order: 1, title: () => "Documentation" },
        },
        {
          path: "results/:capnogramId",
          name: "ntd-result-view",
          component: ResultsPage,
          meta: { order: 1, title: () => "Test Result" },
        },
        {
          path: "results-history",
          name: "ntd-results-history",
          component: SelectHandset,
          meta: {
            order: 1,
            title: () => "Results History",
          },
          children: [
            {
              path: ":udi",
              name: "ntd-handset-results",
              component: ResultsHistory,
              meta: { order: 1, title: () => "Result History" },
            },
          ],
        },
        {
          path: "handsets",
          name: "ntd-handset",
          component: SelectHandset,
          meta: {
            order: 1,
            title: () => "Handset",
          },
          children: [
            {
              path: ":udi/start-test",
              name: "ntd-start-test",
              component: StartTest,
              meta: { order: 1, title: () => "Start Test" },
            },
            {
              path: ":udi/loading-test",
              name: "ntd-loading-test",
              component: LoadingTest,
              meta: { order: 1, title: () => "Loading Test" },
            },
          ],
        },
        {
          path: "privacy",
          name: "ntd-privacy",
          component: PanelPrivacy,
          meta: {
            requiresLogin: true,
            order: 1,
            title: () => "Privacy Policy",
          },
        },
      ],
    },
    // If page doesn't match any routes, redirect to login
    { path: "*", redirect: { name: "login" } },
    {
      path: "/nts/:capnogramId/print",
      name: "print-test-result",
      component: PrintPage,
      meta: {
        title: () => "Print Test",
        requiresNtdUiPermission: true,
        selectedProduct: "ntd",
        requiresLogin: true,
      },
    },
  ],
});

export default router;
