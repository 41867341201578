import {
  APIUserInfoResponse,
  DUID,
  ErrorLevel,
  HandsetUDI,
  User,
} from "@/store/types";
import errorReporter from "@/errors";
import state, {
  DialogEULAState,
  State,
  UserKeys,
} from "@/store/internal/state";
import { DeviceData } from "@/store/fetcher";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";
import { DeviceSummaryStatName } from "@/store/fetcher/summaryStats";
import {
  LS_ACCESS_TOKEN,
  LS_REFRESH_TOKEN,
  LS_USER_ID,
  LS_USER_KEY,
} from "@/store/constants";

/**
 * Returns the decoded payload of a JWT. Completely ignores the header and signature. For example, if the token
 * was `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVGVzdCJ9.E7_2g0YuTeNMwAS55izHnYVg_gmPkaQq6efZVR_lQDk`, this
 * function would base-64 decode the middle section `eyJuYW1lIjoiVGVzdCJ9` and parse it as JSON, yielding
 * `{name: "Test"}`.
 * @param jwt - JWT to decode payload of
 * @returns Decoded and JSON parsed payload of the JWT
 */
function decodeJWT(jwt: string): any {
  return JSON.parse(atob(jwt.split(".")[1]));
}

/**
 * Sets the currently logged in user and stores their tokens in local storage if we're remembering them. Decodes
 * tokens to extract user information and expiry dates. If the tokens are falsey, the local storage is cleared instead.
 * This is intended to be used for logout.
 * @param state - State of the application [[src/store]] to mutate
 * @param rawAccessToken - Encoded access JWT
 * @param rawRefreshToken - Encoded refresh JWT
 * @param remember - Whether to store access & refresh tokens in local storage
 * @category Vuex Mutation
 */
export function SET_USER(
  state: State,
  {
    rawAccessToken,
    rawRefreshToken,
    remember,
  }: { rawAccessToken: string; rawRefreshToken: string; remember: boolean }
) {
  if (rawAccessToken) {
    // decode access JWT
    const accessToken: any = decodeJWT(rawAccessToken);
    const newUser: User = {
      accessToken: accessToken,
      accessTokenExp: new Date(accessToken.exp * 1000), // exp is in seconds
      rawAccessToken,
    };
    // persist access token in browser
    if (remember) {
      window.localStorage.setItem(LS_ACCESS_TOKEN, rawAccessToken);
    } else {
      window.localStorage.removeItem(LS_REFRESH_TOKEN);
    }

    if (rawRefreshToken && remember) {
      // decode refresh JWT if one was provided
      const refreshToken: any = decodeJWT(rawRefreshToken);
      newUser.refreshToken = refreshToken;
      newUser.refreshTokenExp = new Date(refreshToken.exp * 1000); // exp is in seconds
      newUser.rawRefreshToken = rawRefreshToken;
      // persist refresh token in browser
      window.localStorage.setItem(LS_REFRESH_TOKEN, rawRefreshToken);
    } else {
      // clear stored refresh token
      window.localStorage.removeItem(LS_REFRESH_TOKEN);
    }

    state.user = newUser;
    const userId = state.user.accessToken.user_id;
    if (userId) {
      errorReporter.setUser(userId.toString());
      if (remember) {
        window.localStorage.setItem(LS_USER_ID, userId);
      }
    }
  } else {
    state.user = null;
    errorReporter.setUser(undefined);

    // clear any stored user information
    window.localStorage.removeItem(LS_USER_ID);
    window.localStorage.removeItem(LS_ACCESS_TOKEN);
    window.localStorage.removeItem(LS_REFRESH_TOKEN);
  }
}

/**
 * Sets and stores in local storage the fernet encryption keys for additional info for each group
 * @param state - State of the application [[src/store]] to mutate
 * @param userKeys - Object containing fernet encryption keys for each group
 * @category Vuex Mutation
 */
export function SET_USER_KEY(state: State, userKeys: UserKeys) {
  state.userKeys = userKeys;
  if (userKeys === null) {
    window.localStorage.removeItem(LS_USER_KEY);
  } else {
    window.localStorage.setItem(LS_USER_KEY, JSON.stringify(userKeys));
  }
}

/**
 * Displays an error/message to the user as a modal dialog above everything else in the application
 * @param state - State of the application [[src/store]] to mutate
 * @param message - Body text of the dialog
 * @param level - Level of the error, will determine what icon is used and whether an application refresh is required
 * @category Vuex Mutation
 */
export function ADD_ERROR(
  state: State,
  {
    message,
    level = ErrorLevel.ERROR,
    logout = false,
  }: { message: string; level?: ErrorLevel; logout?: boolean }
) {
  // check if we've already got an error with the same message, if so, don't bother adding the error
  if (state.errors.some((error) => error.message === message)) return;

  // add an error (immutably)
  state.errors = [
    ...state.errors,
    {
      id: state.errorCount,
      message,
      level,
      logout,
    },
  ];
  // increment the count so no errors have the same ID (in one session)
  state.errorCount++;
}

/**
 * Removes an error from the store so it isn't displayed anymore
 * @param state - State of the application [[src/store]] to mutate
 * @param errorId - Identifier of the error object to remove
 * @category Vuex Mutation
 */
export function DISMISS_ERROR(state: State, errorId: number) {
  // remove an error (immutably)
  state.errors = state.errors.filter((e) => e.id !== errorId);
}

/**
 * Sets the user info (information: groups, permissions, etc) for the currently logged in user.
 * If any of the data isn't provided, it will default to `null`.
 * @param state - State of the application [[src/store]] to mutate
 * @param userInfo - Information about the currently logged in user. See [[State.userInfo]].
 * @category Vuex Mutation
 */
export function SET_USER_INFO(state: State, userInfo: APIUserInfoResponse) {
  state.userInfo = userInfo || null;
}
/**
 * Sets the user UDI list for the currently logged in user.
 * @param state - State of the application [[src/store]] to mutate
 * @param userUDIs - UDIs the user has access to. See [[State.userUDIs]].
 * @category Vuex Mutation
 */
export function SET_USER_UDIS(state: State, userUDIs: HandsetUDI[]) {
  state.userUDIs = userUDIs || null;
}
/**
 * Sets the user DUID list for the currently logged in user.
 * @param state - State of the application [[src/store]] to mutate
 * @param userDUIDs - DUIDs the user has access to. See [[State.userDUIDs]].
 * @category Vuex Mutation
 */
export function SET_USER_DUIDS(state: State, userDUIDs: DUID[]) {
  state.userDUIDs = userDUIDs || null;
}

/**
 * Updates a particular DUID in the store. Used for updating data in the list view when we recevie a new record via
 * Pusher.
 * @param state - State of the application [[src/store]] to mutate
 * @param userDUID - New version of a DUID the user has access to. See [[State.userDUIDs]].
 * @category Vuex Mutation
 */
export function SET_USER_DATA_DUID(state: State, userDUID: DUID) {
  if (state.userDUIDs !== null) {
    // Update the array (immutably so Vuex notices the change)
    state.userDUIDs = state.userDUIDs.map((duid) => {
      if (duid.duid === userDUID.duid) {
        // If this is the DUID we're updating, update it
        return userDUID;
      } else {
        // Otherwise, return the existing DUID for this
        return duid;
      }
    });
  }
}

/**
 * Replaces the DUID in the store matching the passed DUID object's string DUID with the passed DUID object. 😕
 * @param state - State of the application [[src/store]] to mutate
 * @param duid - DUID to update
 * @category Vuex Mutation
 */
export function UPDATE_USER_DUID(state: State, duid: DUID) {
  state.userDUIDs =
    state.userDUIDs === null
      ? null
      : state.userDUIDs.map((d) => (d.duid === duid.duid ? duid : d));
}

/**
 * Sets the current DUID/user data (summary stats, waveform, capnogram, trends) for the selected DUID. If any of the
 * data isn't provided, it will default to `null`. See [[DeviceData]].
 * @param state - State of the application [[src/store]] to mutate
 * @param deviceDUID - Currently selected DUID
 * @param deviceSummaryStats - Summary statistics/parameters associated with the last capnogram for the DUID
 * @param deviceWaveform - Average waveforms for the last capnograms for the DUID
 * @param deviceCapnogram - Last full capnogram trace for the DUID
 * @param deviceCapnogramStartTime - Time last breath record was taken for the DUID
 * @param deviceTrends - Data of previous summary statistics of capnograms for the DUID
 * @category Vuex Mutation
 */
export function SET_DEVICE_DATA(
  state: State,
  {
    deviceCapnogramId,
    deviceDUID,
    deviceSummaryStats,
    deviceWaveform,
    deviceCapnogram,
    deviceCapnogramStartTime,
    deviceTrends,
  }: DeviceData
) {
  state.deviceCapnogramId = deviceCapnogramId || null;
  state.deviceDUID = deviceDUID || null;
  state.deviceSummaryStats = deviceSummaryStats || null;
  state.deviceWaveform = deviceWaveform || null;
  state.deviceCapnogram = deviceCapnogram || null;
  state.deviceCapnogramStartTime = deviceCapnogramStartTime || null;
  state.deviceTrends = deviceTrends || null;
}

/**
 * Sets the additional info of the currently selected DUID
 * @param state - State of the application [[src/store]] to mutate
 * @param additionalInfo - New additional info for the DUID
 * @category Vuex Mutation
 */
export function SET_DEVICE_ADDITIONAL_INFO(
  state: State,
  additionalInfo: DeviceAdditionalInfo
) {
  state.deviceAdditionalInfo = additionalInfo;
}

/**
 * Sets the selected [[DeviceSummaryStatName | trend name]]. This will determine which trend series is displayed.
 * This information should also be stored in the route hash, but that's pretty unreliable.
 * @param state - State of the application [[src/store]] to mutate
 * @param selectedTrendName - New trend to display
 * @category Vuex Mutation
 */
export function SET_SELECTED_TREND(
  state: State,
  selectedTrendName: DeviceSummaryStatName
) {
  state.selectedTrendName = selectedTrendName;
}

/**
 * Shows/hides the EULA dialog, with buttons depending on the passed payload.
 * @param state - State of the application [[src/store]] to mutate
 * @param dialogState - Whether to show/hide the dialog, and with what buttons
 * @category Vuex Mutation
 */
export function SHOW_EULA_DIALOG(
  state: State,
  dialogState = DialogEULAState.VISIBLE_CLOSE
) {
  state.dialogEULA = dialogState;
}

/**
 * Shows/hides the product labelling dialog.
 * @param state - State of the application [[src/store]] to mutate
 * @param dialogState - Whether to show/hide the dialog
 * @category Vuex Mutation
 */
export function SET_PRODUCT_LABELING_DIALOG(
  state: State,
  dialogState: boolean
) {
  state.dialogProductLabelling = dialogState;
}
/*
 * Resets the state of the keys (with exceptions).
 * @param exempt - Keys free from resetting (e.g. error messages)
 * @category Vuex Mutation
 */
export function RESET_STATE(_state: State, exempt: Array<keyof State>) {
  const newState = state();
  for (const key of exempt) {
    delete newState[key];
  }
  Object.assign(_state, newState);
}
