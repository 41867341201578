
import Vue from "vue";
import Component from "vue-class-component";
import PanelHeader from "@/views/common/PanelHeader.vue";
import DialogDeviceAllocation from "@/views/panels/dashboard/DialogDeviceAllocation.vue";
import DialogParticipantTraining from "@/views/panels/dashboard/DialogParticipantTraining.vue";
import DialogProductLabelling from "@/views/panels/dashboard/DialogProductLabelling.vue";
import CardDialogBackground from "@/components/CardDialogBackground.vue";
import DialogInformation from "@/views/panels/dashboard/DialogInformation.vue";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";
import { APIUserGroup } from "@/store/types";
import DialogConfirmAllocation from "@/views/panels/dashboard/DialogConfirmAllocation.vue";
import Sidebar from "@/views/common/Sidebar.vue";
import { State } from "vuex-class";
import DialogEula from "@/views/panels/login/DialogEULA.vue";
import { DialogEULAState } from "@/store/internal/state";

/**
 * Main dashboard view. Container for the list view, detail view and documentation (basically everything the user
 * sees when their logged in). Uses child routes and another `<router-view>` component. Also contains logic for device
 * allocation, since this can happen at any point when the user is logged in, provided they have the correct permission.
 */
@Component({
  name: "view-dashboard",
  components: {
    DialogEula,
    Sidebar,
    DialogConfirmAllocation,
    DialogInformation,
    CardDialogBackground,
    DialogParticipantTraining,
    DialogProductLabelling,
    DialogDeviceAllocation,
    PanelHeader,
  },
})
export default class ViewDashboard extends Vue {
  /**
   * Whether the sidebar is currently expanded
   * @category Vue Data
   */
  sidebarExpanded: boolean = false;

  // information of device currently being allocated
  /**
   * Currently selected handset UDI to allocate to the new DUID.
   * @category Vue Data
   */
  deviceAllocationUDI: string | null = null;
  /**
   * Currently selected group to allocate the handset to.
   * @category Vue Data
   */
  deviceAllocationGroup: APIUserGroup | null = null;
  /**
   * Number after the selected group's project ID.
   * @category Vue Data
   */
  deviceAllocationDUIDSuffix: string = "";
  /**
   * Default for the number after the selected group's project ID.
   * @category Vue Data
   */
  deviceAllocationDUIDSuffixDefault: number = -1;
  /**
   * First name for additional info for the currently allocating DUID.
   * @category Vue Data
   */
  deviceAllocationFirstName: string = "";
  /**
   * Last name for additional info for the currently allocating DUID.
   * @category Vue Data
   */
  deviceAllocationLastName: string = "";
  /**
   * Date of birth for additional info for the currently allocating DUID.
   * @category Vue Data
   */
  deviceAllocationDOB: string = "";
  /**
   * Hospital number for additional info for the currently allocating DUID.
   * @category Vue Data
   */
  deviceAllocationHospitalNumber: string = "";
  /**
   * If the handset is already allocated, this must be set to `true` before the device can be allocated.
   * @category Vue Data
   */
  deviceAllocationOverrideAssigned: boolean = false;
  /**
   * If the DUID already exists, this must be set to `true` before the device can be allocated.
   * @category Vue Data
   */
  deviceAllocationOverrideUserId: boolean = false;
  /**
   * Make sure the user has cleaned the device, this must be set to `true` before the device can be allocated.
   * @category Vue Data
   */
  deviceAllocationCleaningCheck: boolean = false;
  // dialog active states
  /**
   * Whether the handset allocation dialog is currently visible. See [[DialogDeviceAllocation]].
   * @category Vue Data
   */
  dialogDeviceAllocationActive: boolean = false;
  /**
   * Whether the confirm allocation dialog is currently visible. See [[DialogConfirmAllocation]].
   * @category Vue Data
   */
  dialogConfirmAllocationActive: boolean = false;
  /**
   * Whether the participant training dialog is currently visible. See [[DialogParticipantTraining]].
   * @category Vue Data
   */
  dialogParticipantTrainingActive: boolean = false;
  /**
   * See [[State.dialogEULA]]
   * @category Vuex Binding
   */
  @State("dialogEULA") readonly dialogEULA!: DialogEULAState;
  /**
   * See [[State.dialogProductLabelling]]
   * @category Vuex Binding
   */
  @State("dialogProductLabelling") readonly dialogProductLabelling!: boolean;

  /**
   * Full DUID to needs to be allocated. Made from the concatenation of the selected group, a dash and the DUID
   * suffix.
   * @category Vue Computed
   * @returns Full DUID that is to be allocated
   */
  get deviceAllocationDUID(): string {
    // @ts-ignore
    return `${this.deviceAllocationGroup.projectid}-${this.deviceAllocationDUIDSuffix}`;
  }

  /**
   * Gets the [[DeviceAdditionalInfo]] object containing the entered additional information in
   * [[DialogDeviceAllocation]]. This requires joining the first and last name together first.
   * @returns An object containing all the entered additional information
   */
  get deviceAllocationAdditionalInfo(): DeviceAdditionalInfo {
    // build the full name from the provided information, including a space if required
    let name = this.deviceAllocationFirstName;
    if (this.deviceAllocationFirstName && this.deviceAllocationLastName) {
      name += " ";
    }
    name += this.deviceAllocationLastName;

    return {
      name: name,
      dob: this.deviceAllocationDOB,
      hospitalNumber: this.deviceAllocationHospitalNumber,
    };
  }

  /**
   * Resets all handset allocation state to the default values, hiding all dialogs too.
   */
  resetDeviceAllocation() {
    this.deviceAllocationUDI = null;
    this.deviceAllocationGroup = null;
    this.deviceAllocationDUIDSuffix = "";
    this.deviceAllocationDUIDSuffixDefault = -1;
    this.deviceAllocationFirstName = "";
    this.deviceAllocationLastName = "";
    this.deviceAllocationDOB = "";
    this.deviceAllocationHospitalNumber = "";
    this.deviceAllocationOverrideAssigned = false;
    this.deviceAllocationOverrideUserId = false;
    this.deviceAllocationCleaningCheck = false;
    this.dialogDeviceAllocationActive = false;
    this.dialogConfirmAllocationActive = false;
    this.dialogParticipantTrainingActive = false;
  }

  /**
   * Handler for the next button in [[DialogDeviceAllocation]]. Hides the handset allocation dialog and shows the
   * confirm allocation one.
   */
  deviceAllocationNext() {
    this.dialogDeviceAllocationActive = false;
    this.dialogConfirmAllocationActive = true;
  }

  /**
   * Handler for the back button in [[DialogConfirmAllocation]]. Hides the confirm allocation dialog and shows the
   * handset allocation one.
   */
  deviceAllocationConfirmBack() {
    this.dialogConfirmAllocationActive = false;
    this.dialogDeviceAllocationActive = true;
  }

  /**
   * Handler for the next button in [[DialogConfirmAllocation]]. Hides the confirm allocation dialog and shows the
   * participant training one.
   */
  deviceAllocationConfirmNext() {
    this.dialogConfirmAllocationActive = false;
    this.dialogParticipantTrainingActive = true;
  }

  /**
   * Handler for the complete button in [[DialogParticipantTraining]]. Just hides the participant training dialog.
   */
  deviceAllocationParticipantTrainingComplete() {
    this.dialogParticipantTrainingActive = false;
  }

  // NTC dashboard re-transitions the inner component of the dashboard when the url changes
  // NTD dashboard re-transitions whenever the the secondary route changes. The
  // first match is `/ntc`, and the second corresponds to the page. Else, use
  // the same as ntc.
  getRouterViewKey() {
    if (this.isProductNtd) {
      const routeTree = this.$route.matched;
      if (routeTree.length >= 2) {
        return routeTree[1].name;
      }
    }
    return this.$route.path;
  }

  get isProductNtd(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntd");
  }

  get isProductNtc(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntc");
  }
}
