
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import {
  NTC_DASHBOARD_IFU_FILE,
  DASHBOARD_ASSET_BUCKET_PREFIX,
  NTH_HANDSET_IFU_FILE,
  NTH_HANDSET_QSG_FILE,
} from "@/config";
import Loader from "@/components/Loader.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import { APIUserInfoResponse } from "@/store/types";

/**
 * Panel for showing product documentation (also includes the footer).
 * Files are located in the `public/files` directory.
 *
 * ![](media://paneldocumentation.png)
 */
@Component({
  name: "panel-documentation",
  components: {
    Loader,
    PanelFooter,
  },
})
export default class PanelDocumentation extends Vue {
  dashboardIFUSrc = `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTC_DASHBOARD_IFU_FILE}`;
  handsetIFUSrc = `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTH_HANDSET_IFU_FILE}`;
  handsetQuickStartGuideSrc = `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTH_HANDSET_QSG_FILE}`;

  /**
   * See [[State.userInfo]]
   * @category Vuex Binding
   */
  @State("userInfo") readonly userInfo!: APIUserInfoResponse | null;
}
