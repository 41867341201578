
import Vue from "vue";
import Dropdown from "@/components/Dropdown.vue";
import Checkbox from "@/components/Checkbox.vue";
import Toggle from "@/components/Toggle.vue";
import TextInput from "@/components/TextInput.vue";
import Chart from "@/components/graphics/Chart.vue";
import {
  autoScale,
  ChartAnnotation,
  ChartDataPoint,
  ChartDataScales,
  ChartDataSeries,
} from "@/components/graphics/utils";
import PercentCircle from "@/components/PercentCircle.vue";
import Component from "vue-class-component";

/**
 * View containing all UI components for testing. Ironically not actually used in any of the E2E/unit tests, would
 * be a good candidate for visual regression testing though.
 */
@Component({
  name: "view-ui-test",
  components: {
    PercentCircle,
    TextInput,
    Toggle,
    Checkbox,
    Dropdown,
    Chart,
  },
})
export default class ViewUITest extends Vue {
  /**
   * Currently selected dropdown option.
   * @category Vue Data
   */
  dropdownValue = "";
  /**
   * Currently input text.
   * @category Vue Data
   */
  text = "";
  /**
   * Currently input tags.
   * @category Vue Data
   */
  tags = [];
  /**
   * Current checkbox/toggle state.
   * @category Vue Data
   */
  checkboxValue = false;
  /**
   * Series for test chart. Initialised in [[created]] hook.
   * @category Vue Data
   */
  randomSeries: ChartDataSeries<ChartDataPoint> | null = null;
  /**
   * Scales for test chart. Initialised in [[created]] hook.
   * @category Vue Data
   */
  randomScales: ChartDataScales | null = null;
  /**
   * Annotations for test chart. Initialised in [[created]] hook.
   * @category Vue Data
   */
  randomAnnotations: ChartAnnotation[] = [];

  // noinspection JSUnusedGlobalSymbols
  /**
   * Initialise test chart data: [[randomSeries]], [[randomScales]] and [[randomAnnotations]].
   * @category Vue Lifecycle
   */
  created() {
    const series = [];
    let value = 50;
    for (let i = 0; i < 20; i++) {
      series.push({
        x: i,
        y: [value - 10, value, value + 10],
        colour: value < 33 ? "red" : value > 66 ? "green" : "yellow",
      });
      value += (Math.random() - 0.5) * 25;
      value = Math.min(100, Math.max(0, value));
    }
    this.randomSeries = { data: series };
    this.randomScales = autoScale(series);
    this.randomAnnotations = [
      {
        type: "background-rect",
        x: 5,
        x2: 15,
      } as ChartAnnotation,
    ];
  }
}
