import {
  autoScale,
  ChartDataPoint,
  ChartDataScales,
  ChartDataSeries,
  emptyChart,
  normY,
  scaleValue,
} from "@/components/graphics/utils";
import { DeviceDataFetchContext } from "@/store/fetcher/index";

/**
 * Value for pCO2 above which is considered expiration. Used when creating [[DeviceWaveformBottomAnnotation]] objects
 * for annotating inspiration/expiration on average waveform.
 *
 * ![](media://threshold.png)
 */
export const THRESHOLD = 0.5;

/**
 * Individual annotation for the bottom of the waveform topology section. There will usually be 3 of these per waveform.
 * The [[width]] values should sum to 100(%).
 *
 * ![](media://thresholdannotations.png)
 */
export interface DeviceWaveformBottomAnnotation {
  /**
   * Type of annotation, displayed in coloured rectangle. Inspiration is dark and under the [[THRESHOLD]], expiration
   * is light and above the [[THRESHOLD]].
   */
  type: "Expiration" | "Inspiration";
  /** Percentage width this annotation should take up of the available space. These should sum to 100 for a waveform. */
  width: number;
}

/**
 * Data object containing a processed capnogram average waveform (and optionally the previous capnogram's waveform).
 * Contains the series (for each capnogram), scales and annotations for inspiration and expiration (just for the
 * current one). See [[fetchWaveform]].
 */
export interface ChartAverageWaveform {
  /**
   * Array of series. Either contains 1 or 2 objects. If it contains 1, it's just the current one. If it contains 2,
   * it's the previous followed by the current one.
   */
  series: ChartDataSeries<ChartDataPoint>[];
  /**
   * Array of scales. Either contains 1 or 2 objects. If it contains 1, it's just the current one. If it contains 2,
   * it's the previous followed by the current one.
   */
  scales: ChartDataScales[];
  /** Annotations recording where inspiration/expiration sections begin and end. */
  bottomAnnotations: DeviceWaveformBottomAnnotation[];
}

/**
 * Processes the average waveform associated with the current capnogram in the passed context. If a previous capnogram
 * is also provided, will process that too.
 * @param ctx - Context containing current and previous capnograms to extract average waveforms from
 * @returns Processed waveform(s) containing series, scales and current annotations
 */
export async function fetchWaveform(
  ctx: DeviceDataFetchContext
): Promise<ChartAverageWaveform> {
  if (ctx.capnogram.data_avg_waveform === null) {
    const empty = emptyChart<ChartAverageWaveform>(true);
    empty.bottomAnnotations = [];
    return empty;
  }

  // get current waveform and scales
  const waveformData = ctx.capnogram.data_avg_waveform.map(([x, y, stdev]) => ({
    x: x,
    y: [y - stdev, y, y + stdev],
  }));
  const waveformScales = autoScale(waveformData);

  // get previous waveform and scales if there if one, store this as a list so it can be mapped and spread later
  const previousWaveformData =
    ctx.previousCapnogram && ctx.previousCapnogram.data_avg_waveform !== null
      ? [
          {
            data: ctx.previousCapnogram.data_avg_waveform.map(
              ([x, y, _stdev]) => ({
                x: x,
                y: y,
              })
            ),
            lineColour: "#AAAAAA",
            areaColour: "rgba(128, 128, 128, 0.1)",
          } as ChartDataSeries<ChartDataPoint>,
        ]
      : [];
  const previousWaveformScales = previousWaveformData.map((data) =>
    autoScale(data.data)
  );

  // set all scales to use the same maximum y value
  const maxY = Math.max(
    waveformScales.y[1],
    ...previousWaveformScales.map((scales) => scales.y[1])
  );
  waveformScales.y[1] = maxY;
  previousWaveformScales.forEach((scales) => (scales.y[1] = maxY));

  const startX = waveformData[0].x;
  const endX = waveformData[waveformData.length - 1].x;

  // find the positions of "Expiration" and "Inspiration" on the waveform
  const bottomAnnotations: DeviceWaveformBottomAnnotation[] = [];
  let lastX = 0;
  let lastAboveThreshold = false;
  let i = 0;

  function recordAnnotation() {
    const x = scaleValue(waveformData[i].x, [startX, endX], 100);
    bottomAnnotations.push({
      type: lastAboveThreshold ? "Expiration" : "Inspiration",
      width: x - lastX,
    });
    lastX = x;
  }

  while (i < waveformData.length) {
    const y = normY(waveformData[i].y);
    const aboveThreshold = y > THRESHOLD;
    // if the threshold state changed, record an annotation
    if (aboveThreshold !== lastAboveThreshold) {
      recordAnnotation();
    }
    lastAboveThreshold = aboveThreshold;
    i++;
  }
  // backtrack to the last point
  i--;
  // record an annotation at the end with the current state
  recordAnnotation();

  return {
    // series earlier in the list are displayed behind others
    scales: [...previousWaveformScales, waveformScales],
    series: [
      ...previousWaveformData,
      {
        data: waveformData,
      },
    ],
    bottomAnnotations,
  };
}
