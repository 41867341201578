
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";

/**
 * Input component for specifying a set of discrete items: e.g. allowed domains. Allows the user to enter some text
 * then press `enter`/`tab`/`space` to add a tag. Pressing `backspace` with no text entered, will delete the last tag.
 *
 * ![](media://tagsinput.png)
 */
@Component({
  name: "tags-input",
})
export default class TagsInput extends Vue {
  /**
   * Reference to the new tag input. Exposed to allow focusing. See [[focus]].
   * @category Vue Ref
   */
  @Ref() readonly input!: HTMLInputElement;

  /**
   * Array of current tags. This does not include the content of the tag currently being edited.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  readonly value!: string[];

  /**
   * Content of the tag currently being edited.
   * @category Vue Data
   */
  newTag: string = "";

  /**
   * Styles for the new tag input box. Makes sure it's wide enough to contain the text.
   * @category Vue Computed
   * @returns Styles for the new tag input box
   */
  get newTagInputStyle() {
    return {
      width: `${this.newTag.length + 1}ch`, // 1ch = width of "0" (zero)
    };
  }

  /** Focuses the new tag input box. */
  focus() {
    this.input.focus();
  }

  /**
   * Handler for key events in the new tag input box. Pressing `enter`/`tab`/`space` will add the current contents
   * of the new tag box to the tags list, only if the new tag isn't empty. Pressing backspace will remove the last
   * tag in the tags list. In both cases, an `input` event will be emitted, containing the new tags list.
   * @param e - Event object containing the key that was pressed
   */
  onKeyDown(e: KeyboardEvent) {
    if (
      (e.key === "Enter" || e.key === "Tab" || e.key === " ") &&
      this.newTag !== ""
    ) {
      e.preventDefault();
      // build a new tag and add it to the tags list
      this.$emit("input", [...this.value, this.newTag]);
      // reset tag input
      this.newTag = "";
    }
    if (e.key === "Backspace" && this.newTag === "") {
      e.preventDefault();
      // remove the last tag
      this.$emit("input", this.value.slice(0, this.value.length - 1));
    }
  }

  /**
   * Watcher for the new tag input box's content. Emits an `input-new-tag` emit with the new content. Used by
   * [[TextInput]] to make sure the label stays up when the field has text.
   * @param value - New content of the tag input box
   * @category Vue Watch
   */
  @Watch("newTag")
  onNewTagChange(value: string) {
    this.$emit("input-new-tag", value);
  }
}
