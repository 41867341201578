
import Vue from "vue";
import Component from "vue-class-component";
import { FOOTER_WARNING } from "@/config";

/**
 * Wrapper for login, register and password reset views. Includes the product graphic and the research use only notice.
 * See src/views/panels/login modules for the individual route components.
 */
@Component({
  name: "view-login-wrapper",
})
export default class ViewLoginWrapper extends Vue {
  /**
   * Gets the title of the application from the configuration.
   * @returns title
   */

  /**
   * Footer warning from the configuration
   * @returns warning message
   */
  get footerWarning(): string {
    return FOOTER_WARNING;
  }

  get isProductNtc(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntc");
  }
}
