
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component for a clickable checkbox that has 2 states: active or not. The styles for this component are located in
 * `@/styles/_inputs.sass`. *(TODO: move these into this component)*
 * See [[ViewUITest]] for example usage.
 *
 * ![](media://checkbox.png)
 */
@Component({
  name: "checkbox",
})
export default class Checkbox extends Vue {
  /**
   * Current state of the checkbox: true is checked/active, false is unchecked.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly value!: boolean;
  /**
   * Whether the checkbox is disabled and cannot have its state changed. This will grey it out, but keep the last value.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  /** Handler for clicking the checkbox. Toggles the current value emitting an `input` event with the new value. */
  toggle() {
    if (!this.disabled) {
      this.$emit("input", !this.value);
    }
  }
}
