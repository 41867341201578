
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component representing a tooltip to be displayed beneath another component. Positioned relative to the other
 * component with left/center/right alignment. See [[longHover]] for a way of implementing long hover to activate
 * this component.
 *
 * ![](media://tooltip.png)
 */
@Component({
  name: "tooltip",
})
export default class Tooltip extends Vue {
  /**
   * Whether to align this tooltip to the left. Only one of [[left]] and [[right]] should be `true`.
   *
   * ![](media://tooltipleft.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly left!: boolean;
  /**
   * Whether to align this tooltip to the right. Only one of [[left]] and [[right]] should be `true`.
   *
   * ![](media://tooltipright.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly right!: boolean;
}
