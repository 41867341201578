import { ENABLE_RAG_STATUS } from "@/config";
import { formatDate, timescaleCodes } from "@/components/graphics/date";
import {
  colourStatValue,
  DeviceSummaryStatColouringLine,
  DeviceSummaryStatName,
  statColouringLines,
  statGraphRanges,
  statGraphRangesMaximums,
  statNames,
} from "./summaryStats";
import {
  ChartDataPoint,
  ChartDataScales,
  ChartDataSeries,
} from "@/components/graphics/utils";
import { DeviceDataFetchContext } from "@/store/fetcher/index";

/**
 * List of timescales available to select from the trends graph. Builds objects containing the name of the timescale
 * and the number of milliseconds it represents, using [[timescaleCodes]].
 */
export const availableTimescales = ["1D", "2D", "1W", "1M", "6M", "ALL"].map(
  (name) => ({
    name,
    range:
      name === "ALL" ? Infinity : parseInt(name[0]) * timescaleCodes[name[1]],
  })
);

/** [[ChartDataPoint]] object containing additional date data that's useful when plotting trends */
export interface DeviceTrendDataPoint extends ChartDataPoint {
  /** Date object corresponding to the x-coordinate (which will be a unix timestamp in milliseconds) */
  xDate: Date;
  /** Formatted version of [[xDate]] using [[formatDate]] with full month names */
  xDateFormatted: string;
}

/**
 * Container for the trend of a single summary statistic. See [[DeviceTrends]] for the object containing one of
 * these for each [[DeviceSummaryStatName]].
 */
export interface DeviceTrendSeries {
  /** Series using the custom [[DeviceTrendDataPoint]] containing additional date data */
  series: ChartDataSeries<DeviceTrendDataPoint>;
  /** Scales for this trend */
  scales: ChartDataScales;
  /** Maximum series space y-value this trend contains */
  maxYValue: number;
  /** Whether to show the [[statColouringLines]] that are coloured yellow */
  showYellowLines: boolean;
  /** Whether to show the [[statColouringLines]] that are coloured red */
  showRedLines: boolean;
  /** The [[statColouringLines]] that should be shown for this particular trend */
  lines: DeviceSummaryStatColouringLine[];
}

/** Container for all trends for a DUID */
export type DeviceTrends = {
  [key in DeviceSummaryStatName]: DeviceTrendSeries;
};

/**
 * Uses the `ctx`'s [[APIClient]] to fetch the trends for the current DUID, then builds a trend for each of the
 * summary statistics.
 * @param ctx - Context containing the [[APIClient]] to use the fetch the `ctx`'s
 * [[DeviceDataFetchContext.duid | duid]]'s trends
 * @returns Processed trend for each of the summary stats, with scales, series and additional line annotations
 */
export async function fetchTrends(
  ctx: DeviceDataFetchContext
): Promise<DeviceTrends> {
  // start out with each trend being empty
  // (this is a function so if vue makes everything reactive, updating one thing won't update the others,
  // not that this data should be updated anyways, it's meant to be immutable ;) )
  const emptyProcessedTrend = () =>
    ({
      series: { data: [] },
      scales: { x: [0, 0], y: [0, 1] },
      maxYValue: 0,
      showYellowLines: false,
      showRedLines: false,
      lines: [],
    } as DeviceTrendSeries);
  const processedTrendData = {
    "END TIDAL CO2": emptyProcessedTrend(),
    "RESPIRATORY RATE": emptyProcessedTrend(),
    "METABOLIC BURDEN": emptyProcessedTrend(),
    "OBSTRUCTIVE INDEX": emptyProcessedTrend(),
  } as DeviceTrends;

  // check we have a duid before proceeding
  if (!ctx.duid) return processedTrendData;

  // get trend data for duid
  const trendData = await ctx.client.getDUIDTrends(ctx.duid);
  if (!trendData.data) {
    return processedTrendData;
  }

  // process trend data response
  for (let i = trendData.data.length - 1; i >= 0; i--) {
    // (nice automatic formatting here :) )
    const { capnogram_dt, data_par_breath_avg, data_par_cap } =
      trendData.data[i];
    const date = new Date(capnogram_dt);
    const dateMilliseconds = date.getTime();
    const formattedDate = formatDate(date);

    // adds a given data point to the trend array
    const processTrendDataPoint = (
      trendName: DeviceSummaryStatName,
      dataPoint: number | number[],
      forceSingleValue = false
    ) => {
      // if this data point has undefined data, skip it
      if (
        dataPoint === undefined ||
        dataPoint === null ||
        (Array.isArray(dataPoint) && dataPoint[0] === null)
      ) {
        return;
      }

      // record max value
      const thisMaxYValue = Array.isArray(dataPoint)
        ? forceSingleValue
          ? dataPoint[0]
          : dataPoint[0] + dataPoint[1]
        : dataPoint;
      if (thisMaxYValue > processedTrendData[trendName].maxYValue) {
        processedTrendData[trendName].maxYValue = thisMaxYValue;
      }

      // get colour of the point if the RAG status is enabled
      const colour = ENABLE_RAG_STATUS
        ? colourStatValue(
            trendName,
            Array.isArray(dataPoint) ? dataPoint[0] : dataPoint
          )
        : "dark";
      if (colour === "yellow") {
        processedTrendData[trendName].showYellowLines = true;
      } else if (colour === "red") {
        processedTrendData[trendName].showYellowLines = true;
        processedTrendData[trendName].showRedLines = true;
      }

      // record data point
      processedTrendData[trendName].series.data.push({
        x: dateMilliseconds,
        xDate: date,
        xDateFormatted: formattedDate,
        y: Array.isArray(dataPoint)
          ? forceSingleValue
            ? dataPoint[0]
            : [
                dataPoint[0] - dataPoint[1],
                dataPoint[0],
                dataPoint[0] + dataPoint[1],
              ]
          : dataPoint,
        colour,
      });
    };

    // only add the trend data points if they're defined and this isn't from the clock reset time
    if (data_par_breath_avg !== null && date.getFullYear() !== 2000) {
      processTrendDataPoint("END TIDAL CO2", data_par_breath_avg.PetCO2);
      processTrendDataPoint("METABOLIC BURDEN", data_par_breath_avg.metBurden);
      processTrendDataPoint(
        "OBSTRUCTIVE INDEX",
        data_par_breath_avg.epTangent,
        true
      );
    }
    if (data_par_cap !== null && date.getFullYear() !== 2000) {
      processTrendDataPoint("RESPIRATORY RATE", data_par_cap.RR_est);
    }
  }

  // add correct lines
  for (const trendName of statNames) {
    const trend = processedTrendData[trendName];

    // get range
    // eslint-disable-next-line prefer-const
    let [min, max] = statGraphRanges[trendName];
    // clamp to max value
    if (max > statGraphRangesMaximums[trendName]) {
      max = statGraphRangesMaximums[trendName];
    }
    const firstPoint = trend.series.data[0];
    const lastPoint = trend.series.data[trend.series.data.length - 1];
    trend.scales = {
      x: [firstPoint ? firstPoint.x : 0, lastPoint ? lastPoint.x : 0],
      y: [min, max],
    };

    // build correct scale
    // only show the annotation lines if RAG status is enabled
    trend.lines = ENABLE_RAG_STATUS
      ? statColouringLines[trendName].filter(
          (line) =>
            line.colour === "green" ||
            line.colour === "dark" ||
            (line.colour === "yellow" && trend.showYellowLines) ||
            (line.colour === "red" && trend.showRedLines)
        )
      : [];

    // if the start/end is the same, we've only got 1 data point, so don't show the trends graph
    if (trend.scales.x[0] === trend.scales.x[1]) {
      trend.series.data = [];
      trend.lines = [];
    }
  }

  return processedTrendData;
}
