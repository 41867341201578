
import Vue from "vue";
import lottie from "lottie-web";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component for displaying a Lottie (After Effects) animation. Animation data is stored in the `@/assets/animations`
 * directory. Data should be Bodymovin JSON files exported from After Effects.
 */
@Component({
  name: "animation",
})
export default class Animation extends Vue {
  /**
   * Bodymovin JSON animation data exported from After Effect. Should've been imported as a JSON module using Webpack.
   * @category Vue Prop
   */
  @Prop({ type: Object, required: true }) readonly animation!: any;

  // noinspection JSUnusedGlobalSymbols
  /**
   * Loads the animation and starts it playing on loop.
   * @category Vue Lifecycle
   */
  mounted() {
    // start the animation on mount (when the element is added to the screen)
    lottie.loadAnimation({
      // @ts-ignore
      container: this.$refs.animation,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.animation,
    });
  }
}
