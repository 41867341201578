
import { defineComponent } from "vue";
import Loader from "@/components/Loader.vue";
import {
  ntdPrefix,
  ActionTypes,
  GetterTypes,
  HandsetTestResultsParsed,
} from "@/store/internal/modules/ntd";
import { formatDate } from "@/components/graphics/date";
import { HandsetV2 } from "@/store/types";

export default defineComponent({
  components: {
    Loader,
  },
  methods: {
    formatDate,
  },
  computed: {
    rows(): HandsetTestResultsParsed[] | null {
      return this.$store.getters[
        ntdPrefix(GetterTypes.TESTS_SORTED_BY_CAPTURED_AT)
      ](this.$route.params.udi);
    },
    handsetSerialStr(): string | null {
      const handset: HandsetV2 | null = this.$store.getters[
        ntdPrefix(GetterTypes.GET_HANDSET_BY_UDI)
      ](this.$route.params.udi);
      return handset?.serialNumberStr ?? null;
    },
  },
  data() {
    return {
      loading: true,
      headers: ["Test ID", "Date", "Test Outcome"],
    };
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch(
        ntdPrefix(ActionTypes.GET_HANDSET_TEST_RESULTS),
        this.$route.params.udi
      )
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error))
      .finally(() => (this.loading = false));
  },
});
