
import router from "@/router";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { PanelFooter },
  methods: {
    gotoNtc() {
      router.push({ name: "list" });
    },
    gotoNtd() {
      router.push({ name: "ntd" });
    },
  },
});
