
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { State, Getter } from "vuex-class";
import {
  ACTION_FETCH_DEVICE_DATA,
  MUTATION_ADD_ERROR,
  MUTATION_SET_SELECTED_TREND,
} from "@/store/constants";
import {
  statNames,
  statUnits,
  statTooltips,
  statMarkers,
  DeviceSummaryStats,
  DeviceSummaryStatName,
} from "@/store/fetcher/summaryStats";
import StatisticCard from "@/components/StatisticCard.vue";
import NormalisedPlot from "@/components/graphics/NormalisedPlot.vue";
import PanelTrends from "@/views/panels/dashboard/detail/PanelTrends.vue";
import PanelWaveform from "@/views/panels/dashboard/detail/PanelWaveform.vue";
import PanelCapnogram from "@/views/panels/dashboard/detail/PanelCapnogram.vue";
import PanelHeader from "@/views/common/PanelHeader.vue";
import Dropdown from "@/components/Dropdown.vue";
import Tabs from "@/components/Tabs.vue";
import Loader from "@/components/Loader.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import IconBack from "@/assets/icons/IconBack.vue";
import { ChartCapnogramWithMetadata } from "@/store/fetcher/capnogram";
import { Route } from "vue-router";
import { formatUdi } from "@/store/api";
import { DUID } from "@/store/types";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";
import PanelAdditionalInfo from "@/views/panels/dashboard/detail/PanelAdditionalInfo.vue";
import StatisticCardMarker from "@/components/StatisticCardMarker.vue";
import { ListDUID } from "@/store/internal/modules/listView";
import { ChartAverageWaveform } from "@/store/fetcher/waveform";

/**
 * Main panel for the detail view. Made up of lots of sub-panels
 * ([[PanelAdditionalInfo]], [[PanelCapnogram]], [[PanelTrends]],
 * [[PanelWaveform]]) all in this directory. Also responsible for redirecting
 * the user to the first DUID if they don't have one selected and go to
 * "/detail/" (without any id).
 *
 * ![](media://panelsdetailview.png)
 */
@Component({
  name: "panel-detail",
  components: {
    StatisticCardMarker,
    PanelAdditionalInfo,
    PanelFooter,
    Loader,
    Tabs,
    Dropdown,
    PanelHeader,
    PanelCapnogram,
    PanelWaveform,
    PanelTrends,
    NormalisedPlot,
    StatisticCard,
    IconBack,
  },
})
export default class PanelDetail extends Vue {
  // stat constants
  /**
   * Attached so it can be used in the template. See [[src/store/fetcher/summaryStats.statNames | statNames]] and
   * [[StatisticCard.name]].
   */
  statNames = statNames;
  /**
   * Attached so it can be used in the template. See [[src/store/fetcher/summaryStats.statUnits | statUnits]] and
   * [[StatisticCard.unit]].
   */
  statUnits = statUnits;
  /**
   * Attached so it can be used in the template. See [[src/store/fetcher/summaryStats.statTooltips | statTooltips]].
   */
  statTooltips = statTooltips;
  /** Attached so it can be used in the template. See [[src/store/fetcher/summaryStats.statMarkers | statMarkers]]. */
  statMarkers = statMarkers;

  /**
   * Get values of for the [[StatisticCard.ranges]] property for the passed statistic name. Converts the
   * [[DeviceSummaryStatColouringRange.min | min]]s and [[DeviceSummaryStatColouringRange.max | max]]s to
   * percentages.
   * @param statName - Name of the statistic to get ranges for
   * @returns Values for the [[StatisticCard.ranges]] property
   */
  statRanges(statName: DeviceSummaryStatName) {
    if (statName === "METABOLIC BURDEN") {
      return [{ colour: "dark", width: 100 }];
    } else {
      return ["red", "yellow", "green", "yellow", "red"].map((colour) => ({
        colour,
        width: 20,
      }));
    }
  }

  // vuex store bindings
  /**
   * See [[State.userDUIDs]]
   * @category Vuex Binding
   */
  @State("userDUIDs") readonly userDUIDs!: DUID[] | null;
  /**
   * See [[State.deviceDUID]]
   * @category Vuex Binding
   */
  @State("deviceDUID") readonly deviceDUID!: string;
  /**
   * See [[State.deviceAdditionalInfo]]
   * @category Vuex Binding
   */
  @State("deviceAdditionalInfo")
  readonly deviceAdditionalInfo!: DeviceAdditionalInfo;
  /**
   * See [[State.deviceSummaryStats]]
   * @category Vuex Binding
   */
  @State("deviceSummaryStats") readonly deviceSummaryStats!: DeviceSummaryStats;
  /**
   * See [[State.deviceCapnogram]]
   * @category Vuex Binding
   */
  @State("deviceCapnogram")
  readonly deviceCapnogram!: ChartCapnogramWithMetadata;
  /**
  /**
   * See [[State.deviceWaveform]]
   * @category Vuex Binding
   */
  @State("deviceWaveform")
  readonly deviceWaveform!: ChartAverageWaveform | null;
  /**
   * See [[State.deviceCapnogramStartTime]]
   * @category Vuex Binding
   */
  @State("deviceCapnogramStartTime") readonly deviceCapnogramStartTime!: string;
  /**
   * See [[src/store/internal/getters.deviceDataLoading | deviceDataLoading]]
   * getter
   * @category Vuex Binding
   */
  @Getter("deviceDataLoading") readonly deviceDataLoading!: boolean;
  /**
   * See [[src/store/internal/getters.hasDetailPermission |
   * hasDetailPermission]] getter
   * @category Vuex Binding
   */
  @Getter("hasDetailPermission") readonly hasDetailPermission!: boolean;
  /**
   * See [[src/store/internal/modules/listView/getters.sortFilteredDUIDs |
   * sortFilteredDUIDs]] getter
   * @category Vuex Binding
   */
  @Getter("listView/sortFilteredDUIDs")
  readonly sortFilteredDUIDs!: ListDUID[];

  // want to 2-way data binding for this store value, so custom getter definition
  /**
   * See [[State.selectedTrendName | selectedTrendName]]
   * @returns Current value of [[State.selectedTrendName | selectedTrendName]]
   */
  get selectedTrendName(): DeviceSummaryStatName {
    return this.$store.state.selectedTrendName;
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Called when the user selects a different trend tab/option from the mobile-only dropdown. Updates the name in
   * the store and also in the route hash.
   * @param value - New selected trend name
   */
  set selectedTrendName(value: DeviceSummaryStatName) {
    // check this is a new trend selection
    if (value === this.$store.state.selectedTrendName) {
      return;
    }
    // change the hash in the route
    const duid = this.$route.params.duid;
    const location: any = {
      hash: "#" + encodeURIComponent(value),
    };
    // check whether we've got a DUID, and replace the route with the correct name
    // (basically try to persist the current route, it's probably impossible to be on the "dashboard" only route,
    // because that wouldn't show the trends graph, but ¯\_(ツ)_/¯)
    if (duid) {
      location.name = "dashboard-with-duid";
      location.params = { duid };
    } else {
      location.name = "dashboard";
    }
    // replace the route with the new hash
    this.$router.replace(location);
  }

  /**
   * Gets the full [[DUID]] object associated with the currently selected [[deviceDUID]] or undefined
   * if [[userDUIDs]] is null or [[deviceDUID]] couldn't be found.
   * @returns [[DUID]] object for [[deviceDUID]]
   */
  get fullDeviceDUID(): DUID | undefined {
    // TODO: ideally, we wouldn't have to do this as deviceDUID would be an instance of DUID not a string
    // (also this linear search makes me sad :( )
    return this.userDUIDs
      ? this.userDUIDs.find((duid) => duid.duid === this.deviceDUID)
      : undefined;
  }

  /**
   * Gets a formatted concatenation of the device type and padded serial number
   * @returns Formatted handset UDI to display next to the DUID
   */
  get formattedHandsetUDI(): string {
    const duid = this.fullDeviceDUID;
    if (duid && duid.udi) {
      const { serialNumberFormatted, type } = formatUdi(duid.udi);
      // \u00A0 = No-Break Space
      return `${type}\u00A0${serialNumberFormatted || ""}`;
    } else {
      return "";
    }
  }

  /**
   * Gets the adherence for the selected DUID, or 0 if undefined
   * @returns adherence percentage in range [0, 100]
   */
  get adherence(): number {
    const duid = this.fullDeviceDUID;
    return Math.round(((duid && duid.listViewExtra.adherence) || 0) * 100);
  }

  /**
   * Gets the overall breath quality for the selected DUID, or 0 if undefined
   * @returns quality percentage in range [0, 100]
   */
  get breathQuality(): number {
    const duid = this.fullDeviceDUID;
    return Math.round((duid && duid.listViewExtra.overallBreathQuality) || 0);
  }

  /**
   * Gets all the DUIDs from [[State.userDUIDs]] which have a capnogram count of
   * more than zero.
   * @returns a list of DUIDs with at least one capnogram.
   */
  get userDUIDsWithCapnos(): ListDUID[] | null {
    if (this.sortFilteredDUIDs === null) return null;

    return this.sortFilteredDUIDs.filter((duid) => duid.count > 0);
  }

  /**
   * Gets the index of the [[State.deviceDUID]] within the
   * [[PanelDetail.userDUIDsWithCapnos]] state.
   * @returns index of the [[State.deviceDUID]] or null when the DUID is not
   * found within [[PanelDetail.userDUIDsWithCapnos]].
   */
  get indexOfDUID(): number | null {
    if (this.userDUIDsWithCapnos === null) return null;

    const index = this.userDUIDsWithCapnos
      .map((duid) => duid.duid)
      .indexOf(this.deviceDUID);

    // when current device DUID does not have any data
    if (index === -1) return null;

    return index;
  }

  /**
   * Gets the next DUID in the [[PanelDetail.userDUIDsWithCapnos]].
   * @returns the next DUID in the [[PanelDetail.userDUIDsWithCapnos]] or
   * returns null when the current DUID is the last in the in the list.
   */
  get nextDUID(): ListDUID | null {
    if (
      this.userDUIDsWithCapnos === null ||
      this.indexOfDUID === null ||
      this.indexOfDUID + 1 === this.userDUIDsWithCapnos.length
    )
      return null;

    return this.userDUIDsWithCapnos[this.indexOfDUID + 1];
  }

  /**
   * Gets the previous DUID in the [[PanelDetail.userDUIDsWithCapnos]].
   * @returns the previous DUID in the [[PanelDetail.userDUIDsWithCapnos]] or
   * returns null when the current DUID is the first in the in the list.
   */
  get previousDUID(): ListDUID | null {
    if (
      this.userDUIDsWithCapnos === null ||
      this.indexOfDUID === null ||
      this.indexOfDUID === 0
    )
      return null;

    return this.userDUIDsWithCapnos[this.indexOfDUID - 1];
  }

  /**
   * Navigate to a DUID's detail page. If the passed DUID is null, nothing will
   * happen.
   */
  goToDUID(duid: DUID | null) {
    if (duid) {
      this.$router.push({
        // // Go to the current route (just with different parameters)
        name: this.$route.name as string,
        params: {
          duid: duid.duid,
        },
      });
    }
  }

  /**
   * Watcher for the current route. Sets the correct device to fetch/watch data for and also the selected trend
   * name when the hash changes. If [[userDUIDs]] is `null`, this device will set a callback to be called when this
   * becomes non-null. See [[onUserDUIDsChange]] for where this is called. This is because this function requires
   * [[userDUIDs]] to be defined to get the first DUID to redirect to if one isn't defined.
   * @param route - New route object
   * @param oldRoute - Previous route object, or null if this was the first route
   * @category Vue Watch
   */
  @Watch("$route", { immediate: true })
  fetchDeviceData(route: Route, oldRoute: Route | null) {
    // if we don't have duids yet, wait until we do before fetching device data
    if (this.userDUIDs === null) {
      // this callback will be called when userDUIDs changes (see the watcher below)
      (this as any).onUserDUIDsCallback = () =>
        this.fetchDeviceData(route, oldRoute);
      return;
    }

    // make sure we have permission to view this page, should also be caught by the route guard in main.ts
    if (!this.hasDetailPermission) {
      this.$store.commit(MUTATION_ADD_ERROR, {
        message: "You don't have permission to view detailed information.",
      });
      this.$router.replace({
        name: "list",
      });
      return;
    }

    // select DUID based on route param
    if (route.params.duid) {
      // if one is defined and it's different to the last one...
      if (!oldRoute || route.params.duid !== oldRoute.params.duid) {
        this.$store.dispatch(ACTION_FETCH_DEVICE_DATA, route.params.duid);
      }
    } else if (this.userDUIDs && this.userDUIDs.length > 0) {
      // if one isn't defined, use the first default one if possible
      this.$router.replace({
        name: "dashboard-with-duid",
        params: {
          duid: this.userDUIDs[0].duid,
        },
        hash: route.hash,
      });
    }

    // select trend based on route hash
    const hash = decodeURIComponent(route.hash.substring(1));
    // check this is a valid & different hash
    if (
      statNames.includes(hash as DeviceSummaryStatName) &&
      hash !== this.selectedTrendName
    ) {
      this.$store.commit(MUTATION_SET_SELECTED_TREND, hash);
    }
  }

  /**
   * Watcher for [[userDUIDs]]. When these change, if a callback for [[fetchDeviceData]] is defined, it will be
   * called. See [[fetchDeviceData]] for more details.
   * @category Vue Watch
   */
  @Watch("userDUIDs")
  onUserDUIDsChange() {
    // call any callback if one is defined, removing it after so it doesn't get called again
    if ((this as any).onUserDUIDsCallback) {
      (this as any).onUserDUIDsCallback();
      delete (this as any).onUserDUIDsCallback;
    }
  }
}
