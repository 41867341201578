import { Store } from "vuex";
import { MUTATION_ADD_ERROR, MUTATION_SET_USER } from "./constants";
import { API_BASE_URL } from "@/config";
import errorReporter from "@/errors";
import { State } from "@/store/internal/state";
import { ChannelAuthorizationData } from "pusher-js/types/src/core/auth/options";
import {
  APICapnogramListResponse,
  APICapnogramResponse,
  APIDUID,
  APIDeviceTrendDataPoint,
  APIUDIListResponse,
  APIUserInfoResponse,
  APIUserLoginResponse,
  APIUserRefreshResponse,
  DUID,
  ErrorLevel,
} from "@/store/types";

/** Possible HTTP methods for API requests. Passed to [[APIClient.request]]. */
export type HTTPMethod = "GET" | "POST" | "UPDATE" | "PUT" | "PATCH" | "DELETE";

/**
 * Wrapper for a response from the API containing a status code and optionally some data. Data will only be set
 * if [[status]] is an expected code.
 */
export interface APIResponse<T> {
  data?: T;
  ok: boolean;
  status: number;
}

/**
 * Gets the type of device given a UDI, according to these rules which are applied in order until a match is found:
 *
 * - If the UDI starts with `"(01)99000000000001"` or `"(01)05060724290069"`, it is an **NTPRMP** device
 * - If the UDI starts with `"(01)05060724290007"`, it is an **NTC** device now labelled **NTH**
 * - If the UDI starts with `"(01)05060724290038"`, it is an **NTA** device
 * - If the UDI starts with `"(01)09999999990090"`, it is an **NTH_DEV** device
 * - If the UDI starts with `"(01)05060724290090"`, it is an **NTH** device now labelled *NTH*
 * - If the UDI starts with `"(01)05060724290168"`, it is an **NTH 2** device now labelled *NTH 2*
 * - If the UDI is all numeric, it is an **NTC1** device
 *
 * If the UDI is unrecognised, this function will return "Unknown", reporting this as an error to Stackdriver as a
 * side effect.
 *
 * @param udi - UDI to get device type of
 * @returns Device type abbreviation or "Unknown" if the UDI is unrecognised
 */
export function deviceTypeForUdi(udi: string): string {
  if (
    udi.startsWith("(01)99000000000001") ||
    udi.startsWith("(01)05060724290069")
  )
    return "NTPRMP";
  if (
    udi.startsWith("(01)05060724290007") ||
    udi.startsWith("(01)05060724290090")
  )
    return "NTH";
  if (udi.startsWith("(01)05060724290038")) return "NTA";
  if (udi.startsWith("(01)09999999990090")) return "NTH_DEV";
  if (udi.startsWith("(01)05060724290168")) return "NTH 2";
  // NTC1 devices have 1-4 digit long UDIs: Matthew thinks the smallest is 8 & the largest 6001 (or similar).
  if (!isNaN(parseInt(udi))) return "NTC1";

  // make sure we get notified when we get an unrecognised device
  errorReporter.report(new Error(`unknown device type ${udi}`));
  return "Unknown";
}

/**
 * Gets the components required to display a formatted version of a UDI.
 * See [[deviceTypeForUdi]].
 *
 * @param udi - UDI to format
 * @returns Numeric serial number, zero-padded serial number, device type
 */
export function formatUdi(udi?: string | null): {
  serialNumber: number;
  serialNumberFormatted: string | null;
  type: string;
} {
  // try to parse the serial number from the last ")" onwards
  // (e.g. "...(21)000000082" -> 82)
  // defaulting to Infinity ensures the sorting order is correct
  const serialNumber = udi
    ? parseInt(udi.substring(udi.lastIndexOf(")") + 1))
    : Infinity;
  const serialNumberFormatted = isFinite(serialNumber)
    ? serialNumber.toString().padStart(5, "0")
    : null;
  const type = udi ? deviceTypeForUdi(udi) : "";
  return { serialNumber, serialNumberFormatted, type };
}

/**
 * Client for making API requests to the backend Django API. Contains strongly-typed functions for possible HTTP
 * requests. Handles token refreshes, errors, and prepending the [[API_BASE_URL]] to all requests.
 */
export default class APIClient {
  /** Application store instance: required to access current user and report errors */
  private store: Store<State>;

  /**
   * @param store - Application store instance
   */
  constructor(store: Store<any>) {
    this.store = store;
  }

  /**
   * Helper function to show an error/message to the user
   * @param message - Body of the message
   * @param level - Level the message should be displayed as
   */
  private _showMessage(message: string, level: ErrorLevel, logout = false) {
    this.store.commit(MUTATION_ADD_ERROR, { message, level, logout });
  }

  /** Clears the capnogram cache, called when the user logs out */
  flushCache() {
    this.capnocache = {};
  }

  /**
   * Makes a request to the API, adds the "Authorization" header if required, and refreshes the access token if it's
   * about to/has expire(d).
   *
   * @typeParam T - type of response data
   *
   * @param method - HTTP method
   * @param route - path including leading /
   * @param body - form data to send with request
   * @param requiresAuth - whether to authenticate the request
   * @param expectedErrorCodes - array of error HTTP status codes that shouldn't show unexpected error
   * @param explanation - message to show to the user explaining what happened if an error occurred
   * @param required - whether this request must succeed, fatal error if it fails
   * @returns parsed JSON response
   */
  async request<T>({
    method,
    route,
    body = null,
    requiresAuth = true,
    expectedErrorCodes = [],
    explanation = "",
    required = false,
  }: {
    method: HTTPMethod;
    route: string;
    body?: any;
    requiresAuth?: boolean;
    expectedErrorCodes?: number[];
    explanation?: string;
    required?: boolean;
  }): Promise<APIResponse<T>> {
    const headers: { [key: string]: string } = {};

    // build the FormData object for the body if there is one
    let sentBody: FormData | undefined = undefined;
    if (body !== null) {
      sentBody = new FormData();
      Object.entries(body).forEach(([key, value]) =>
        // @ts-ignore
        sentBody.append(key, value)
      );
    }

    // build the Authorization header if a user is given
    if (this.store.state.user !== null && requiresAuth) {
      // get the time remaining until expiry for the access & refresh tokens
      const now = new Date();
      const accessRemainingSeconds =
        (this.store.state.user.accessTokenExp.getTime() - now.getTime()) / 1000;
      const refreshRemainingSeconds = this.store.state.user.refreshTokenExp
        ? (this.store.state.user.refreshTokenExp.getTime() - now.getTime()) /
          1000
        : 0;

      // if the access token is about to/has expire(d) (< 30 seconds)
      if (accessRemainingSeconds < 30) {
        // if the refresh token is also about to/has expire(d)/never existed in the first place
        if (
          refreshRemainingSeconds < 30 ||
          !this.store.state.user.rawRefreshToken
        ) {
          // log the user out as their session has expired
          this._showMessage(
            "Your session has expired. Please login again.",
            ErrorLevel.INFO,
            true
          );
          return { ok: false, status: 401 };
        } else {
          // otherwise, refresh the access token and update the user object
          const res = await this.refreshAccessToken(
            this.store.state.user.rawRefreshToken
          );
          if (res.data) {
            this.store.commit(MUTATION_SET_USER, {
              rawAccessToken: res.data.access,
              rawRefreshToken: this.store.state.user.rawRefreshToken || null,
            });
          }
        }
      }
      // build the authorization header
      headers[
        "Authorization"
      ] = `Bearer ${this.store.state.user.rawAccessToken}`;
    }

    // add " whilst " to the explanation if there is one
    if (explanation) {
      explanation = ` whilst ${explanation}`;
    }

    // make the actual request and parse the response as JSON
    try {
      const res = await fetch(API_BASE_URL + route, {
        method,
        body: sentBody,
        headers: headers,
      });
      const isExpectedErrorCode =
        expectedErrorCodes && expectedErrorCodes.includes(res.status);
      if (!res.ok) {
        const body = await res.text();

        // eslint-disable-next-line no-console
        console.warn(
          `API Error: ${method} ${route} -> ${res.status} ${res.statusText} \n%c${body}`,
          "color: #AAAAAA"
        );

        // if the user was unauthorised to make this request, and we're not trying to login, log the user out
        if (
          (res.status === 401 || res.status === 403) &&
          route !== "/user/login/" &&
          route !== "/pusher/auth/"
        ) {
          this._showMessage(
            "Your session has expired. Please login again.",
            ErrorLevel.INFO,
            true
          );
        } else if (!isExpectedErrorCode) {
          errorReporter.report(
            `API Error: ${method} ${route} -> ${res.status} ${res.statusText} ${body}`
          );
          this._showMessage(
            `An unexpected error occurred${explanation}.`,
            required ? ErrorLevel.FATAL : ErrorLevel.ERROR
          );
        } /* isExpectedErrorCode */ else {
          return {
            data: JSON.parse(body),
            ok: res.ok,
            status: res.status,
          };
        }
      }
      return {
        // Try to parse as JSON, if not just return undefined (e.g. No Content)
        data: await res.json().catch(() => undefined),
        ok: res.ok,
        status: res.status,
      };
    } catch (e) {
      errorReporter.report(`API Network Error: ${method} ${route} ${e}`);
      // eslint-disable-next-line no-console
      console.error(`API Network Error: ${method} ${route}`, e);
      this._showMessage(
        `An unexpected network error occurred${explanation}. Please try again later.`,
        required ? ErrorLevel.FATAL : ErrorLevel.ERROR
      );
      return {
        ok: false,
        status: 500,
      };
    }
  }

  /**
   * Tries to login with the specified credentials
   * @param email - Username of the user
   * @param password - Password of the user
   * @returns Access & refresh tokens and the user key
   */
  async login(
    email: string,
    password: string
  ): Promise<APIResponse<APIUserLoginResponse>> {
    return this.request({
      method: "POST",
      route: "/user/login/",
      body: { username: email.toLowerCase(), password },
      requiresAuth: false,
      expectedErrorCodes: [401 /*incorrect credentials*/],
      explanation: "logging in",
    });
  }

  /**
   * Gets a new access token for the user
   * @param refreshToken - User's refresh token
   * @returns New access token for user
   */
  async refreshAccessToken(
    refreshToken: string
  ): Promise<APIResponse<APIUserRefreshResponse>> {
    return this.request({
      method: "POST",
      route: "/user/login/refresh/",
      body: { refresh: refreshToken },
      requiresAuth: false,
      explanation: "refreshing your session",
    });
  }

  /**
   * Registers a new user
   * @param email - New user's username
   * @param password - New user's password
   * @param invitationCode - Secure Invitation Code
   * @param groupName - Name of the group the user is creating
   * @param authToken - From the URL query, provided by CRiL to new study administrators (SAs)
   * @param allowedDomains - Whitelist of domains for new users email addresses
   * @returns Response containing any validation errors
   */
  async register({
    email,
    password,
    invitationCode,
    groupName = "",
    authToken = "",
    allowedDomains = [],
  }: {
    email: string;
    password: string;
    invitationCode: string;
    groupName: string;
    authToken: string;
    allowedDomains: string[];
  }): Promise<APIResponse<any>> {
    // if we have an auth token we're a Study Administrator (SA),
    // otherwise we're a Study User (SU)
    const userType = authToken ? "SA" : "SU";
    const registerBody: any = {
      username: email.toLowerCase(),
      password,
      user_type: userType,
      sic: invitationCode,
    };
    // add study administrator information if required
    if (authToken) {
      registerBody.groupname = groupName;
      registerBody.sck = authToken;
      registerBody.allowed_domains = JSON.stringify(allowedDomains);
    }

    return this.request({
      method: "POST",
      route: "/user/register/",
      body: registerBody,
      requiresAuth: false,
      expectedErrorCodes: [400 /* errors in params */],
      explanation: "registering your account",
    });
  }

  /**
   * Requests that a password reset email be sent to the user if their user account exists. If the account doesn't
   * exist, we'll be required to handle the error.
   * @param email - User's username
   * @returns Status based on whether the user exists
   */
  async sendResetPasswordEmail(email: string): Promise<APIResponse<string>> {
    return this.request({
      method: "GET",
      route:
        "/user/login/reset/?username=" +
        encodeURIComponent(email.toLowerCase()),
      requiresAuth: false,
      expectedErrorCodes: [400 /* user not found */],
      explanation: "requesting a password reset",
    });
  }

  /**
   * Actually performs a password reset once a user has clicked on the link in their email
   * @param passwordResetKey - VEK included in the link in the email
   * @param invitationCodes - Object containing user's Secure Invitations Codes so we can re-encrypt the user keys
   * @param password - New password
   * @returns Response containing any validation errors (bad VEK, weak password, etc)
   */
  async resetPassword({
    passwordResetKey,
    invitationCodes,
    password,
  }: {
    passwordResetKey: string;
    invitationCodes: {
      // noinspection JSUnusedLocalSymbols
      [key: number]: string;
    };
    password: string;
  }): Promise<APIResponse<any>> {
    return this.request({
      method: "POST",
      route: "/user/login/reset/",
      body: {
        vek: passwordResetKey,
        sics: JSON.stringify(invitationCodes),
        password: password,
      },
      requiresAuth: false,
      expectedErrorCodes: [400 /* errors in params */],
      explanation: "resetting your password",
    });
  }

  /**
   * Gets basic user information
   * @returns User's groups, permissions and additional info encryption keys
   */
  async getUserInfo(): Promise<APIResponse<APIUserInfoResponse>> {
    return this.request({
      method: "GET",
      route: "/user/info/",
      explanation: "getting your account information",
      required: true,
    });
  }

  /**
   * Gets a list of handset UDIs the user has access to for handset allocation
   * @returns List of handset UDIs accessible to the user
   */
  async getAllUDIs(): Promise<APIResponse<APIUDIListResponse>> {
    return this.request({
      method: "GET",
      route: "/device/",
      explanation: "getting UDIs associated with your account",
      required: true,
    });
  }

  /**
   * Gets a list of DUID objects the user has access to
   * @returns List of DUIDs and their associated data for the list view
   */
  async getAllDUIDs(): Promise<APIResponse<APIDUID[]>> {
    return await this.request({
      method: "GET",
      route: "/duid/",
      explanation: "getting DUIDs associated with your account",
      required: true,
    });
  }

  /**
   * Gets a particular DUID the user has access. Used for refreshing data in the list view when a new record
   * is received.
   * @returns Associated data for the speicifed data for the list view
   */
  async getDUID(duid: string): Promise<APIResponse<APIDUID>> {
    return await this.request({
      method: "GET",
      route: `/duid/${duid}/`,
      explanation: "getting a DUID associated with your account",
    });
  }

  /**
   * Gets basic information for all capnograms that have the specified DUID
   * @param duid - DUID to get capnograms for
   * @returns Basic information for all capnograms that have the specified DUID
   */
  async getCapnogramsForDUID(
    duid: string
  ): Promise<APIResponse<APICapnogramListResponse>> {
    return this.request({
      method: "GET",
      route: "/capnogram/?duid=" + duid,
      explanation: "getting the selected DUID's capnograms",
    });
  }

  /**
   * Capnogram response cache: avoids unnecessarily fetching previous capnogram if required. Import as the
   * [[DeviceDataFetcher]] will call [[getCapnogram]] for previous capnograms that may have already been fetched
   * and displayed. Maps capnogram UUIDs to their raw API response. This is OK as capnograms are basically immutable
   * once parameterised. See [[flushCache]] which clears this cache when the user logs out.
   */
  private capnocache: { [key: string]: APIResponse<APICapnogramResponse> } = {};

  /**
   * Gets the full capnogram with the specified UUID
   * @param id - UUID of the capnogram to get
   * @returns Full capnogram response containing breath data and all parameters
   */
  async getCapnogram(id: string): Promise<APIResponse<APICapnogramResponse>> {
    // first check if we've already fetched this capnogram
    if (id in this.capnocache) {
      // eslint-disable-next-line no-console
      console.log(`Using capnocache for capnogram with ID ${id}`);
      return this.capnocache[id];
    }

    // if not, try to fetch it
    const res = await this.request<APICapnogramResponse>({
      method: "GET",
      route: "/capnogram/" + id + "/",
      explanation: "getting capnogram data",
    });

    // if this was successful, store the response in the cache
    if (res.data) {
      this.capnocache[id] = res;
    }

    return res;
  }

  /**
   * Get the parameter trends for a duid
   * @param duid - DUID to get trends for
   * @returns Parameter values at every time a breath record was taken
   */
  async getDUIDTrends(
    duid: string
  ): Promise<APIResponse<APIDeviceTrendDataPoint[]>> {
    return this.request({
      method: "GET",
      route: `/duid/param/?duid=${duid}`,
      explanation: "getting data trends",
    });
  }

  /**
   * Gets the next assignable DUIDs for every study the user has access to
   * @returns Object mapping numeric group IDs to next sequential DUIDs
   */
  async getNewDUID(): Promise<APIResponse<{ [key: number]: string }>> {
    return this.request({
      method: "GET",
      route: "/duid/next/",
      explanation: "getting the next DUID",
    });
  }

  // tsdoc's eslint rule doesn't like the shruggie, no idea why ¯\_(ツ)_/¯ (:P)
  /* eslint-disable tsdoc/syntax */
  /**
   * Updates the additional metadata (encrypted user info, COVID-19 status) stored with a DUID
   * @param duid - DUID object containing the actual DUID, and the updated metadata
   * @returns ¯\_(ツ)_/¯
   */
  /* eslint-enable tsdoc/syntax */
  async updateDUID(duid: DUID): Promise<APIResponse<unknown>> {
    return this.request({
      method: "POST",
      route: "/duid/",
      body: {
        duid: duid.duid,
        add_info: duid.additionalInfo || "-",
        //group: duid.groupId
      },
      explanation: "updating stored data",
    });
  }

  /**
   * Creates a new DUID mapping for a handset using the specified information, deactivating the old mapping if there
   * was one
   * @param udi - UDI of the handset to allocate
   * @param duid - DUID to allocate to
   * @param assignedBy - Person that allocated the handset
   * @param trainer - Person that trained the user
   * @param groupId - Group to allocate the handset to
   * @param additionalInfo - Encrypted additional info to attach to the DUID
   * @returns Something (TODO: update the current state using this as opposed to re-fetching ALL user data)
   */
  async allocateDevice({
    udi,
    duid,
    assignedBy,
    trainer,
    groupId,
    additionalInfo,
  }: {
    udi: string;
    duid: string;
    assignedBy?: string;
    trainer?: string;
    groupId: number;
    additionalInfo: string;
  }): Promise<APIResponse<unknown>> {
    // only include assignedBy and trainer if they we're specified
    const body: any = {
      handset_udi: udi,
      duid,
      group: groupId,
      add_info: additionalInfo,
    };
    if (assignedBy) body.assigned_by = assignedBy;
    if (trainer) body.trainer = trainer;

    return this.request({
      method: "POST",
      route: "/device/assign/",
      body: body,
      expectedErrorCodes: [400 /* errors in params */],
      explanation: "allocating the handset",
    });
  }

  /**
   * Marks the currently active DUID mapping for the passed DUID as inactive.
   * @param udi - UDI of the handset to allocate
   * @param deassignedBy - Person that de-allocated the handset
   * @returns Something (TODO: update the current state using this as opposed to re-fetching ALL user data)
   */
  async deallocateDevice({
    udi,
    deassignedBy,
  }: {
    udi: string;
    deassignedBy: string;
  }): Promise<APIResponse<unknown>> {
    return this.request({
      method: "POST",
      route: "/device/deassign/",
      body: {
        handset_udi: udi,
        assigned_by: deassignedBy,
      },
      explanation: "de-allocating the handset",
    });
  }

  /**
   * Authenticates a socket to connect to a named channel. The backend will
   * verify the current user has appropriate permissions to communicate on the
   * channel.
   * @param socketId - ID of this application instance's Pusher socket
   * @param channelName - Name of the channel to connect to
   * @returns Pusher authentication response, see
   *  https://pusher.com/docs/channels/server_api/authenticating-users
   */
  async authenticatePusherChannel({
    socketId,
    channelName,
  }: {
    socketId: string;
    channelName: string;
  }): Promise<APIResponse<ChannelAuthorizationData>> {
    return this.request({
      method: "POST",
      route: "/pusher/auth/",
      body: {
        socket_id: socketId,
        channel_name: channelName,
      },
      explanation: "authenticating with the notification server",
    });
  }

  /**
   * Consents the current user to the EULA. This will respond with 204 No
   * Content on success so we don't expect a response. This will fail if the
   * user has already consented to the EULA.
   * @returns Nothing
   */
  async consentToEULA(): Promise<APIResponse<never>> {
    return this.request({
      method: "POST",
      route: "/user/eula",
      explanation: "consenting to the end user licensing agreement",
    });
  }
}
