/**
 * Module containing mixins: reusable Vue component blocks that add functionality to a component. When Vue 3 is
 * released, these should be replaced with [composition functions](https://composition-api.vuejs.org/).
 * @packageDocumentation
 */

import Vue from "vue";

/**
 * Mixin factory that generates mixins for providing a way of determining when the mouse has hovered over an element
 * for a long time *(500ms)*. This is a factory because some components may have multiple elements that need tracking
 * *(i.e. multiple tooltips)*.
 *
 * This will add the following to the Vue instance:
 * - Data `longHover<id>`: whether the element has been hovered for a long time
 * - Method `startHover<id>`: call this when the mouse enters the element
 * - Method `cancelHover<id>`: call this when the mouse leaves the element
 *
 * @param id - Discriminator to allow multiple of these mixins to be used in the same component
 * @returns Long hover mixin parameterised with `id`
 */
export function longHover(id: string = "") {
  return Vue.extend({
    data() {
      return {
        [`longHover${id}`]: false,
      };
    },
    methods: {
      [`startHover${id}`]() {
        clearTimeout((this as any)[`longHoverTimeout${id}`]);
        (this as any)[`longHoverTimeout${id}`] = setTimeout(() => {
          (this as any)[`longHover${id}`] = true;
        }, 500);
      },
      [`cancelHover${id}`]() {
        clearTimeout((this as any)[`longHoverTimeout${id}`]);
        (this as any)[`longHover${id}`] = false;
      },
    },
  });
}
