import { detect } from "detect-browser";

/**
 * Gets the major version number from a semver version string (e.g. "7.8.1" returns 7). Returns NaN if null is passed
 * or there is no major version. Inequalities with NaN will always return false so we can use this to mark these
 * browsers as unsupported.
 * @param semanticVersion - Semver version
 * @returns Major version number
 */
function majorVersion(semanticVersion: string | null): number {
  return semanticVersion ? parseInt(semanticVersion.split(".")[0]) : NaN;
}

/**
 * Checks if the current User-Agent is supported by this app. The version numbers here were determined from
 * [support for CSS grid](https://caniuse.com/#feat=css-grid). TODO: improve this to require other features
 * @returns `true` if the app supports the current browser
 */
export function isBrowserSupported(): boolean {
  const browser = detect();
  // if we don't know what this browser is, it's definitely unsupported
  if (!browser) return false;
  // otherwise, check it against our compatibility table
  const version = majorVersion(browser.version);
  switch (browser.name) {
    case "edge":
    case "edge-ios":
    case "crios":
    case "fxios":
    case "searchbot":
    case "bot":
      return true;
    case "edge-chromium":
    case "chrome":
    case "chromium-webview":
      return version >= 57;
    case "samsung":
      return version >= 6;
    case "firefox":
      return version >= 52;
    case "opera":
      return version >= 44;
    case "ie":
      return version >= 10;
    case "android":
      return version >= 5;
    case "ios":
    case "safari":
    case "ios-webview":
      return version >= 10;
    default:
      return false;
  }
}
