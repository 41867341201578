
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component for an animated triangle that can be flipped/hidden. Used to indicate changes in data values,
 * or sorting order.
 */
@Component({
  name: "indicator-arrow",
})
export default class IndicatorArrow extends Vue {
  /**
   * Change this arrow represents. If this is:
   * - Positive: an up arrow will be shown
   * - Zero: no arrow will be shown
   * - Negative: a down arrow will be shown
   * @category Vue Prop
   */
  @Prop({ type: Number, required: true }) readonly change!: number;
}
