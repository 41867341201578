
import Vue from "vue";
import Component from "vue-class-component";
import { EMAIL_REGEXP, ErrorLevel } from "@/store/types";
import CardDialogBackground from "@/components/CardDialogBackground.vue";
import DialogInformation from "../dashboard/DialogInformation.vue";
import LogoText from "@/components/LogoText.vue";
import TextInput from "@/components/TextInput.vue";
import { apiClient } from "@/store";
import { MUTATION_ADD_ERROR } from "@/store/constants";

/**
 * Panel embedded in [[ViewLoginWrapper]] for requesting a password reset email.
 */
@Component({
  name: "panel-forgotten-password",
  components: { TextInput, DialogInformation, LogoText, CardDialogBackground },
})
export default class PanelForgottenPassword extends Vue {
  /**
   * Current email address being typed by the user.
   * @category Vue Data
   */
  email: string = "";
  /**
   * Whether the user has clicked the request button. Disables the request button to prevent spamming.
   * @category Vue Data
   */
  requesting: boolean = false;
  /**
   * Whether to show the success dialog. Set on success response from the API.
   * @category Vue Data
   */
  requestDialogVisible: boolean = false;

  /**
   * Whether the request button is disabled. Disabled if the user hasn't put it a valid email, or they've already
   * clicked the button
   * @returns `true` if the email is empty/invalid, or we're requesting an email
   * @category Vue Computed
   */
  get disabled() {
    return (
      this.email === "" || !EMAIL_REGEXP.test(this.email) || this.requesting
    );
  }

  /**
   * Handler for clicking the request button. After checking if the button is disabled, tries to request a reset email.
   * Shows the correct dialog based on the response.
   */
  request() {
    if (!this.disabled) {
      this.requesting = true;
      // try to request a password reset
      apiClient
        .sendResetPasswordEmail(this.email)
        .then((res) => {
          // only show the success dialog if we got a success response
          if (res.status == 200) {
            this.requestDialogVisible = true;
          } else {
            this.$store.commit(MUTATION_ADD_ERROR, {
              message: "We couldn't find an account with that email address!",
              level: ErrorLevel.ERROR,
            });
          }
        })
        .finally(() => {
          // reactivate the button whatever happens, so the user can request another email if required
          this.requesting = false;
        });
    }
  }

  /**
   * Handler for clicking the next button on the success dialog. Hides the dialog and redirects the user to the login
   * page.
   */
  requestDialogNext() {
    this.requestDialogVisible = false;
    this.$router.replace({ name: "login" });
  }
}
