
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { APIUserGroup, ErrorLevel } from "@/store/types";
import CardDialog from "@/components/CardDialog.vue";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";
import { ACTION_ALLOCATE_DEVICE, MUTATION_ADD_ERROR } from "@/store/constants";
import IconNotification from "@/assets/icons/IconNotification.vue";
import errorReporter from "@/errors";

/**
 * Dialog for confirming the details of a handset allocation entered on the previous dialog, [[DialogDeviceAllocation]].
 * Shows the details in big text and an alert showing this is a permanent action and something that cannot be undone.
 * Once the handset has been allocation, the user will be taken to [[DialogParticipantTraining]].
 *
 * ![](media://dialogconfirmallocation.png)
 */
@Component({
  name: "dialog-confirm-allocation",
  components: {
    CardDialog,
    IconNotification,
  },
})
export default class DialogConfirmAllocation extends Vue {
  // data from previous dialog, passed via props from ViewDashboard
  /**
   * UDI of handset selected in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: Object }) readonly udi!: { value: string; name: string } | null;
  /**
   * Group selected in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: Object }) readonly group!: APIUserGroup | null;
  /**
   * DUID entered in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly duid!: string;
  /**
   * Additional info entered in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: Object }) readonly additionalInfo!: DeviceAdditionalInfo;

  /**
   * Whether we're allocating a device. If we are, the allocate button is disable so the user can't spam it.
   * @category Vue Data
   */
  allocatingDevice: boolean = false;

  /**
   * Confirms the allocation and actually performs the allocation in the database. Disables the allocate button
   * via [[allocatingDevice]]. If this results in any errors, a dialog box will be shown, otherwise the user will be
   * moved onto [[DialogParticipantTraining]]. For testing purposes, if `window.__testSkipAllocation` is truthy, no
   * allocation will take place and the user will be moved straight onto [[DialogParticipantTraining]].
   */
  allocate() {
    // assertions: these should never be true
    if (this.udi === null) throw Error("UDI is null!");
    if (this.group === null) throw Error("Group is null!");

    // for testing the user training dialog, we may not always want to allocate the device
    if ((window as any).__testSkipAllocation) {
      this.$emit("next");
      return;
    }

    // mark the dialog as allocating, disabling the button
    this.allocatingDevice = true;
    this.$store
      .dispatch(ACTION_ALLOCATE_DEVICE, {
        udi: this.udi.value,
        duid: this.duid,
        additionalInfo: this.additionalInfo,
        groupId: this.group.id,
      })
      .then((res) => {
        this.allocatingDevice = false;
        if (res.status === 200) {
          this.$emit("next");
        } else if (res.status === 400) {
          const ctx = {
            udi: this.udi,
            group: this.group,
            duid: this.duid,
          };
          errorReporter.report(
            `Device Allocation Error: ${JSON.stringify(
              res.data
            )} Context: ${JSON.stringify(ctx)}`
          );
          const message = Array.isArray(res.data.duid)
            ? res.data.duid.join("")
            : "An unexpected error occurred whilst allocating the device";
          this.$store.commit(MUTATION_ADD_ERROR, {
            message: message,
            level: ErrorLevel.ERROR,
          });
        }
      });
  }
}
