
import Vue from "vue";
import Component from "vue-class-component";
import { PropSync, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import { apiClient } from "@/store";
import CardDialog from "@/components/CardDialog.vue";
import IconNotification from "@/assets/icons/IconNotification.vue";
import Dropdown from "@/components/Dropdown.vue";
import Checkbox from "@/components/Checkbox.vue";
import TextInput from "@/components/TextInput.vue";
import { deviceTypeForUdi } from "@/store/api";
import {
  APIUserGroup,
  APIUserInfoResponse,
  DUID,
  HandsetUDI,
} from "@/store/types";

/** Data object for a UDI in the Handset ID dropdown */
interface UDIOption {
  /** Original index of the UDI for preserving sort order */
  index: number;
  /** Full UDI of the handset */
  value: string;
  /** Friendly name of handset displayed in the dropdown containing the device type and whether it is/isn't allocated */
  name: string;
  /** Whether the handset is (un)allocated */
  unassigned: boolean;
  /** Group the handset belongs to */
  group: APIUserGroup;
}

/**
 * Dialog for the first part of device allocation: choosing the handset UID, entering user information, confirming
 * cleaning check, etc. The user will be given a chance to confirm these details on the next dialog,
 * [[DialogConfirmAllocation]].
 *
 * ![](media://dialogdeviceallocation.png)
 */
@Component({
  name: "dialog-device-allocation",
  components: { TextInput, Checkbox, Dropdown, IconNotification, CardDialog },
})
export default class DialogDeviceAllocation extends Vue {
  /**
   * Currently selected handset UDI to allocate to the new DUID. It's the responsibility of the parent component to
   * handle `update:udi` events whose payload is what to store.
   *
   * ![](media://dialogdeviceallocationudi.png)
   *
   * @category Vue Prop
   */
  @PropSync("udi", { type: Object }) syncedUdi!: UDIOption | null;
  /**
   * Currently selected group to allocate the handset to. It's the responsibility of the parent component to handle
   * `update:group` events whose payload is what to store.
   *
   * ![](media://dialogdeviceallocationgroup.png)
   *
   * @category Vue Prop
   */
  @PropSync("group", { type: Object }) syncedGroup!: APIUserGroup | null;
  /**
   * Number after the selected group's project ID. It's the responsibility of the parent component to handle
   * `update:duidSuffix` events whose payload is what to store.
   *
   * ![](media://dialogdeviceallocationduidsuffix.png)
   *
   * @category Vue Prop
   */
  @PropSync("duidSuffix", { type: String, default: "" })
  syncedDuidSuffix!: string;
  /**
   * Default number after the selected group's project ID. It's the responsibility of the parent component to
   * handle `update:duidSuffixOptions` events whose payload is what to store.
   *
   * @category Vue Prop
   */
  @PropSync("duidSuffixDefault", {
    type: Number,
    default: -1,
  })
  syncedDuidSuffixDefault!: number;
  /**
   * First name for additional info. Will be encrypted with the selected groups user key. It's the responsibility of
   * the parent component to handle `update:firstName` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("firstName", { type: String, default: "" })
  syncedFirstName!: string;
  /**
   * Last name for additional info. Will be encrypted with the selected groups user key. It's the responsibility of
   * the parent component to handle `update:lastName` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("lastName", { type: String, default: "" }) syncedLastName!: string;
  /**
   * Date of birth for additional info. Will be encrypted with the selected groups user key. It's the responsibility of
   * the parent component to handle `update:dob` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("dob", { type: String, default: "" }) syncedDob!: string;
  /**
   * Hospital number for additional info. Will be encrypted with the selected groups user key. It's the responsibility
   * of the parent component to handle `update:hospitalNumber` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("hospitalNumber", { type: String, default: "" })
  syncedHospitalNumber!: string;
  /**
   * If the handset is already allocated, this must be set to `true` for the next button to be enabled. It's the
   * responsibility of the parent component to handle `update:overrideAssigned` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("overrideAssigned", { type: Boolean, default: false })
  syncedOverrideAssigned!: boolean;
  /**
   * If the DUID already exists, this must be set to `true` for the next button to be enabled. It's the responsibility
   * of the parent component to handle `update:overrideUserId` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("overrideUserId", { type: Boolean, default: false })
  syncedOverrideUserId!: boolean;
  /**
   * Make sure the user has cleaned the device, this must be set to `true` for the next button to be enabled. It's the
   * responsibility of the parent component to handle `update:cleaningCheck` events whose payload is what to store.
   * @category Vue Prop
   */
  @PropSync("cleaningCheck", { type: Boolean, default: false })
  syncedCleaningCheck!: boolean;

  /**
   * `true` when the generate DUID request is being made, disables the UI to prevent spamming requests.
   * See [[onUDIChange]].
   * @category Vue Data
   */
  generatingDUID: boolean = false;

  // vuex store bindings
  /**
   * See [[State.userInfo]]
   * @category Vuex Binding
   */
  @State("userInfo") readonly userInfo!: APIUserInfoResponse;
  /**
   * See [[State.userUDIs]]
   * @category Vuex Binding
   */
  @State("userUDIs") readonly userUDIs!: HandsetUDI[];
  /**
   * See [[State.userDUIDs]]
   * @category Vuex Binding
   */
  @State("userDUIDs") readonly userDUIDs!: DUID[] | null;

  /**
   * Whether the user has selected an already allocated device
   * @returns `true` if a UDI is selected and the selected UDI is allocated
   * @category Vue Computed
   */
  get selectedAssignedDevice(): boolean {
    return this.syncedUdi != null && !this.syncedUdi.unassigned;
  }

  /**
   * Resulting DUID that the handset will be allocated to, built from the [[syncedGroup | selected group]] and
   * [[syncedDuidSuffix | DUID suffix]].
   * @returns Group concatenated with the suffix
   * @category Vue Computed
   */
  get duidWithPrefix(): string {
    if (this.syncedGroup == null) {
      return "";
    } else {
      // @ts-ignore
      return `${this.syncedGroup.projectid}-${this.syncedDuidSuffix}`;
    }
  }

  /**
   * Whether [[duidWithPrefix]] already exists. If it does, the user will have to check an additional checkbox.
   * See [[syncedOverrideUserId]].
   * @returns `true` if the selected DUID has been allocated before
   * @category Vue Computed
   */
  get matchesExistingDUID(): boolean {
    // checks whether the currently entered DUID matches an existing DUID
    return this.userDUIDs === null
      ? false
      : this.userDUIDs.some((duid) => duid.duid === this.duidWithPrefix);
  }

  /**
   * Builds a list of dropdown options from the UDIs the user has access to. Includes whether they've been
   * allocated to a DUID already in the option name text. The results will be sorted with unallocated devices first
   * and then by serial number.
   * @returns Options for the handset UDI dropdown
   * @category Vue Computed
   */
  get userUDIOptions(): UDIOption[] {
    const allocatedUDIs = new Set<String | null>(
      // doesn't matter if we get null UDIs in this set, we'll only get 1 anyways
      this.userDUIDs ? this.userDUIDs.map((duid) => duid.udi) : []
    );

    // gets the last 6 characters of each UDI to display in the dropdown
    const options = this.userUDIs
      .map((udi, index) => {
        const unassigned = !allocatedUDIs.has(udi.udi);
        const groupObject = this.userInfo.groups.find(
          (group) => group.id === udi.groupId
        );
        return {
          name: `${deviceTypeForUdi(udi.udi)} ${
            udi.udi.length > 6 ? udi.udi.substring(udi.udi.length - 6) : udi.udi
          }${unassigned ? " (Unallocated)" : " (Allocated)"}`, // what's actually displayed
          value: udi.udi, // representation of displayed value,
          index, // original index for maintaining sort order beyond unassigned first,
          unassigned,
          group: groupObject,
        } as UDIOption;
      })
      // make sure every UDI has a group and isn't unprovisioned
      .filter((option) => option.group && !option.value.includes("X"));

    options.sort((a, b) => {
      if (a.unassigned == b.unassigned) {
        return a.index - b.index;
      } else {
        return a.unassigned ? -1 : 1;
      }
    });

    return options;
  }

  /**
   * Whether additional info inputs and checkboxes are disabled. This happens when there isn't a UDI or group
   * selected, or DUIDs are being automatically generated.
   * @returns `true` if the user hasn't selected all required information for allocation
   * @category Vue Computed
   */
  get inputDisabled() {
    return (
      this.syncedUdi === null ||
      this.syncedGroup === null ||
      this.generatingDUID
    );
  }

  /**
   * Whether the next button is disabled. This happens when [[inputDisabled]], the [[syncedDuidSuffix]] is empty or
   * the required checkboxes haven't been checked.
   * @returns `true` if the user hasn't met all the requirements for handset allocation
   * @category Vue Computed
   */
  get nextDisabled() {
    // whether the next button is disabled
    return (
      this.inputDisabled ||
      !/^[0-9]+$/.test(this.syncedDuidSuffix) ||
      (this.selectedAssignedDevice && !this.syncedCleaningCheck) ||
      (this.selectedAssignedDevice && !this.syncedOverrideAssigned) ||
      (this.matchesExistingDUID && !this.syncedOverrideUserId)
    );
  }

  /**
   * Toggles [[syncedCleaningCheck]]. Handler for paragraph next to checkbox so it also changes the checkbox value.
   * Also makes sure inputs are disabled first.
   */
  toggleCleaningCheck() {
    if (!this.inputDisabled) {
      this.syncedCleaningCheck = !this.syncedCleaningCheck;
    }
  }

  /**
   * Toggles [[syncedOverrideAssigned]]. Handler for paragraph next to checkbox so it also changes the checkbox value.
   * Also makes sure inputs are disabled first.
   */
  toggleOverrideAssigned() {
    if (!this.inputDisabled) {
      this.syncedOverrideAssigned = !this.syncedOverrideAssigned;
    }
  }

  /**
   * Toggles [[syncedOverrideUserId]]. Handler for paragraph next to checkbox so it also changes the checkbox value.
   * Also makes sure inputs are disabled first.
   */
  toggleOverrideUserId() {
    // handler for paragraph next to checkbox so it also changes the checkbox value
    if (!this.inputDisabled) {
      this.syncedOverrideUserId = !this.syncedOverrideUserId;
    }
  }

  /**
   * Handler for the next button. Just emits a `next` event, delegating to the parent component to handle the
   * transition. Will check the next button isn't disabled before emitting.
   */
  next() {
    if (!this.nextDisabled) {
      this.$emit("next");
    }
  }

  /**
   * Watcher for the selected handset UDI. Generates new suggested DUIDs on changes, resetting the UI state
   * beforehand.
   *
   * Note: even though this watches the `udi` path not [[syncedUdi]], it will still work, as [[syncedUdi]] is just
   * a computed getter.
   *
   * @category Vue Watch
   */
  @Watch("udi")
  onUDIChange(udi: UDIOption) {
    this.generatingDUID = true; // disables the UI while generated DUID
    this.syncedGroup = null;
    this.syncedDuidSuffix = "";
    apiClient.getNewDUID().then((newDUIDsRes) => {
      if (newDUIDsRes.data) {
        const newDUIDs = newDUIDsRes.data;
        this.syncedGroup = udi.group;
        const newDUID: string = newDUIDs[udi.group.id];
        if (newDUID) {
          const suffix = newDUID.substring(udi.group.projectid.length + 1);
          this.syncedDuidSuffix = suffix;
          const numericSuffix = parseInt(suffix);
          this.syncedDuidSuffixDefault = isNaN(numericSuffix)
            ? -1
            : numericSuffix;
        }
      }
      this.generatingDUID = false;
    });
  }
}
