import { DISPLAYED_STATISTICS } from "@/config";
import { DeviceDataFetchContext } from "@/store/fetcher/index";
import { APICapnogramResponse } from "@/store/types";

import { percentPointFunction } from "./percentiles";
// import distribution percentiles (these will just be the percentile arrays)
import endTidalCO2Percentiles from "./percentiles/endTidalCO2.json";
import respiratoryRatePercentiles from "./percentiles/respiratoryRate.json";
import metabolicBurdenPercentiles from "./percentiles/metabolicBurden.json";
import obstructiveIndexPercentiles from "./percentiles/obstructiveIndex.json";

/** Uppercase names of possible summary statistics. [[statNames]] is used to iterate these. */
const allowedStatNames = [
  "END TIDAL CO2",
  "RESPIRATORY RATE",
  "METABOLIC BURDEN",
  "OBSTRUCTIVE INDEX",
] as const;

export type DeviceSummaryStatName = (typeof allowedStatNames)[number];

/** Possible colours to use to colour code statistic values */
export type DeviceSummaryStatColour = "red" | "yellow" | "green" | "dark";

/**
 * Represents a range of values (from [[min]] to [[max]]) that should be coloured with [[colour]]. See
 * [[statColourings]] and [[colourStatValue]] for where these are used.
 */
export interface DeviceSummaryStatColouringRange {
  /** Colour to colour a value in this range with */
  colour: DeviceSummaryStatColour;
  /** Minimum value in this range. The next [[min]] should be the previous [[max]]. */
  min: number;
  /** Maximum value in this range. The next [[min]] should be the previous [[max]]. */
  max: number;
}

/**
 * A colouring (how to colour in summary statistics) is made up of contiguous colour ranges. For instance, in the image
 * below (for respiratory rate), there are 5 contiguous range objects, one for each colour block.
 *
 * ![](media://statcolouring.png)
 */
export type DeviceSummaryStatColouring = DeviceSummaryStatColouringRange[];

/**
 * Represents a horizontal line that may be drawn on the trends graph (depending on values of that statistic) at a
 * specified y [[value]] with a [[colour]], and potentially [[dashed]].
 *
 * ![](media://statlines.png)
 */
export interface DeviceSummaryStatColouringLine {
  /** Colour of the line */
  colour: DeviceSummaryStatColour;
  /** Series space y-value this line should be plotted at */
  value: number;
  /** Whether the line should be dashed, not solid */
  dashed?: boolean;
}

/**
 * Represents a marker to be shown on the back side of [[StatisticCard]]s. In the image below (for respiratory rate),
 * there are 3 of these: normal, medium and high.
 *
 * ![](media://statcolouring.png)
 */
export interface DeviceSummaryStatMarker {
  /** Relative percentage position of this marker: 0 is completely to the left, 100 is completely to the right */
  percent: number;
  /** Value this marker represents */
  value: number;
  /** What to label this value and show underneath it */
  description: string;
}

/** Ordered summary stat names (used to show cards/trends in correct/consistent order) */
export const statNames: DeviceSummaryStatName[] = DISPLAYED_STATISTICS.map(
  (s) => {
    if (!allowedStatNames.includes(s as DeviceSummaryStatName))
      throw Error(
        `DISPLAYED_STATISTICS contains ${s} which is not an allowed stat name`
      );

    return s;
  }
) as DeviceSummaryStatName[];

/**
 * Min/max for each statistic, used for the progress bar in [[StatisticCard]]s. Stored as arrays of the form
 * `[min, max]`.
 *
 * ![](media://statcardminmax.png)
 */
export const statRanges: { [key in DeviceSummaryStatName]: number[] } = {
  "METABOLIC BURDEN": [0, 1],
  "END TIDAL CO2": [0, 11],
  "OBSTRUCTIVE INDEX": [0, 1],
  "RESPIRATORY RATE": [0, 40],
};

/**
 * Used as a preference for the trend y-axis scales, however if a value exceeds the maximum, a rounded up copy
 * of that value will be used instead. If that exceeds the [[statGraphRangesMaximums]], that maximum value will be
 * used instead.
 */
export const statGraphRanges: { [key in DeviceSummaryStatName]: number[] } = {
  "METABOLIC BURDEN": [0, 1],
  "END TIDAL CO2": [0, 10],
  "OBSTRUCTIVE INDEX": [0, 1.5],
  "RESPIRATORY RATE": [0, 40],
};

/** Used as hard maximums for the trend y-axis scales, values will be clamped to this if defined as a finite value */
export const statGraphRangesMaximums: {
  [key in DeviceSummaryStatName]: number;
} = {
  "METABOLIC BURDEN": 1,
  "END TIDAL CO2": Infinity,
  "OBSTRUCTIVE INDEX": 2,
  "RESPIRATORY RATE": Infinity,
};

/** How many decimal places to round each statistic to before displaying */
export const statPrecision: { [key in DeviceSummaryStatName]: number } = {
  "METABOLIC BURDEN": 2,
  "END TIDAL CO2": 2,
  "OBSTRUCTIVE INDEX": 2,
  "RESPIRATORY RATE": 0,
};

/**
 * Unit of each statistic to be displayed in [[StatisticCard]]s and on [[PanelTrends]]. If this is empty (""), the
 * unit won't be shown/take up space.
 */
export const statUnits: { [key in DeviceSummaryStatName]: string } = {
  "METABOLIC BURDEN": "mmol/s",
  "END TIDAL CO2": "kPa",
  "OBSTRUCTIVE INDEX": "",
  "RESPIRATORY RATE": "bpm",
};

/**
 * Used for the text on the back of each [[StatisticCard]]. This shouldn't really be called a tooltip anymore, but it's
 * used for the same purpose as one.
 */
export const statTooltips: { [key in DeviceSummaryStatName]: string } = {
  "METABOLIC BURDEN":
    "Metabolic burden (CO2/second) is a marker of the body’s overall metabolic rate, estimated from the mmol of CO2 " +
    "respired during normal breathing.",
  "END TIDAL CO2":
    "End-tidal CO2 is the partial pressure of CO2 at the very end of exhalation.",
  "OBSTRUCTIVE INDEX":
    "Obstructive Index is a marker of (small) airways obstruction.",
  "RESPIRATORY RATE":
    "Respiratory rate is the number of breaths someone takes per minute.",
};

/**
 * Lines to display on the trends graph if values exceed the colour boundaries defined by [[statColourings]].
 * For instance, red coloured lines are only shown if the data has red values. See [[DeviceSummaryStatColouringLine]].
 */
export const statColouringLines: {
  [key in DeviceSummaryStatName]: DeviceSummaryStatColouringLine[];
} = {
  "METABOLIC BURDEN": [],
  "END TIDAL CO2": [
    { colour: "green", value: 4 },
    { colour: "green", value: 6 },
  ],
  "OBSTRUCTIVE INDEX": [
    { colour: "green", value: 0.4 },
    { colour: "red", value: 0.6 },
  ],
  "RESPIRATORY RATE": [
    { colour: "red", value: 8, dashed: true },
    { colour: "green", value: 12 },
    { colour: "green", value: 20 },
    { colour: "red", value: 24, dashed: true },
  ],
};

/**
 * How to colour each statistic, for each colouring the max of the previous item should be the min of the next.
 * See [[colourStatValue]] for how these colourings are actually used.
 */
export const statColourings: {
  [key in DeviceSummaryStatName]: DeviceSummaryStatColouring;
} = {
  "METABOLIC BURDEN": [{ colour: "dark", min: 0, max: 1 }],
  "END TIDAL CO2": [
    // green=[4-6], yellow=[everything else]
    { colour: "yellow", min: 0, max: 4 },
    { colour: "green", min: 4, max: 6 },
    { colour: "yellow", min: 6, max: 11 },
  ],
  "OBSTRUCTIVE INDEX": [
    // green=[0-0.4], yellow=[0.4-0.6], red=[>0.6]
    { colour: "green", min: 0, max: 0.4 },
    { colour: "yellow", min: 0.4, max: 0.6 },
    { colour: "red", min: 0.6, max: 1 },
  ],
  "RESPIRATORY RATE": [
    // green=[12-20], yellow=[8-12 or 20-30], red=[<8 or >30]
    { colour: "red", min: 0, max: 8 },
    { colour: "yellow", min: 8, max: 12 },
    { colour: "green", min: 12, max: 20 },
    { colour: "yellow", min: 20, max: 30 },
    { colour: "red", min: 30, max: 40 },
  ],
};

/**
 * Gets the colour for a value, given that it is a certain stat. If the value is less than the first
 * [[DeviceSummaryStatColouringRange.min | min]], the first [[DeviceSummaryStatColouringRange.colour | colour]] will
 * be used. If the value is greater than the last [[DeviceSummaryStatColouringRange.max | max]], the last
 * [[DeviceSummaryStatColouringRange.colour | colour]] will be used.
 * @param stat - Statistic this value is associated with
 * @param value - Value of this statistic to colour
 * @returns Colour to use when displaying the value
 */
export function colourStatValue(
  stat: DeviceSummaryStatName,
  value: number
): DeviceSummaryStatColour {
  const colouring = statColourings[stat];
  // find the first matching range
  for (const range of colouring) {
    if (value < range.max) {
      return range.colour;
    }
  }
  // otherwise, return the last colour
  return colouring[colouring.length - 1].colour;
}

/**
 * Markers to display on the back of [[StatisticCard]]s. See [[DeviceSummaryStatMarker]].
 * If an empty array is defined, no markers will be shown.
 */
export const statMarkers: {
  [key in DeviceSummaryStatName]: DeviceSummaryStatMarker[];
} = {
  "METABOLIC BURDEN": [],
  "END TIDAL CO2": [
    { percent: 40, value: 4, description: "" },
    { percent: 60, value: 6, description: "" },
    { percent: 80, value: 9, description: "" },
  ],
  "OBSTRUCTIVE INDEX": [
    { percent: 40, value: 0, description: "" },
    { percent: 60, value: 0.4, description: "" },
    { percent: 80, value: 0.6, description: "" },
  ],
  "RESPIRATORY RATE": [
    { percent: 40, value: 12, description: "" },
    { percent: 60, value: 20, description: "" },
    { percent: 80, value: 30, description: "" },
  ],
};

/**
 * Percentiles used when calculated the normalised population positions for each stat. See [[percentPointFunction]]
 * and [[NormalisedPlot]] for where these are used.
 */
export const statPercentiles: {
  [key in DeviceSummaryStatName]: number[];
} = {
  "METABOLIC BURDEN": metabolicBurdenPercentiles,
  "END TIDAL CO2": endTidalCO2Percentiles,
  "OBSTRUCTIVE INDEX": obstructiveIndexPercentiles,
  "RESPIRATORY RATE": respiratoryRatePercentiles,
};

/** Data object representing the current state of a summary stat, including it's current and previous value */
export interface DeviceSummaryStat {
  /** What colour the current value corresponds to */
  colour?: DeviceSummaryStatColour;
  /** Raw, un-rounded value of this statistic */
  rawValue?: number;
  /** Rounded value of this statistic to [[statPrecision]] decimal places */
  value?: number;
  /** Rounded **previous** value of this statistic to [[statPrecision]] decimal places */
  previousValue?: number;
  /**
   * Percentage through the [[statRanges]] for this statistic. Used for the progress bar at the top of
   * [[StatisticCard]]s.
   */
  percent?: number;
  /**
   * Position on the "normal"ish distribution for this statistic. Result of passing [[value]] to the
   * [[percentPointFunction]].
   */
  normalised?: number;
}

/** Data object containing all summary stats for a capnogram */
export type DeviceSummaryStats = {
  [key in DeviceSummaryStatName]: DeviceSummaryStat;
};

/**
 * Extracts the values from the capnogram for each of the displayed [[DeviceSummaryStatName | summary stats]]. If any
 * of the places where these stats are defined aren't themselves defined, those stats will be empty objects.
 * @param capnogram - Capnogram to extract values from. If this is undefined, the returned stats will be empty.
 * @returns Extracted objects containing just the [[rawValue]]s for each of the [[DeviceSummaryStatName]]s
 */
function _extractCapnogramStats(
  capnogram?: APICapnogramResponse
): DeviceSummaryStats {
  const stats = {
    "METABOLIC BURDEN": {},
    "END TIDAL CO2": {},
    "OBSTRUCTIVE INDEX": {},
    "RESPIRATORY RATE": {},
  } as DeviceSummaryStats;

  if (capnogram) {
    if (capnogram.data_par_breath_avg !== null) {
      stats["METABOLIC BURDEN"].rawValue =
        capnogram.data_par_breath_avg.metBurden[0];
      stats["END TIDAL CO2"].rawValue = capnogram.data_par_breath_avg.PetCO2[0];
      stats["OBSTRUCTIVE INDEX"].rawValue =
        capnogram.data_par_breath_avg.epTangent[0];
    }
    if (capnogram.data_par_cap !== null) {
      stats["RESPIRATORY RATE"].rawValue = capnogram.data_par_cap.RR_est;
    }
  }

  return stats;
}

/**
 * Rounds `x` to the given number of decimal places.
 *
 * **Examples**
 *
 * ```
 * round(0.533, 2) === 0.53
 * round(0.537, 2) === 0.54
 * round(0.533, 0) === 1
 * ```
 *
 * @param x - Number to round
 * @param dp - Number of decimal places to round to, should be greater than or equal to 0
 * @returns `x` rounded to `dp` decimal places
 */
function round(x: number, dp: number): number {
  const multiplier = Math.pow(10, dp);
  return Math.round(x * multiplier) / multiplier;
}

/**
 * Extracts and processes the summary stats from the capnogram in the [[DeviceDataFetchContext]]. Also processes the
 * previous capnogram to get values to compare against. Computes the correct colour for each value based on expected
 * ranges, along with the position in the normal distribution.
 * @param ctx - Context to get the capnograms to get summary stats from
 * @returns Processed summary stats, including values from the previous capnogram if one is provided
 */
export async function fetchSummaryStats(
  ctx: DeviceDataFetchContext
): Promise<DeviceSummaryStats> {
  // extract stats from the current and previous capnograms
  const stats = _extractCapnogramStats(ctx.capnogram);
  const previousStats = _extractCapnogramStats(ctx.previousCapnogram);

  for (const statKey in stats) {
    // required for JavaScript "for ... in ..." loops over objects
    if (!stats.hasOwnProperty(statKey)) continue;

    // need to type cast here as statKey is just a string
    const statName = statKey as DeviceSummaryStatName;
    const stat = stats[statName];
    const previousStat = previousStats[statName];

    // check we've got values for this stat
    if (stat.rawValue !== undefined && stat.rawValue !== null) {
      // compute parameters based on this stat's value
      const [min, max] = statRanges[statName];
      stat.value = round(stat.rawValue, statPrecision[statName]);
      stat.percent = ((stat.rawValue - min) / (max - min)) * 100;
      stat.normalised = percentPointFunction(
        statPercentiles[statName],
        stat.rawValue
      );
      stat.colour = colourStatValue(statName, stat.rawValue);

      // if we've got a previous value, use it too
      if (previousStat.rawValue) {
        stat.previousValue = round(
          previousStat.rawValue,
          statPrecision[statName]
        );
      }
    }
  }

  return stats;
}
