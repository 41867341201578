// Polyfill fetch and Object.fromEntries if required
import "whatwg-fetch";
if (!Object.fromEntries) {
  Object.fromEntries = function (arr: any[]) {
    return arr.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  };
}

import "normalize.css";
import "core-js/features/url";
import "core-js/features/url-search-params";
import "@/errors";
import { isBrowserSupported } from "@/browsers";

import Vue from "vue";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { ACTION_LOGIN, MUTATION_ADD_ERROR } from "@/store/constants";
import { eulaRedirect } from "@/views/panels/login/loginScripts";
import { Route } from "vue-router";

Vue.config.productionTip = false;

/** Information overlay wrapper. See [[showInfo]] for more details. */
const infoOverlay = document.getElementById("information");
/** Information overlay title. See [[showInfo]] for more details. */
const infoOverlayTitle = document.getElementById("information-title");
/** Information overlay body. See [[showInfo]] for more details. */
const infoOverlayBody = document.getElementById("information-body");

/**
 * Used when waiting for long running operations in tests (i.e. receiving emails). Will cover the whole screen when
 * active. Mainly so we can see what's happening in video recordings of tests.
 *
 * ![](media://infooverlay.png)
 *
 * @param title - Title of the information overlay
 * @param body - Body to be displayed under the title of the information overlay
 */
function showInfo(title?: string, body: string = "") {
  if (infoOverlay && infoOverlayTitle && infoOverlayBody) {
    if (title) {
      infoOverlayTitle.innerText = title;
      infoOverlayBody.innerText = body;
      infoOverlay.style.display = "block";
    } else {
      infoOverlay.style.display = "none";
    }
  }
}

(window as any).showInfo = showInfo;

if (isBrowserSupported()) {
  router.beforeEach((to, from, next) => {
    // Check if logged in
    const loginRequired = isPermissionRequired(to, "requiresLogin");
    if (loginRequired && !store.getters.loggedIn) {
      next({ name: "login", query: { to: to.fullPath } });
      return;
    }
    if (
      loginRequired &&
      !store.state.userInfo?.consented_to_eula &&
      to.name !== "eulaConsent"
    ) {
      next({ name: "eulaConsent", query: { to: to.fullPath } });
      return;
    }

    // Check permissions (cascade through parent permissions)
    const rwDashRequired = isPermissionRequired(to, "requiresRwDash");
    const ntdUiRequired = isPermissionRequired(to, "requiresNtdUiPermission");
    if (
      (rwDashRequired && !store.getters.hasRwDashPermission) ||
      (ntdUiRequired && !store.getters.hasNtdUiPermission)
    ) {
      // reset navigation
      store.commit(MUTATION_ADD_ERROR, {
        message: "You don't have permission to view this page.",
      });
      next({ name: "eulaConsent" });
      return;
    }

    // Conditional Redirects
    if (to.name === "product") {
      if (
        store.getters.hasRwDashPermission &&
        !store.getters.hasNtdUiPermission
      ) {
        next({ name: "list" });
      } else if (
        !store.getters.hasRwDashPermission &&
        store.getters.hasNtdUiPermission
      ) {
        next({ name: "ntd" });
      }
    } else if (to.name === "login" && store.getters.loggedIn) {
      next({ name: "eulaConsent", query: to.query });
    } else if (
      to.name === "eulaConsent" &&
      store.getters.loggedIn &&
      store.state.userInfo?.consented_to_eula
    ) {
      // If they're already logged in, redirect them either to the path in the urls query or
      // to the product page
      const redirect = eulaRedirect(to);
      next(redirect);
    }
    next();
  });

  // don't mount the app if we're on the test setup page (used for setting stuff in local storage such as user
  // credentials before loading the app)
  if (window.location.pathname === "/__test") {
    // Used to logout etc. when running tests
    (window as any).store = store;
    (window as any).router = router;
  } else {
    // login with stored credentials if we've got them, before mounting the app
    // noinspection JSIgnoredPromiseFromCall
    store.dispatch(ACTION_LOGIN, {}).then(() => {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    });
  }
} else {
  document.getElementById("incompatible-browser")!.style.display = "block";
}

function isPermissionRequired(to: Route, permission: string): boolean {
  if (to.meta && permission in to.meta) {
    return to.meta[permission];
  }
  return to.matched.some((route) => route.meta[permission]);
}
