
import Vue from "vue";
import Tab from "./Tab.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component representing a group of [[Tab]] headers. Set up so `v-model` can be used for 2 way data binding on the
 * selected tab, with `input` events containing the newly selected tab when a [[Tab]] is clicked.
 *
 * ![](media://tabs.png)
 */
@Component({
  name: "tabs",
  components: { Tab },
})
export default class Tabs extends Vue {
  /**
   * List of [[Tab]] header labels to display.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  readonly options!: string[];
  /**
   * Currently selected [[Tab]] label.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly value!: string;
}
