
import Vue from "vue";
import Component from "vue-class-component";

/**
 * Component representing a sheet of virtual "paper" that has padding, a light background colour and rounded corners.
 * The default slot will be render on top of the "paper".
 */
@Component({
  name: "card",
})
export default class Card extends Vue {}
