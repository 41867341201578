
import Vue from "vue";
import {
  autoTick,
  ChartDataPoint,
  ChartDataScales,
  ChartDataSeries,
  normY,
} from "@/components/graphics/utils";
import Card from "@/components/Card.vue";
import Chart from "@/components/graphics/Chart.vue";
import Toggle from "@/components/Toggle.vue";
import Component from "vue-class-component";
import { ChartAverageWaveform } from "@/store/fetcher/waveform";
import { Prop } from "vue-property-decorator";

/**
 * Panel displaying an average approximation of all breaths in the capnogram. This will have been precomputed during
 * device processing. This panel also contains a toggle switch for showing the previous waveform underneath the
 * current one greyed out.
 *
 * ![](media://panelwaveform.png)
 */
@Component({
  name: "panel-waveform",
  components: { Toggle, Chart, Card },
})
export default class PanelWaveform extends Vue {
  /** Expose [[src/components/graphics/utils.normY | normY]] to the template so
   * it can be used for formatting data points. */
  normY = normY;

  /** Whether to underlay *(is this a word?)* the previous chart for comparison */
  showPrevious: boolean = false;

  /** See [[State.deviceWaveform]] */
  @Prop() readonly averageWaveform!: ChartAverageWaveform | null;
  /** See [[State.deviceCapnogramStartTime]] */
  @Prop() readonly capnogramStartTime!: string | null;

  /** Whether to display the [[showPrevious]] toggle */
  @Prop({ type: Boolean, default: true })
  readonly displayPreviousToggle!: boolean;

  /**
   * Y-axis ticks generated from the last/top series, these will be displayed with flex
   * [space-between](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content) so as long as they uniformly
   * cover the entire range, we don't need to calculate additional positioning information, just the labels.
   * @returns Tick labels that should be evenly spaced on the left axis
   * @category Vue Computed
   */
  get yTicks(): string[] {
    if (!this.averageWaveform) return [];

    return autoTick(
      // last series' scales
      this.averageWaveform.scales[this.averageWaveform.scales.length - 1].y[1]
    );
  }

  /**
   * Get the series that should be displayed on the chart (may/may not include the previous average waveform)
   * @returns Array of series to show on the waveform chart
   * @category Vue Computed
   */
  get visibleSeries(): ChartDataSeries<ChartDataPoint>[] {
    // if we don't have a waveform yet, no series should be displayed
    if (!this.averageWaveform) return [];

    if ((this as any).showPrevious) {
      // if we're showing previous, display all series
      return this.averageWaveform.series;
    } else {
      // otherwise, just the last series
      const lastSeries =
        this.averageWaveform.series[this.averageWaveform.scales.length - 1];
      return lastSeries ? [lastSeries] : [];
    }
  }

  /**
   * Get the names of the series that be displayed on the chart, used to ensure the fade transition works
   * correctly (these will be the keys used) (may/may not include the previous average waveform)
   * @returns Array of series keys to use for the waveform chart
   * @category Vue Computed
   */
  get visibleSeriesNames(): string[] {
    if (!this.averageWaveform) return [];
    return (this as any).showPrevious ? ["previous", "current"] : ["current"];
  }

  /**
   * Get the scales for the series that should be displayed on the chart (may/may not include the previous average
   * waveform)
   * @returns Array of scales to use for the series to display on the waveform chart
   * @category Vue Computed
   */
  get visibleScales(): ChartDataScales[] {
    // if we don't have a waveform yet, no series should be displayed
    if (!this.averageWaveform) return [];

    if ((this as any).showPrevious) {
      // if we're showing previous, display all series
      return this.averageWaveform.scales;
    } else {
      // otherwise, just the last series
      const lastScales =
        this.averageWaveform.scales[this.averageWaveform.scales.length - 1];
      return lastScales ? [lastScales] : [];
    }
  }

  /**
   * Make the the visual changes to the charts required by the NTD results page,
   * e.g. those in https://camresp.atlassian.net/browse/CAM-146.
   * Defaults to `false`.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false })
  readonly ntdResultChart!: boolean;
}
