
import store from "@/store";
import { ACTION_LOGOUT } from "@/store/constants";
import { defineComponent } from "vue";

export default defineComponent({
  beforeCreate() {
    store.dispatch(ACTION_LOGOUT);
  },
});
