import { ActionContext, Commit } from "vuex";
import { USER_IDLE_TIMEOUT_DURATION } from "@/config";
import { Store } from "@/store";
import {
  defaultAdditionalInfo,
  DeviceAdditionalInfo,
} from "@/store/additionalInfo";
import { APIDUID, DUID, ErrorLevel, HandsetUDI } from "@/store/types";
import { DeviceDataFetcher } from "@/store/fetcher";
import { DialogEULAState, State } from "@/store/internal/state";
import { State as ListState } from "@/store/internal/modules/listView";
import {
  MutationTypes,
  LocalState as NtdState,
  ntdPrefix,
} from "@/store/internal/modules/ntd";
import {
  ACTION_FETCH_DUIDS,
  ACTION_FETCH_UDIS,
  ACTION_FETCH_USER_DATA,
  ACTION_RESET_STATE,
  LS_ACCESS_TOKEN,
  LS_REFRESH_TOKEN,
  LS_USER_KEY,
  MUTATION_ADD_ERROR,
  MUTATION_RESET_STATE,
  MUTATION_LIST_RESET_STATE,
  MUTATION_SET_DEVICE_ADDITIONAL_INFO,
  MUTATION_SET_DEVICE_DATA,
  MUTATION_SET_LIST_VIEW_FILTER,
  MUTATION_SET_LIST_VIEW_SELECTED_STUDY,
  MUTATION_SET_LIST_VIEW_SORT,
  MUTATION_SET_USER,
  MUTATION_SET_USER_DATA_DUID,
  MUTATION_SET_USER_DUIDS,
  MUTATION_SET_USER_INFO,
  MUTATION_SET_USER_KEY,
  MUTATION_SET_USER_UDIS,
  MUTATION_SHOW_EULA_DIALOG,
  MUTATION_UPDATE_USER_DUID,
  ACTION_LOGOUT,
} from "@/store/constants";
import { ANY_STUDY, DEFAULT_SORT } from "@/store/internal/modules/listView";
import router from "@/router";

/**
 * Converts an API representation of a DUID to a frontend-friendly version of one. Would also decrypt
 * additional info if that was required here.
 * @param duid - DUID to convert/decrypt data of
 * @returns Frontend-friendly representation of the DUID
 */
function apiDUIDtoFrontendDUID(/*state,*/ duid: APIDUID): DUID {
  // eslint-disable-next-line prefer-const
  let decryptedAdditionalInfo: DeviceAdditionalInfo = defaultAdditionalInfo();
  if (duid.add_info === "-") {
    duid.add_info = null;
  }
  // if (duid.add_info && state.userKeys) {
  //   try {
  //     decryptedAdditionalInfo = decryptData(
  //       duid.add_info,
  //       state.userKey
  //     );
  //   } catch (e) {
  //     errorReporter.report(e);
  //     // eslint-disable-next-line no-console
  //     console.warn("Unable to decrypt additional information!", e);
  //     commit(MUTATION_ADD_ERROR, {
  //       message: "Unable to decrypt additional information!"
  //     });
  //   }
  // }

  const lastDash = duid.duid.lastIndexOf("-");
  const duidStudy = duid.duid.substring(0, lastDash);
  let duidNumber = parseInt(duid.duid.substring(lastDash + 1));
  if (isNaN(duidNumber)) duidNumber = Infinity;
  return {
    duid: duid.duid,
    duidStudy,
    duidNumber,
    additionalInfo: duid.add_info || "",
    decryptedAdditionalInfo,
    groupIds: duid.groups,
    udi: duid.handset_udi,
    listViewExtra: {
      capnogramCount: duid.capnogram_count,
      latestCapnogramDate: duid.latest_capnogram_dt
        ? new Date(duid.latest_capnogram_dt)
        : undefined,
      overallBreathQuality: duid.capnogram_breath_quality,
      latestBreathQuality: duid.latest_capnogram_breath_quality,
      adherence: duid.adherence,
    },
  } as DUID;
}

// noinspection JSCommentMatchesSignature
/**
 * Connects to pusher asynchronously.
 * @param store - Vuex Store
 */
async function connectToPusher(store: Store<State>) {
  // eslint-disable-next-line no-console
  console.log(`Connecting to pusher...`);
  store.initPusher();
}

// noinspection JSCommentMatchesSignature
/**
 * Fetchers and stores user duids [[getAllDUIDs]]).
 * Processes the fetched DUIDs into a more frontend-friendly formatted, sorting the DUIDs by study and number.
 * See [[SET_USER_DUIDS]] for the mutation that actually sets the data. Also connects to Pusher.
 * @category Vuex Action
 */
export async function FETCH_DUIDS(
  this: Store<State>,
  { commit }: ActionContext<State, State>
) {
  const userDUIDsRes = await this.apiClient.getAllDUIDs();
  if (!userDUIDsRes.data) return;
  // DUIDs are returned as a lists of objects (e.g. {"duids": "..."}) so they need
  // to be extracted
  const userDUIDs = userDUIDsRes.data
    .map((duid) => apiDUIDtoFrontendDUID(/*state,*/ duid))
    .sort((a, b) => {
      // If these are the same study, sort by number instead
      if (a.duidStudy === b.duidStudy) {
        if (a.duidNumber === b.duidNumber) {
          return 0;
        } else if (a.duidNumber < b.duidNumber) {
          return -1;
        } else {
          return 1;
        }
      }
      // Otherwise, sort by study alphabetically
      return a.duidStudy < b.duidStudy ? -1 : 1;
    });
  commit(MUTATION_SET_USER_DUIDS, userDUIDs);
}

// noinspection JSCommentMatchesSignature
/**
 * Fetchers and stores user udis [[getAllUDIs]].
 * See [[SET_USER_UDIS]] for the mutation that actually sets the data. Also connects to Pusher.
 * @category Vuex Action
 */
export async function FETCH_UDIS(
  this: Store<State>,
  { commit }: ActionContext<State, State>
) {
  const userUDIsRes = await this.apiClient.getAllUDIs();

  if (!userUDIsRes.data) return;
  // UDIs returned as a list of objects (e.g. {"udi": "..."}) so they need
  // to be extracted
  const userUDIs: HandsetUDI[] = userUDIsRes.data
    .map((udi) => ({
      udi: udi.handset_udi,
      groupId: udi.group,
    }))
    .sort((a, b) => (a.udi === b.udi ? 0 : a.udi < b.udi ? -1 : 1));

  commit(MUTATION_SET_USER_UDIS, userUDIs);
}

// noinspection JSCommentMatchesSignature
/**
 * Fetchers and stores user data (user info, [[getUserInfo]]; handset UDIs, [[getAllUDIs]]; DUIDs, [[getAllDUIDs]]).
 * Processes the fetched DUIDs into a more frontend-friendly formatted, sorting the DUIDs by study and number.
 * See [[SET_USER_DATA]] for the mutation that actually sets the data. Also connects to Pusher.
 * @category Vuex Action
 */
export async function FETCH_USER_DATA(
  this: Store<State>,
  { commit, dispatch }: ActionContext<State, State>
) {
  // get all user info
  const userInfo = await this.apiClient.getUserInfo();
  if (!userInfo.data) return;
  commit(MUTATION_SET_USER_INFO, userInfo.data);

  // show EULA if user needs to accept it, if they do, this event will be
  // re-dispatched, if not, they'll be logged out
  if (!userInfo.data.consented_to_eula) {
    commit(MUTATION_SHOW_EULA_DIALOG, DialogEULAState.VISIBLE_ACCEPT_REJECT);
    return;
  }

  // TODO: Give control to the page, not the login action
  // https://camresp.atlassian.net/browse/CAM-278
  if (this.getters.hasRwDashPermission) {
    // Don't await otherwise all duids and udis will be loaded on login (which may
    // take a long time)
    dispatch(ACTION_FETCH_UDIS);
    dispatch(ACTION_FETCH_DUIDS);
  }

  // connect to pusher, now we're logged in
  // TODO Remove await - https://camresp.atlassian.net/browse/CAM-131
  try {
    await connectToPusher(this);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

// noinspection JSCommentMatchesSignature
/**
 * Fetches and watches the data attached to the specified DUID. Will call the [[SET_DEVICE_DATA]]] mutation whenever
 * new data arrives. Uses a [[DeviceDataFetcher]] to fetch the data, disposing of the old one if one exists. This
 * means you can only be watching one DUID at a time, but this is fine. Will also set the current additional info
 * via [[SET_DEVICE_ADDITIONAL_INFO]] at the start.
 * @param duid - DUID to fetch data for
 * @category Vuex Action
 */
export async function FETCH_DEVICE_DATA(
  this: Store<State>,
  { state, commit }: ActionContext<State, State>,
  duid: string
) {
  // reset the device data (triggers loading animation)
  commit(MUTATION_SET_DEVICE_DATA, {
    deviceDUID: duid,
  });

  // find duid in user list to get additional info for decryption
  if (state.userDUIDs === null) {
    // this branch should never be called
    // eslint-disable-next-line no-console
    console.warn("User DUIDs weren't loaded when fetching device data!");
    return;
  }
  const userDUID = state.userDUIDs.find((d) => d.duid === duid);
  if (!userDUID) {
    commit(MUTATION_ADD_ERROR, {
      message: "Unable to find device!",
    });
    return;
  }

  commit(MUTATION_SET_DEVICE_ADDITIONAL_INFO, userDUID.decryptedAdditionalInfo);

  // if we've got an active data fetcher, stop it
  this.deviceDataFetcher?.dispose();

  // create a new data fetcher (polls at regular interval for data)
  this.deviceDataFetcher = new DeviceDataFetcher({
    client: this.apiClient,
    pusher: this.pusher!,
    duid: duid,
    udi: userDUID.udi || "",
    onData: (deviceData, fromPusher) => {
      if (deviceData === null) {
        // there isn't any data for this duid yet
        commit(MUTATION_ADD_ERROR, {
          message:
            "We couldn't find any data for this user yet. This page will automatically update when data is available.",
          level: ErrorLevel.INFO,
        });
      } else {
        // set device data
        commit(MUTATION_SET_DEVICE_DATA, deviceData);

        if (fromPusher) {
          // if this change was from Pusher, update the list view too
          this.apiClient.getDUID(duid).then((apiDuid) => {
            if (apiDuid.data) {
              commit(
                MUTATION_SET_USER_DATA_DUID,
                apiDUIDtoFrontendDUID(apiDuid.data)
              );
            }
          });
        }
      }
    },
  });
}

// noinspection JSCommentMatchesSignature
/**
 * Updates the data associated with a DUID. Currently only handles COVID-19 status but also has (commented out) code
 * for additional info. Updates the application state and also makes an API call to update it in the backend.
 * @param duid - DUID to update data for
 * @category Vuex Action
 */
export async function UPDATE_DEVICE_DATA(
  this: Store<State>,
  { state, commit }: ActionContext<State, State>,
  {
    duid,
  }: //additionalInfo
  {
    duid: string;
    additionalInfo?: DeviceAdditionalInfo;
  }
) {
  // find duid object to update
  if (state.userDUIDs === null) {
    // this branch should never be called
    // eslint-disable-next-line no-console
    console.warn("User DUIDs weren't loaded when updating device data!");
    return;
  }
  const userDUID = state.userDUIDs.find((d) => d.duid === duid);
  if (!userDUID) return;

  // if (additionalInfo && state.userKey) {
  //   // eslint-disable-next-line no-console
  //   userDUID.additionalInfo = encryptData(additionalInfo, state.userKey);
  //   userDUID.decryptedAdditionalInfo = additionalInfo;
  //   // update the additional info, if it's the currently selected one
  //   if (state.deviceDUID === duid) {
  //     commit(MUTATION_SET_DEVICE_ADDITIONAL_INFO, additionalInfo);
  //   }
  // }
  commit(MUTATION_UPDATE_USER_DUID, userDUID);
  await this.apiClient.updateDUID(userDUID);
}

// noinspection JSCommentMatchesSignature
/**
 * Allocates a handset to a new DUID. If the allocation is successful, will refresh all user data. Really it should
 * just update the old and new DUIDs.
 * @param udi - UDI of handset to allocate
 * @param duid - New DUID to allocate
 * @param groupId - Group to allocate the handset to
 * @returns API response from the allocation
 * @category Vuex Action
 */
export async function ALLOCATE_DEVICE(
  this: Store<State>,
  { state, dispatch }: ActionContext<State, State>,
  {
    udi,
    duid,
    //additionalInfo,
    groupId,
  }: {
    udi: string;
    duid: string;
    additionalInfo: DeviceAdditionalInfo;
    groupId: number;
  }
) {
  // assert that we've got the required user data
  //if (!state.userKey) throw new Error("Missing userKey!");
  if (!state.userInfo) throw new Error("Missing userInfo!");

  // const encryptedAdditionalInfo = encryptData(
  //   additionalInfo,
  //   state.userKey
  // );

  const username = state.userInfo.username || "UNKNOWN";
  const res = await this.apiClient.allocateDevice({
    udi,
    duid,
    groupId: groupId,
    // additionalInfo: encryptedAdditionalInfo
    additionalInfo: "-",
    assignedBy: username,
    trainer: username,
  });
  if (res.status === 200) {
    // re-fetch user data to get the new DUID
    // FIXME: just update the state from the response of allocateDevice
    await dispatch(ACTION_FETCH_USER_DATA);
  }

  return res;
}

// noinspection JSCommentMatchesSignature
/**
 * De-allocates a handset from the passed DUID. If the de-allocation is successful, will refresh all user data. Really
 * it should just update the DUID.
 * @param udi - UDI of the to handset be de-allocated
 * @returns API response from the de-allocation
 * @category Vuex Action
 */
export async function DEALLOCATE_DEVICE(
  this: Store<State>,
  { state, dispatch }: ActionContext<State, State>,
  udi: string
) {
  if (!state.userInfo) throw new Error("Missing userInfo!");
  const username = state.userInfo.username || "UNKNOWN";
  const res = await this.apiClient.deallocateDevice({
    udi,
    deassignedBy: username,
  });
  if (res.status === 200) {
    // re-fetch user data to get the new DUID
    // FIXME: just update the state from the response of deallocateDevice
    await dispatch(ACTION_FETCH_USER_DATA);
  }
  return res;
}

/**
 * `setTimeout` return value for the current active timeout. Used for the inactive timeout to log the user out when
 * they don't move their mouse for 10 minutes.
 */
let activeTimeoutHandle: any = undefined;
/**
 * Current mouse move listener so that it can be unregistered when the user logs out.
 */
let activeTimeoutMouseListener: any = undefined;

// noinspection JSCommentMatchesSignature
/**
 * Logs in the user. If an email and password are provided, the [[APIClient]] will be used to make a login request
 * to Django, with tokens being stored in local storage. If no credentials are passed, the application will try to
 * load saved credentials from local storage. See the [[SET_USER]] mutation, which stores user details/tokens in
 * local storage. Once the user is logged in, this action dispatches the [[FETCH_USER_DATA]] action and starts the
 * inactive timeout, which logs the user out after 10 minutes of no mouse movement.
 * @param email - Username to login with. If this isn't provided, the saved tokens will be used instead.
 * @param password - Password of user to login as. If this isn't provided, the saved tokens will be used instead.
 * @param remember - Whether to store the users tokens in local storage. If not, the user will have to login again on
 * refresh.
 * @category Vuex Action
 */
export async function LOGIN(
  this: Store<State>,
  { state, getters, commit, dispatch }: ActionContext<State, State>,
  {
    email,
    password,
    remember,
  }: {
    email?: string;
    password?: string;
    remember?: boolean;
  }
) {
  // check if email & password was provided
  if (email && password) {
    const res = await this.apiClient.login(email, password);
    if (res.status === 401) {
      commit(MUTATION_ADD_ERROR, {
        message:
          res.data && res.data["detail"]
            ? res.data["detail"]
            : "No active account found with the given credentials",
      });
      // throw error that can be caught in PanelLogin to reset password field
      throw new Error();
    } else if (res.data) {
      // otherwise "access" and "refresh" JWT tokens should have been provided
      commit(MUTATION_SET_USER, {
        rawAccessToken: res.data.access,
        rawRefreshToken: res.data.refresh,
        remember: remember,
      });

      // generate & store the user key if there is one
      // if (res.data.user_key && res.data.user_key_salt) {
      //   const userKey = await generateFernetKey(
      //     password,
      //     res.data.user_key,
      //     res.data.user_key_salt
      //   );
      //   commit(MUTATION_SET_USER_KEY, userKey);
      // }
    } else {
      throw new Error();
    }
  } else {
    // if not, we're initialising the application

    // check this session has a stored user key, if not, we'll need the user to login again
    const userKey = window.localStorage.getItem(LS_USER_KEY) || null;

    // login with stored credentials
    commit(MUTATION_SET_USER, {
      rawAccessToken: window.localStorage.getItem(LS_ACCESS_TOKEN) || null,
      rawRefreshToken: window.localStorage.getItem(LS_REFRESH_TOKEN) || null,
      remember: true,
    });

    if (userKey !== null) {
      commit(MUTATION_SET_USER_KEY, userKey);
    }
  }

  // if we're now logged in, fetch user data asynchronously
  if (getters.loggedIn) {
    await dispatch(ACTION_FETCH_USER_DATA);

    if (USER_IDLE_TIMEOUT_DURATION) {
      // inactivity timeout (log user out if they don't move their mouse for
      // more than USER_IDLE_TIMEOUT_DURATION minutes)
      clearTimeout(activeTimeoutHandle);
      window.removeEventListener("mousemove", activeTimeoutMouseListener);
      activeTimeoutMouseListener = () => {
        clearTimeout(activeTimeoutHandle);
        activeTimeoutHandle = setTimeout(() => {
          commit(MUTATION_ADD_ERROR, {
            message: `You've been inactive for more than ${USER_IDLE_TIMEOUT_DURATION} minutes. Please login again.`,
            level: ErrorLevel.INFO,
          });
          dispatch(ACTION_LOGOUT);
        }, 1000 * 60 * USER_IDLE_TIMEOUT_DURATION);
      };
      activeTimeoutMouseListener();
      window.addEventListener("mousemove", activeTimeoutMouseListener);
    }
  }
}

/**
 * Resets data of the current user.
 * @param exempt - List of main state keys not to be reset
 * @param listExempt - List of state keys from ./modules/listView.ts not to be reset
 * @param ntdExempt - List of state keys from ./modules/ntd.ts  not to be reset
 * @category Vuex Action
 */
export async function RESET_STATE(
  this: Store<State>,
  { commit }: ActionContext<State, State>,
  {
    exempt = [],
    listExempt = [],
    ntdExempt = [],
  }: {
    exempt?: Array<keyof State>;
    listExempt?: Array<keyof ListState>;
    ntdExempt?: Array<keyof NtdState>;
  }
) {
  // disable inactivity timeout
  clearTimeout(activeTimeoutHandle);
  window.removeEventListener("mousemove", activeTimeoutMouseListener);

  // if we've got an active data fetcher, stop it
  this.deviceDataFetcher?.dispose();

  // disconnect from pusher
  // eslint-disable-next-line no-console
  console.log("Disconnecting from pusher");
  this.pusher?.disconnect();
  this.pusher = undefined;

  // remove any cached data
  this.apiClient.flushCache();

  // clear vuex state (side effect: clears parts of local storage)
  commit(MUTATION_SET_USER, {
    rawAccessToken: null,
    rawRefreshToken: null,
    remember: false,
  });

  commit(MUTATION_RESET_STATE, exempt);
  commit(MUTATION_LIST_RESET_STATE, listExempt);
  commit(ntdPrefix(MutationTypes.RESET_STATE), ntdExempt);

  localStorage.clear();
  sessionStorage.clear();
}

/**
 * Logs out the current user: resetting application state, clearing any saved tokens in local storage, stopping
 * the inactive timeout and disconnecting from Pusher. Redirects the user to login page when complete.
 * @category Vuex Action
 */
export async function LOGOUT(
  this: Store<State>,
  { dispatch }: ActionContext<State, State>
) {
  // clears the entire vuex store excluding errors
  dispatch(ACTION_RESET_STATE, {
    exempt: ["errors", "errorCount"],
  });

  try {
    await router.push({ name: "login" });
  } catch (e) {
    // ignore error, only when navigation duplicated
  }
}
