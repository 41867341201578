
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component for a simple dropdown menu with no search. See [[Dropdown]] for a more advanced component that displays
 * the currently selected option and allows filtering.
 */
@Component({
  name: "dropdown",
})
export default class DropdownMenu extends Vue {
  /**
   * Array of selectable options. A `click` event with the clicked option will be emitted when an option is clicked.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  readonly options!: string[];
}
