
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component representing an iOS-style toggle switch for a boolean value. See [[ViewUITest]] for example usage. Set
 * up for `v-model` bindings.
 *
 * ![](media://toggle.png)
 */
@Component({
  name: "toggle",
})
export default class Toggle extends Vue {
  /**
   * Current state of the input, if this is `true`, the toggle will have a blue background instead.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly value!: boolean;
  /**
   * Whether the input won't respond to clicks. When it is, no `input` events will be emitted.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  /**
   * Whether to use the small toggle style. Designed for [[Chart]] headers.
   *
   * ![](media://togglesmall.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly small!: boolean;

  /**
   * Click handler for the toggle. If the input isn't [[disabled]], will emit `input` events with the [[value]]
   * inverted.
   */
  toggle() {
    if (!this.disabled) {
      // invert the value on click
      this.$emit("input", !this.value);
    }
  }
}
