
import { Vue, Component } from "vue-property-decorator";
import { ENV_NAME } from "@/config";

/**
 * Component for displaying which environment the user is currently using.
 * Gets the environment from an environment variable called ENV_NAME.
 * Displays nothing if ENV_NAME is not set or if ENV_NAME begins with "prod".
 */
@Component
export default class EnvironmentBanner extends Vue {
  /** Environment name from the configuration */
  envName: string = ENV_NAME;

  /**
   * Calculates the banner colour base on the envName
   * @returns styles object including colour of banner
   */
  get bannerStyles() {
    // defaults to black
    let colour: string = "black";
    if (this.envName === "local") colour = "purple";
    if (this.envName === "development") colour = "red";
    if (this.envName === "test") colour = "orange";
    if (this.envName === "staging") colour = "blue";

    return {
      "--environment-banner-color": colour,
    };
  }
}
