// import { Buffer } from "buffer";
// import { pbkdf2 } from "pbkdf2";
// // no type annotations available for fernet so we have to @ts-ignore
// // @ts-ignore
// import fernet from "fernet";
//
// // derive the initial fernet key from the users password and salt
// function deriveKey(
//   password: string | Buffer,
//   salt: string | Buffer,
//   // Django's default settings
//   iterations: number = 100000,
//   length: number = 32
// ) {
//   return new Promise((resolve, reject) => {
//     pbkdf2(password, salt, iterations, length, "sha256", (err, derivedKey) => {
//       if (err) {
//         reject(err);
//       } else {
//         resolve(derivedKey.toString("base64"));
//       }
//     });
//   });
// }
//
// export async function generateFernetKey(
//   // from user when they login
//   userPassword: string,
//   // from /api/user/login or /api/user/info
//   userKey: string,
//   userKeySalt: string
// ): Promise<string> {
//   // decode strings into buffers
//   const userPasswordBuffer = new Buffer(userPassword, "utf8");
//   const userSaltBuffer = new Buffer(userKeySalt, "base64");
//   // derive the fernet key used to decrypt the userKey
//   const derivedKey = await deriveKey(userPasswordBuffer, userSaltBuffer);
//   const derivedFernetSecret = new fernet.Secret(derivedKey);
//   // decrypt & return the decrypted userKey
//   const userKeyToken = new fernet.Token({
//     secret: derivedFernetSecret,
//     token: userKey,
//     ttl: 0
//   });
//   // returned value is then used to encrypt/decrypt additional info
//   return userKeyToken.decode();
// }

/** Current structure of the additional info that is stored encrypted, may change in the future */
export interface DeviceAdditionalInfo {
  /** Hospital identification number */
  hospitalNumber: string;
  /** Date of birth */
  dob: string;
  /** Full name of the user */
  name: string;
}

/**
 * Get a copy of the default/empty additional info (this is a function for the same reason a vue component's
 * data option is a function)
 * @returns New copy of default additional info
 */
export function defaultAdditionalInfo(): DeviceAdditionalInfo {
  return {
    hospitalNumber: "",
    dob: "",
    name: "",
  };
}

// // returns an encrypted copy of the passed additional `data`, using the user's encryption `key`
// export function encryptData(data: DeviceAdditionalInfo, key: string): string {
//   const json = JSON.stringify(data);
//   const secret = new fernet.Secret(key);
//   const token = new fernet.Token({ secret });
//   return token.encode(json);
// }
//
// // returns a decrypted copy of the passed additional `encryptedData`, using the user's encryption `key`
// export function decryptData(
//   encryptedData: string,
//   key: string
// ): DeviceAdditionalInfo {
//   const secret = new fernet.Secret(key);
//   const token = new fernet.Token({ secret, token: encryptedData, ttl: 0 });
//   return JSON.parse(token.decode());
// }
