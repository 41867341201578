
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component used by [[StatisticCard]] to annotate the colour ranges on the back of the card. Consists of a
 * line with text describing the value beneath and to the right.
 *
 * ![](media://statisticcardmarker.png)
 */
@Component({
  name: "statistic-card-marker",
})
export default class StatisticCardMarker extends Vue {
  /**
   * Percentage along the progress bar to mark. Should be a number in the range $[0, 100]$. Unlike [[StatisticCard]]
   * this value is not clamped before being displayed, as it is expected to be static and predefined.
   * @category Vue Prop
   */
  @Prop({ default: "0" }) readonly percent!: string | number;
  /**
   * Value of the statistic this position represents. This will be displayed in (relatively) large text beneath the
   * line.
   * @category Vue Prop
   */
  @Prop({ required: true }) readonly value!: string | number;
  /**
   * Optional unit to display in small text next to the value.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly unit!: string;
  /**
   * Description/interpretation displayed under [[value]] of this value.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly description!: string;
}
