
import Vue from "vue";
import Component from "vue-class-component";
import CardDialog from "@/components/CardDialog.vue";
import { apiClient } from "@/store";
import {
  ACTION_FETCH_USER_DATA,
  MUTATION_SHOW_EULA_DIALOG,
} from "@/store/constants";
import { State } from "vuex-class";
import { DialogEULAState } from "@/store/internal/state";
import { eulaRedirect } from "./loginScripts";

/**
 * Dialog for displaying the End User Licensing Agreement to the user. They can
 * either accept or reject it. This will only be shown when the user first
 * logs in to the application.
 */
@Component({
  name: "dialog-eula",
  components: { CardDialog },
})
export default class DialogEULA extends Vue {
  DialogEULAState = DialogEULAState;

  /**
   * See [[State.dialogEULA]]
   * @category Vuex Binding
   */
  @State("dialogEULA") readonly dialogEULA!: DialogEULAState;

  /**
   * Accepts the EULA for the current user. If this is successful, hide the
   * EULA dialog and re-fetch user data (will have been aborted if not
   * consented to EULA)
   */
  async accept() {
    const res = await apiClient.consentToEULA();
    if (res.status === 204) {
      this.$store.commit(MUTATION_SHOW_EULA_DIALOG, DialogEULAState.HIDDEN);
      // Refresh user info before continuing
      await this.$store.dispatch(ACTION_FETCH_USER_DATA);
      this.$router.replace(eulaRedirect(this.$route));
    }
  }

  /**
   * If the user rejects the EULA, just log them out, there's not much else we
   * can do.
   */
  reject() {
    this.$router.push({ name: "logout" });
  }

  /**
   * If the dialog was opened with just a close button, hide the dialog when
   * this is clicked.
   */
  close() {
    this.$store.commit(MUTATION_SHOW_EULA_DIALOG, DialogEULAState.HIDDEN);
  }
}
