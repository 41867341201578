
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component for displaying a progress bar followed by a percentage. Used for displaying summary stats in the device
 * list view. Doesn't animate any changes, but this would be easy to add:
 * just put a `transition: width ...` on `.bar div`.
 *
 * ![](media://percentbar.png)
 */
@Component({
  name: "percent-bar",
})
export default class PercentBar extends Vue {
  /**
   * Percentage to fill the bar in and show to the right of it.
   * @category Vue Prop
   */
  @Prop({ type: Number, required: true }) readonly percent!: number;
}
