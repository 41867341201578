import { State as RootState } from "@/store/internal/state";
import { formatUdi } from "@/store/api";
import { DropdownOption, DUID } from "@/store/types";

/** Constant representing the default sort, which doesn't define any sort. */
export const DEFAULT_SORT = {
  on: "",
  ascending: true,
};

/**
 * Constant used when filtering by any study, this assumes there will never be a
 * study called "All Groups", which there shouldn't be as study names are all
 * uppercase and have dashes not spaces.
 */
export const ANY_STUDY: DropdownOption = {
  value: -1,
  name: "All Groups",
};

export interface State {
  /** Text filter for DUIDs. Only DUIDs matching this will be shown */
  filter: string;
  /** Key we're currently sorting on, along with the direction */
  sort: { on: string; ascending: boolean };
  /** Study filter, start by not filtering on study ([[studyAnyStudy]]). Only DUIDs in this study will be shown */
  selectedStudy: DropdownOption;
}

/**
 * Data object containing all information required to sort and filters DUID in
 * the list view.
 */
export interface ListDUID {
  /** Index of the duid (used to maintain proper duid sorting order) */
  index: number;
  /** Full duid (e.g. `MY-STUDY-1`) */
  duid: string;
  /** Full UDI (e.g. `(01)...(21)...(14)...`) */
  udi: string | null;
  /** Device type (e.g. `NTCA`, `NTC1`, ...) */
  type: string | null;
  /** Studies expressed as numeric group IDs this DUID is currently attached to */
  studies: number[];
  /** Integer serial number of the device */
  serialNumber: number;
  /** 0-padded serial number of the device */
  serialNumberFormatted: string | null;
  /** Date of last capnogram recorded for the duid */
  lastUsed: Date | null;
  /** Unix timestamp of last used time */
  lastUsedTime: number;
  /** Number of records associated with this DUID */
  count: number;
  /** Quality of last breath */
  lastQuality: number;
  /** Quality of all breaths in past 6 months */
  quality: number;
  /** Adherence in past 6 months */
  adherence: number;
}

export function state(): State {
  return {
    filter: "",
    sort: DEFAULT_SORT,
    selectedStudy: ANY_STUDY,
  };
}

export default {
  namespaced: true,
  state,
  mutations: {
    /*
     * Resets the state of the keys (with exceptions).
     * @param exempt - Keys free from resetting
     * @category Vuex Mutation
     */
    RESET_STATE(_state: State, exempt: Array<keyof State>) {
      const newState = state();
      for (const key of exempt) {
        delete newState[key];
      }
      Object.assign(_state, newState);
    },

    /**
     * Sets the text filter for DUIDs. Only DUIDs matching this will be shown.
     * @param state - State of the application [[src/store]] to mutate
     * @param filter - New text filter
     * @category Vuex Mutation
     */
    SET_FILTER(state: State, filter: string) {
      state.filter = filter;
    },
    /**
     * Sets the key/direction of the list view sort.
     * @param state - State of the application [[src/store]] to mutate
     * @param sort - New list view sort
     * @category Vuex Mutation
     */
    SET_SORT(state: State, sort: { on: string; ascending: boolean }) {
      state.sort = sort;
    },
    /**
     * Sets the selected study from the study dropdown in the list view. Only DUIDs in this study will be shown.
     * @param state - State of the application [[src/store]] to mutate
     * @param selectedStudy - New selected study
     * @category Vuex Mutation
     */
    SET_SELECTED_STUDY(state: State, selectedStudy: DropdownOption) {
      state.selectedStudy = selectedStudy;
    },
  },
  getters: {
    /**
     * Converts [[State.userDUIDs]] into friendly [[ListDUID]] objects. This getter may
     * include shenanigans. 😉
     * @returns Array of [[ListDUID]] objects for each of [[State.userDUIDs]]
     * @category Vuex Getter
     */
    getDUIDs(_: State, __: any, rootState: RootState): ListDUID[] | null {
      if (!rootState.userDUIDs) return null;

      return rootState.userDUIDs.map((duid: DUID, index: number) => {
        const { serialNumber, serialNumberFormatted, type } = formatUdi(
          duid.udi
        );

        // get the unix timestamp (in milliseconds) of the capnogram, or 0 if
        // there isn't one
        const lastUsedTime = duid.listViewExtra.latestCapnogramDate
          ? duid.listViewExtra.latestCapnogramDate.getTime()
          : 0;

        return {
          index: index, // store the index to preserve DUID sorting
          duid: duid.duid,
          udi: duid.udi,
          type: type,
          studies: duid.groupIds,
          serialNumber,
          serialNumberFormatted,
          lastUsed: duid.listViewExtra.latestCapnogramDate || null,
          lastUsedTime,
          count: duid.listViewExtra.capnogramCount || 0,
          lastQuality: duid.listViewExtra.latestBreathQuality || 0,
          quality: duid.listViewExtra.overallBreathQuality || 0,
          adherence: Math.round((duid.listViewExtra.adherence || 0) * 100),
        };
      });
    },
    /**
     * Get rows matching the study filter. See [[selectedStudy]]. If this is
     * [[ANY_STUDY]], then all rows will be returned. This will be passed to
     * [[listDUIDsSorted]].
     * @returns Rows matching the study filter
     * @category Vuex Getter
     */
    filterDUIDsByStudy(state: State, getters: any): ListDUID[] {
      const duids = getters.getDUIDs as ListDUID[];

      // @ts-ignore
      if (state.selectedStudy.value === ANY_STUDY.value) return duids;

      return duids.filter((duid) =>
        // @ts-ignore
        duid.studies.includes(state.selectedStudy.value)
      );
    },
    /**
     * Return sorted copy of [[filterDUIDsByStudy]] according to the
     * current sort key/direction, that match the DUID and study filter.
     * @returns Rows matching the study filter and DUID filter, sorted using the
     * current [[sort]] object
     * @category Vue Computed
     */
    sortFilteredDUIDs(state: State, getters: any): ListDUID[] | null {
      const duids = getters.filterDUIDsByStudy as ListDUID[];

      if (duids === null) return null;

      // default to sorting by last used time in ascending order if no sort is specified
      const defaultSort = state.sort.on === "";
      const { on, ascending } = defaultSort
        ? { on: "lastUsedTime", ascending: true }
        : state.sort;
      const multiplier = ascending ? 1 : -1;

      duids.sort((a, b) => {
        // sort by sort on first
        // @ts-ignore
        const aValue: any = a[on];
        // @ts-ignore
        const bValue: any = b[on];

        // if this is the default sort, make sure inactive devices are at the bottom
        const aSerial = a.serialNumberFormatted;
        const bSerial = b.serialNumberFormatted;
        if (defaultSort && aSerial !== bSerial) {
          if (aSerial === null) {
            return 1;
          } else if (bSerial === null) {
            return -1;
          }
        }

        // sort by the value
        if (aValue < bValue) {
          return -1 * multiplier;
        } else if (aValue > bValue) {
          return multiplier;
        }

        // if these are equal and if sort on isn't "index" (basically DUID), try
        // sort by that
        if (on !== "index") {
          if (a.index < b.index) {
            return -1;
          } else if (a.index > b.index) {
            return 1;
          }
        }

        return 0;
      });
      return duids;
    },
  },
};
