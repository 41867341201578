
import Vue from "vue";
import PercentBar from "@/components/PercentBar.vue";
import Loader from "@/components/Loader.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import { APIResponse } from "@/store/api";
import { APIUserInfoResponse, DropdownOption, DUID } from "@/store/types";
import SortHeader from "@/components/SortHeader.vue";
import { longHover } from "@/components/mixins/longHover";
import Dropdown from "@/components/Dropdown.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";
import Tooltip from "@/components/Tooltip.vue";
import IconHDots from "@/assets/icons/IconHDots.vue";
import Component from "vue-class-component";
import { Getter, State } from "vuex-class";
import { Watch } from "vue-property-decorator";
import CardDialogBackground from "@/components/CardDialogBackground.vue";
import DialogInformation from "@/views/panels/dashboard/DialogInformation.vue";
import {
  ACTION_DEALLOCATE_DEVICE,
  MUTATION_SET_LIST_VIEW_FILTER,
  MUTATION_SET_LIST_VIEW_SELECTED_STUDY,
  MUTATION_SET_LIST_VIEW_SORT,
} from "@/store/constants";
import { ANY_STUDY } from "@/store/internal/modules/listView";
import { ListDUID } from "@/store/internal/modules/listView";
import { formatDate, oneDay } from "@/components/graphics/date";

/**
 * Data object which extends the ListDUID type adding fields only used for
 * displaying the duid.
 */
export interface RowDUID extends ListDUID {
  /** Colour of the indicator representing how recently the device has been used */
  colour: string;
  /** Formatted datetime of last used time */
  lastUsedFormatted: string | null;
}

/**
 * Panel showing summary information for all users the logged in user has access
 * to. Allows sorting by each statistic and filtering by study and DUID.
 *
 * ![](media://panellist.png)
 *
 * On mobile, sorting is disabled and the rows are shown as blocks instead.
 *
 * ![](media://panellistmobile.png)
 */
@Component({
  name: "panel-list",
  components: {
    CardDialogBackground,
    Dropdown,
    DropdownMenu,
    PercentBar,
    Tooltip,
    SortHeader,
    Loader,
    PanelFooter,
    IconHDots,
    DialogInformation,
  },
  // we need a long hover mixin for each of the tooltips
  mixins: [
    longHover("Indicator"),
    longHover("LastQuality"),
    longHover("Adherence"),
    longHover("Quality"),
  ],
})
export default class PanelList extends Vue {
  /**
   * See [[State.listView.filter]] and [[filteredRows]].
   * @returns Current value of [[State.listView.filter]]
   */
  get filter(): string {
    return this.$store.state.listView.filter;
  }

  /**
   * Setter for [[State.listView.filter]]
   * @param newFilter - New text filter for DUIDs
   */
  set filter(newFilter: string) {
    this.$store.commit(MUTATION_SET_LIST_VIEW_FILTER, newFilter);
  }

  /**
   * See [[State.listView.sort]] and [[sortFilteredDUIDs]].
   * @returns Current value of [[State.listView.sort]]
   */
  get sort(): { on: string; ascending: boolean } {
    return this.$store.state.listView.sort;
  }

  /**
   * Setter for [[State.listView.sort]]
   * @param newSort - New key and direction we're sorting on
   */
  set sort(newSort: { on: string; ascending: boolean }) {
    this.$store.commit(MUTATION_SET_LIST_VIEW_SORT, newSort);
  }

  /**
   * See [[State.listView.selectedStudy]] and [[sortFilteredDUIDs]]. See
   * [[studyFilteredRows]].
   * @returns Current value of [[State.listView.selectedStudy]]
   */
  get selectedStudy(): DropdownOption {
    return this.$store.state.listView.selectedStudy;
  }

  /**
   * Setter for [[State.listView.selectedStudy]]
   * @param newStudy - New selected study
   */
  set selectedStudy(newStudy: DropdownOption) {
    this.$store.commit(MUTATION_SET_LIST_VIEW_SELECTED_STUDY, newStudy);
  }

  /**
   * Whether the de-allocation dialog is currently visible. `false` if the user
   * isn't de-allocating a DUID at the moment.
   * @category Vue Data
   */
  showDeallocationDialog: boolean = false;
  /**
   * DUID that the user is confirming to be de-allocated. `null` if the user
   * isn't de-allocating a DUID at the moment.
   * @category Vue Data
   */
  deallocatingDUID: string | null = null;
  /**
   * UDI of the handset the user is confirming to be de-allocated. `null` if the
   * user isn't de-allocating a DUID at the moment.
   * @category Vue Data
   */
  deallocatingUDI: string | null = null;
  /**
   * Friendly name of the handset the user is about to de-allocated from
   * [[deallocatingDUID]]. `null` if the user isn't de-allocating a DUID at the
   * moment.
   * @category Vue Data
   */
  deallocatingHandset: string | null = null;
  /**
   * Whether we're currently de-allocating a device. If we are, disable the
   * buttons in the de-allocation dialog to prevent spamming.
   * @category Vue Data
   */
  deallocating: boolean = false;

  /**
   * Resets the DUID and handset that are currently being de-allocated. Called
   * when the de-allocation dialog transitions out. See [[deallocating]] for how
   * to control dialog visibility.
   */
  resetDeallocating() {
    this.deallocatingDUID = null;
    this.deallocatingUDI = null;
    this.deallocatingHandset = null;
  }

  /**
   * See [[State.userDUIDs]]
   * @category Vuex Binding
   */
  @State("userDUIDs") readonly userDUIDs!: DUID[] | null;

  /**
   * See [[src/store/internal/getters.hasDetailPermission | hasDetailPermission]]
   * getter. Bound to only make the rows clickable if we have detail view
   * permissions.
   */
  @Getter("hasDetailPermission") readonly hasDetailPermission!: boolean;

  /**
   * See [[src/store/internal/modules/listView/getters.sortFilteredDUIDs |
   * sortFilteredDUIDs]] getter
   * @category Vuex Binding
   */
  @Getter("listView/sortFilteredDUIDs")
  readonly sortFilteredDUIDs!: ListDUID[];

  /**
   * Gets of list of studies for the study filter dropdown (without duplicates).
   * @returns [[DropdownOption]]s objects representing unique studies
   * @category Vue Computed
   */
  get studies(): DropdownOption[] {
    const userInfo: APIUserInfoResponse | null = this.$store.state.userInfo;

    // if we haven't loaded duids yet, just return any study as an option
    if (!userInfo) return [ANY_STUDY];

    // return studies, putting "Any Study" first
    return [
      ANY_STUDY,
      ...userInfo.groups
        .map((group) => ({
          value: group.id,
          name: group.projectid,
          tooltip: group.name,
        }))
        // sort in ascending name order
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    ];
  }

  /**
   * Return [[listViewDUIDsSorted]] matching the DUID filter and the study filter.
   * See [[filter]]. If [[filter]] is empty, all rows will be returned.
   * @returns Rows matching the study filter and DUID filter
   * @category Vue Computed
   */
  get filteredDUIDs(): ListDUID[] {
    if (this.filter === "") {
      return this.sortFilteredDUIDs;
    } else {
      const upperCaseFilter = this.filter.toUpperCase();
      return this.sortFilteredDUIDs.filter((duid) =>
        duid.duid.includes(upperCaseFilter)
      );
    }
  }

  /**
   * Return [[filteredDUIDs]] with additional properties used for display only.
   * @returns Rows with additional properties for display
   * @category Vue Computed
   */
  get getRows(): RowDUID[] {
    return this.filteredDUIDs.map((duid) => {
      // flooring as we're only interested in full days, giving people a full
      // day to do their records before changing the indicator colour
      const daysSinceLastUsed = Math.floor(
        (Date.now() - duid.lastUsedTime) / oneDay
      );

      let colour: string;
      if (duid.serialNumberFormatted === null /* inactive duid */) {
        colour = "dark";
      } else if (daysSinceLastUsed >= 2) {
        colour = "red";
      } else if (daysSinceLastUsed >= 1) {
        colour = "yellow";
      } /* 0 <= daysSinceLastUsed < 1 */ else {
        colour = "green";
      }

      const lastUsedFormatted = duid.lastUsed
        ? formatDate(duid.lastUsed, true)
        : null;

      return {
        ...duid,
        colour,
        lastUsedFormatted,
      };
    });
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Tries to focus the search input when the page loads (only does this on desktop)
   * @category Vue Lifecycle
   */
  mounted() {
    this.focusFilter();
  }

  /**
   * If we're not on a device with a touch keyboard that would pop-up
   * automatically, focus the filter. Also registers a watcher to do this when
   * `userDUIDs` changes (i.e. user data loads).
   * @category Vue Watch
   */
  @Watch("userDUIDs")
  focusFilter() {
    if (window.matchMedia && !window.matchMedia("(hover: none)").matches) {
      this.$nextTick(() => {
        if (this.$refs.filter) {
          // @ts-ignore
          this.$refs.filter.focus();
        }
      });
    }
  }

  /**
   * Click handler for a row in the list view. Will show the detail view for the
   * selected DUID, only if the user has the detail view permission.
   * @param duid - DUID to select
   */
  clickRow(duid: string) {
    if (this.hasDetailPermission) {
      this.$router
        .push({
          name: "dashboard-with-duid",
          params: {
            duid: duid,
          },
        })
        // ignore errors: will just be navigation duplicated (doesn't really
        // matter if the user wasn't able to navigate to the screen they're
        // already on, <insert confused face>)
        .catch(() => {});
    }
  }

  /**
   * Click handler for clicking the "Deallocate Handset" button in the
   * additional options for a row in the list view. Will show the confirmation
   * dialog first.
   * @param row - Row containing DUID and device to de-allocate
   */
  deallocateRow(row: ListDUID) {
    this.deallocatingDUID = row.duid;
    this.deallocatingUDI = row.udi;
    this.deallocatingHandset = `${row.type} ${row.serialNumberFormatted}`;
    this.showDeallocationDialog = true;
  }

  /**
   * Click handler for cancelling a device de-allocation. Hides the confirmation
   * dialog.
   */
  cancelDeallocate() {
    if (!this.deallocating) {
      this.showDeallocationDialog = false;
    }
  }

  /**
   * Click handler for confirming a device de-allocation. Hides the confirmation
   * dialog and de-allocates [[deallocatingDUID]] via an API request. Will also
   * refresh the list view data.
   */
  async confirmDeallocate() {
    if (!this.deallocating) {
      this.deallocating = true;

      try {
        const res: APIResponse<unknown> = await this.$store.dispatch(
          ACTION_DEALLOCATE_DEVICE,
          this.deallocatingUDI
        );
        if (res.status === 200) {
          // only hide the dialog if there was no error
          this.showDeallocationDialog = false;
        }
      } finally {
        this.deallocating = false;
      }
    }
  }
}
