
import Vue from "vue";
import { State } from "vuex-class";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import IconEdit from "../../../../assets/icons/IconEdit.vue";
import { ACTION_UPDATE_DEVICE_DATA } from "@/store/constants";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import { DropdownOption, DUID } from "@/store/types";

/**
 * Panel displayed in [[PanelDetail]] for viewing/editing additional info. Will only allow users to edit if the pencil
 * has been clicked. When the pencil is clicked, the current values are copied into component data. These are the fields
 * that are bound to inputs with v-model, so inputs aren't saved until the pencil button is clicked again.
 *
 * ![](media://paneladditionalinfo.png)
 */
@Component({
  name: "panel-additional-info",
  components: { TextInput, IconEdit, Dropdown },
})
export default class PanelAdditionalInfo extends Vue {
  /**
   * Whether the pencil has been clicked and additional info is currently editable
   * @category Vue Data
   */
  editing: boolean = false;

  // copies of values that are currently being edited
  /**
   * Editable copy of hospital number
   * @category Vue Data
   */
  editingHospitalNumber: string = "";
  /**
   * Editable copy of date of birth
   * @category Vue Data
   */
  editingDOB: string = "";
  /**
   * Editable copy of device user name
   * @category Vue Data
   */
  editingName: string = "";

  // vuex store bindings
  /**
   * See [[State.deviceDUID]]
   * @category Vuex Binding
   */
  @State("deviceDUID") readonly deviceDUID!: string;
  /**
   * See [[State.deviceCapnogramStartTime]]
   * @category Vuex Binding
   */
  @State("deviceCapnogramStartTime") readonly deviceCapnogramStartTime!: string;
  /**
   * See [[State.userDUIDs]]
   * @category Vuex Binding
   */
  @State("userDUIDs") readonly userDUIDs!: DUID[] | null;
  /**
   * See [[State.userKeys]]
   * @category Vuex Binding
   */
  @State("userKeys") readonly userKeys!: string | null;

  // two-way computed property: when read, calls get; when assigned, calls set
  /**
   * See [[State.deviceAdditionalInfo]]
   * @returns Current value of [[State.deviceAdditionalInfo]]
   */
  get deviceAdditionalInfo() {
    // store binding
    return this.$store.state.deviceAdditionalInfo;
  }

  /**
   * Updates the additional info for the DUID in the store and in the database.
   * @param value - New additional info
   */
  set deviceAdditionalInfo(value: DeviceAdditionalInfo) {
    this.$store.dispatch(ACTION_UPDATE_DEVICE_DATA, {
      duid: this.$route.params.duid,
      additionalInfo: value,
    });
  }

  /**
   * Get the currently selected DUID as a dropdown option. If no DUID is selected, will default to showing "Select
   * User".
   * @returns [[DropdownOption]] representing the currently selected user (will never be `null`)
   * @category Vue Computed
   */
  get selectedDropdownOption(): DropdownOption {
    return {
      value: this.deviceDUID || "",
      // prefer to show the user's name, otherwise default to DUID, otherwise if no duid is selected
      // show "Select Device"
      name: this.deviceAdditionalInfo.name || this.deviceDUID || "Select User",
    };
  }
  /**
   * Returns a list of dropdown options displaying the user's name if we've got it stored, otherwise just the DUID.
   * If [[userDUIDs]] hasn't loaded yet, will just return an empty list.
   * @returns Array of [[DropdownOptions]] for the selectable DUIDs
   * @category Vue Computed
   */
  get dropdownOptions(): DropdownOption[] {
    // convert duids to dropdown options if we've got them
    return this.userDUIDs === null
      ? []
      : this.userDUIDs.map((duid) => ({
          value: duid.duid,
          name: duid.decryptedAdditionalInfo.name || duid.duid,
        }));
  }

  /**
   * Handler for selecting a DUID in the DUID dropdown that changes the page path. This will be picked up by the
   * dashboard view which will trigger a device data reload
   * @param duid - DUID that was selected by the user
   */
  selectDeviceDUID(duid: DropdownOption) {
    this.$router
      .push({
        name: "dashboard-with-duid",
        // @ts-ignore
        params: { duid: duid.value ? duid.value : duid },
        hash: this.$route.hash, // persist the hash (selected trend) between DUID changes
      })
      .catch(() => {}); // ignore errors here, will just be when navigating to the same page
  }

  /**
   * Click handler for the pencil, toggling the editing state. If we're now editing, Copies the current additional info
   * values over to the editable versions. Otherwise, if we're saving the values, updates the additional info using the
   * [[deviceAdditionalInfo]] setter.
   */
  toggleEditing() {
    const nowEditing = !this.editing;

    if (nowEditing) {
      // copy the current values
      this.editingHospitalNumber = this.deviceAdditionalInfo.hospitalNumber;
      this.editingDOB = this.deviceAdditionalInfo.dob;
      this.editingName = this.deviceAdditionalInfo.name;
    } else {
      // update the values (will trigger the computed setter)
      this.deviceAdditionalInfo = {
        hospitalNumber: this.editingHospitalNumber,
        dob: this.editingDOB,
        name: this.editingName,
      };
    }

    this.editing = nowEditing;
  }

  /**
   * Watcher for additional info that updates the editable/displayed values when data changes
   * @param value - New additional info to copy values from
   * @category Vue Watch
   */
  @Watch("deviceAdditionalInfo", { immediate: true })
  onAdditionalInfoChange(value: DeviceAdditionalInfo) {
    if (!this.editing) {
      this.editingHospitalNumber = value.hospitalNumber;
      this.editingDOB = value.dob;
      this.editingName = value.name;
    }
  }

  /**
   * Gets the project the current DUID is part of by removing everything past the last dash.
   * @returns Project of current DUID
   */
  get deviceDUIDProject(): string {
    if (!this.deviceDUID) return "";
    const lastDash = this.deviceDUID.lastIndexOf("-");
    if (lastDash === -1) return this.deviceDUID;
    return this.deviceDUID.substring(0, lastDash);
  }
}
