
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Individual link for the [[Sidebar]]. Contains a item via the default slot
 * along with some text.
 */
@Component
export default class SidebarItem extends Vue {
  /** Text to display next to the icon */
  @Prop({ type: String, required: true }) readonly title!: string;
  /**
   * Where this link should go to, if this is an empty string, will go nowhere
   * by default and a click event listener is required.
   */
  @Prop({ default: "" }) readonly to!:
    | string
    | { name: string; params?: Object };
  /**
   * Whether the link should only be considered active if the route
   * matches exactly, not just by prefix.
   */
  @Prop({ type: Boolean, default: false }) readonly exact!: boolean;
  /**
   * Whether the sidebar item is a simple button. Renders the component as a div.
   */
  @Prop({ type: Boolean, default: false }) readonly isButton!: boolean;

  /**
   * Gets the href attribute for an a tag for this item. If this is defined, a normal a tag with
   * href set to the return value should be used instead of a router-link.
   * @returns href for a tag or undefined if router-link should be used with to as is
   * @category Vue Computed
   */
  get anchorHref() {
    if (typeof this.to === "string" && this.to.startsWith("mailto:")) {
      return this.to;
    }
    return undefined;
  }

  get getComponent() {
    if (this.isButton) return "div";
    if (this.anchorHref) return "a";
    return "router-link";
  }
}
