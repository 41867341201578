
import { defineComponent } from "vue";

import Loader from "@/components/Loader.vue";
import { Store } from "@/store";
import { HandsetV2 } from "@/store/types";
import { MUTATION_ADD_ERROR } from "@/store/constants";
import {
  ActionTypes,
  GetterTypes,
  ntdPrefix,
} from "@/store/internal/modules/ntd";
import { UploadLifecycleWatcher } from "@/store/fetcher";
import DiagnosticNotificationListenerMixin from "@/components/mixins/DiagnosticNotificationListenerMixin";
import CardDialog from "@/components/CardDialog.vue";
import FailedBreathRecord from "@/components/errorMessages/FailedBreathRecord.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";

export default defineComponent({
  name: "SelectHandset",
  components: { Loader, CardDialog, FailedBreathRecord, PanelFooter },
  mixins: [DiagnosticNotificationListenerMixin],
  data(): {
    loading: boolean;
    notificationWatcher: UploadLifecycleWatcher | null;
  } {
    return {
      loading: true,
      notificationWatcher: null,
    };
  },
  computed: {
    hasBorder(): boolean {
      return !(
        this.$route.name === "ntd-results-history" ||
        this.$route.name === "ntd-handset"
      );
    },
    handsetRouteName(): "ntd-handset-results" | "ntd-start-test" | "" {
      if (
        this.$route.matched.some(
          (route) => route.name === "ntd-results-history"
        )
      ) {
        return "ntd-handset-results";
      }
      if (this.$route.matched.some((route) => route.name === "ntd-handset")) {
        return "ntd-start-test";
      }
      return "";
    },
    handsets(): HandsetV2[] | null {
      return this.$store.getters[
        ntdPrefix(GetterTypes.HANDSETS_SORTED_BY_SERIAL_NUMBER)
      ];
    },
    availableHandsets(): HandsetV2[] {
      // handset are null when not yet fetched
      if (this.handsets === null) return [];

      // Filter available handsets based on url route.
      // Prevents changing handset mid test.
      if (this.$route.name === "ntd-loading-test") {
        const selectedHandset = this.handsets.find(
          (h) => h.udi === this.$route.params.udi
        );
        return selectedHandset ? [selectedHandset] : [];
      }

      return this.handsets;
    },
    validUdi(): boolean | undefined {
      // valid UDI if the UDI is missing or the handsets have not been fetched yet
      if (!this.$route.params.udi || this.handsets === null) return true;
      return this.handsets?.some((udi) => this.$route.params.udi === udi.udi);
    },
  },
  created() {
    // explicit setting of loading - init to true
    this.loading = true;
    this.$store.dispatch(
      ntdPrefix(ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS),
      false
    );

    this.$store
      .dispatch(ntdPrefix(ActionTypes.GET_HANDSETS))
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error))
      .finally(() => (this.loading = false));
  },
  destroyed() {
    this.notificationWatcher?.dispose();
  },
  beforeRouteUpdate(to, _, next) {
    if (this.handsets?.length !== 1) {
      next();
      return;
    }
    if (to.name === "ntd-results-history") {
      next({
        name: "ntd-handset-results",
        params: { udi: this.handsets[0].udi },
      });
    } else if (to.name === "ntd-handset") {
      next({ name: "ntd-start-test", params: { udi: this.handsets[0].udi } });
    }
    next();
  },
  methods: {
    closeFailureMessage() {
      this.$store.dispatch(
        ntdPrefix(ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS),
        false
      );

      if (this.$route.name === "ntd-start-test") return;
      this.$router.push({
        name: "ntd-start-test",
        params: { udi: this.$route.params.udi },
      });
    },
  },
  watch: {
    validUdi: {
      handler(newValidUdi: boolean | undefined) {
        if (newValidUdi === false) {
          this.$store.commit(MUTATION_ADD_ERROR, {
            message: "Error 404. Cannot find the requested handset.",
          });
          this.$router.replace({ name: "ntd" });
        }
      },
      immediate: true,
    },
    handsets: {
      handler(newHandsets: HandsetV2[] | null) {
        if (newHandsets?.length === 1 && this.handsetRouteName) {
          this.$router.replace({
            name: this.handsetRouteName,
            params: { udi: newHandsets[0].udi },
          });
        }
      },
      immediate: true,
    },
    handsetRouteName: {
      handler(newRouteName: string) {
        if (newRouteName !== "") return;
        this.$store.commit(MUTATION_ADD_ERROR, {
          message: "Unexpected navigation error.",
        });
        this.$router.push({ name: "ntd" });
      },
      immediate: true,
    },
    "$route.params.udi": {
      handler(newUdi: string | undefined) {
        // method defined on the listener
        // @ts-ignore
        this.resetListener();
        this.notificationWatcher?.dispose();

        // if no handset is selected do nothing
        if (!newUdi) return;

        // if the route does not contain the ntd-handset route do nothing
        // this is true for the ntd-handset-results
        if (!this.$route.matched.some((route) => route.name === "ntd-handset"))
          return;

        this.notificationWatcher = new UploadLifecycleWatcher(
          this.$route.params.udi,
          (this.$store as Store<any>).pusher!
        );
      },
      immediate: true,
    },
  },
});
