
import Vue from "vue";
import Component from "vue-class-component";
import { ACTION_LOGIN } from "@/store/constants";
import Checkbox from "@/components/Checkbox.vue";
import LogoText from "@/components/LogoText.vue";
import TextInput from "@/components/TextInput.vue";

/**
 * Panel embedded in [[ViewLoginWrapper]] for actually logging in. Contains a link to [[PanelForgottenPassword]] for
 * password resets.
 */
@Component({
  name: "panel-login",
  components: { TextInput, Checkbox, LogoText },
})
export default class PanelLogin extends Vue {
  // current credentials
  /**
   * Current value of the username field to be used when logging in
   * @category Vue Data
   */
  email: string = "";
  /**
   * Current value of the password field to be used when logging in
   * @category Vue Data
   */
  password: string = "";
  /**
   * Whether the access/refresh tokens should be stored to persist the user's session beyond page refreshes
   * @category Vue Data
   */
  rememberMe: boolean = true;
  /**
   * Whether the user is currently being logged in, don't let them login again in this case to prevent spamming.
   * @category Vue Data
   */
  loggingIn: boolean = false;

  /**
   * Whether the login button is disabled. Disable it if we're missing credentials or logging in.
   * @returns `true` if any data is missing or we've submitted a login request
   * @category Vue Computed
   */
  get disabled() {
    return this.email === "" || this.password === "" || this.loggingIn;
  }

  /**
   * Click handler for the login button. After checking the button isn't disabled, dispatches the [[LOGIN]] action
   * with the entered credentials. If this was successful, redirects the user to either the list view, or the path
   * specified in the `to` query parameter if there is one.
   */
  login() {
    if (this.disabled) return;
    // disable the login button
    this.loggingIn = true;
    // try to login with the specified credentials
    this.$store
      .dispatch(ACTION_LOGIN, {
        email: this.email,
        password: this.password,
        remember: this.rememberMe,
      })
      .then(() =>
        this.$router.replace({ name: "eulaConsent", query: this.$route.query })
      )
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error("Login Error:", e);
        this.password = "";
      })
      .finally(() => {
        // reactivate the login button whatever happens
        this.loggingIn = false;
      });
  }
}
