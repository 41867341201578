
import Vue from "vue";
import Component from "vue-class-component";
import TextInput from "@/components/TextInput.vue";
import CardDialogBackground from "@/components/CardDialogBackground.vue";
import DialogInformation from "../dashboard/DialogInformation.vue";
import LogoText from "@/components/LogoText.vue";
import { apiClient } from "@/store";

/**
 * Panel for registering a new user. This isn't actually used at the moment as we're not required to store user
 * identifiable information.
 */
@Component({
  name: "panel-register",
  components: { DialogInformation, CardDialogBackground, LogoText, TextInput },
})
export default class PanelRegister extends Vue {
  // new user information
  /**
   * Email address/username of the new user.
   * @category Vue Data
   */
  email: string = "";
  /**
   * Password of the new user. Must match [[passwordRepeat]] before proceeding.
   * @category Vue Data
   */
  password: string = "";
  /**
   * Copy of the password for the new user. Must match [[password]] before proceeding.
   * @category Vue Data
   */
  passwordRepeat: string = "";
  /**
   * **Only for study administrators.** Name of the group to create.
   * @category Vue Data
   */
  groupName: string = "";
  /**
   * **Only for study administrators.** Secure invitation code used when encrypting the encryption key for additional
   * information.
   * @category Vue Data
   */
  invitationCode: string = "";
  /**
   * **Only for study administrators.** List of allowed email domains. Editable as a [[TagsInput]].
   * @category Vue Data
   */
  allowedDomains: string[] = [];

  /**
   * Whether we're currently registering the new user, don't allow them to click the register button again
   * if we are to prevent spamming.
   * @category Vue Data
   */
  registering: boolean = false;
  /**
   * Whether registration was successful and the success dialog should be shown.
   */
  registerDialogVisible: boolean = false;
  /**
   * Raw error response from the API. Maps field names to a list of associated verification errors.
   * @category Vue Data
   */
  rawErrors: { [key: string]: string[] } = {};

  /**
   * Helper for extracting the study admin token from the URL. This will only be sent if we're signing up a new study
   * administrator. If we are, some additional input fields will be enabled: group name, invitation code and allowed
   * domains.
   * @returns Optional study administrator token extracted from the query string
   * @category Vue Computed
   */
  get studyAdminToken(): string | undefined {
    if (this.$route.query.token) {
      return this.$route.query.token.toString();
    } else {
      return undefined;
    }
  }

  /**
   * Whether to disable the registration button. Disabled if credentials are missing or the passwords don't match.
   * @returns `true` if any data is missing
   * @category Vue Computed
   */
  get disabled() {
    return (
      this.email === "" ||
      this.password === "" ||
      this.passwordRepeat === "" ||
      this.groupName === "" ||
      this.password !== this.passwordRepeat ||
      // TODO: check this works when registering normal users, not study admins
      this.invitationCode === "" ||
      this.allowedDomains.length === 0 ||
      this.registering
    );
  }

  /**
   * Joins each of the arrays in the raw API error response ([[rawErrors]]) to a single string.
   * @returns Object mapping field names to single error string containing all verification errors for that field
   * @category Vue Computed
   */
  get errors(): { [key: string]: string } {
    const errors: { [key: string]: string } = {};
    const additionalErrors = [];

    // convert all string[] errors to string's, recording errors that aren't attached to fields
    for (const errorKey in this.rawErrors) {
      errors[errorKey] = this.rawErrors[errorKey].join(" ");
      if (errorKey === "sck" || errorKey === "non_field_errors") {
        additionalErrors.push(errors[errorKey]);
      }
    }
    errors["additional"] = additionalErrors.join(" ");

    return errors;
  }

  /**
   * Click handler for the register button. After checking if the button is disabled, tries to register the new
   * user, adding study administrator options only if we've got a [[studyAdminToken]]. If there are any errors, they
   * will be stored in [[rawErrors]] and shown under the relevant fields. See [[APIClient.register]].
   */
  register() {
    if (!this.disabled) {
      // prevent the user from registering again by disabling the button
      this.registering = true;
      const registrationOptions: any = {
        email: this.email,
        password: this.password,
        invitationCode: this.invitationCode,
      };
      // only include the study admin options if a token has been provided
      if (this.studyAdminToken) {
        registrationOptions.groupname = this.groupName || undefined;
        registrationOptions.authToken = this.studyAdminToken;
        registrationOptions.allowedDomains = this.allowedDomains;
      }

      // try to register the user
      apiClient
        .register(registrationOptions)
        .then((res) => {
          // if this was successful, show the success dialog
          if (res.status === 200) {
            this.registerDialogVisible = true;
          } else {
            // record any errors
            this.rawErrors = res.data || {};
          }
        })
        .finally(() => {
          // always activate the registration button again
          this.registering = false;
        });
    }
  }

  /**
   * Click handler for next button on success dialog. Redirects the user to the login page to login with their new
   * account.
   */
  registerDialogNext() {
    // click handler for the success dialog's button
    this.registerDialogVisible = false;
    this.$router.replace({ name: "login" });
  }
}
