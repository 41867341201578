
import Vue from "vue";
import Card from "./Card.vue";
import IndicatorArrow from "./IndicatorArrow.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ENABLE_RAG_STATUS } from "@/config";
import { IndicatorColour } from "@/components/graphics/utils";

/**
 * Component for a card that contains information (current value, color, percentage, change) of a parameter. Will
 * animate between values when they change. Also includes a back side containing information on the parameter
 * being displayed.
 *
 * Clicking on the card will flip the visible side. The content of the back side is controller by 2 named slots:
 * `markers` and `details`. `markers` should be an list of [[StatisticCardMarker]] components. `details` should be
 * a plain text node describing the statistic.
 *
 * ![](media://statisticcard.png)
 */
@Component({
  name: "statistic-card",
  components: { Card, IndicatorArrow },
})
export default class StatisticCard extends Vue {
  /**
   * Colour of the card's progress bar and change arrow. Should be one of [[IndicatorColour]]s.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "success" }) readonly type!: IndicatorColour;
  /**
   * Percentage to display as the progress bar. Should be a number in the range $[0, 100]$ or `-1` if no progress
   * bar should be displayed. Even though this number should never go below 0, or above 100, it will still be
   * clamped to this range before being displayed. See [[clampedPercentProgress]].
   * @category Vue Prop
   */
  @Prop({ type: Number, default: -1 }) readonly percentProgress!: number;
  /**
   * Value to show in big text. This is a required prop, but can be set to `false` to hide the value.
   * @category Vue Prop
   */
  @Prop({ required: true }) readonly value!: number | false;
  /**
   * The previous value to display in brackets at the end of the card, or `false` if there is no previous value.
   * @category Vue Prop
   */
  @Prop({ default: false }) readonly previousValue!: number | false;
  /**
   * Optional unit of the value, displayed in small text after [[value]].
   * @category Vue Prop
   */
  @Prop({ default: "" }) readonly unit!: string;
  /**
   * Name of this statistic. Displayed underneath the current/previous values. This is a required prop.
   * @category Vue Prop
   */
  @Prop({ type: String, required: true }) readonly name!: string;
  /**
   * Ranges to display on the back side of the card (should be an array of `colour` and `width` percentages, with
   * `width`s summing to 100).
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  readonly ranges!: { colour: IndicatorColour; width: number }[];

  /** Whether the RAG status indicators are enabled. */
  enableRagStatus = ENABLE_RAG_STATUS;

  /**
   * Whether to show the back of the card instead of the front. Will be inverted when the user clicks the card.
   * @category Vue Data
   */
  flipped = false;

  /**
   * Whether clicking on this card should flip it. Only allow flipping if the card has a "details" slot to display.
   * @returns `true` if the card can be flipped
   * @category Vue Computed
   */
  get flippable(): boolean {
    return !!this.$slots.details;
  }

  /** Click handler for the card. If the card is [[flippable]], will swap which side of the card is visible. */
  flip() {
    if (this.flippable) this.flipped = !this.flipped;
  }

  /**
   * Makes sure the progress bar doesn't over/underflow by clamping [[percentProgress]] to the range $[0, 100]$.
   * @category Vue Computed
   * @returns Clamped copy of [[percentProgress]].
   */
  get clampedPercentProgress() {
    return this.percentProgress !== -1
      ? Math.min(100, Math.max(0, this.percentProgress))
      : false;
  }

  /**
   * Calculate the change between the [[previousValue]] and current [[value]], returning `false` if there was no
   * [[previousValue]].
   * @category Vue Computed
   * @returns Change between previous and current values
   */
  get change(): number | false {
    if (this.value !== false && this.previousValue !== false) {
      return this.value - this.previousValue;
    }
    return false;
  }
}
