
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, State } from "vuex-class";
import {
  ChartAnnotation,
  ChartDataScales,
  niceYScalesTicks,
  normY,
} from "@/components/graphics/utils";
import { availableTimescales, DeviceTrendSeries } from "@/store/fetcher/trends";
import {
  DeviceSummaryStatName,
  statPrecision,
  statUnits,
} from "@/store/fetcher/summaryStats";
import Card from "@/components/Card.vue";
import Chart from "@/components/graphics/Chart.vue";
import {
  oneDay,
  oneHour,
  oneMonth,
  oneWeek,
  oneYear,
  shortMonthNames,
} from "@/components/graphics/date";

/**
 * Panel displayed in [[PanelDetail]] for viewing in the currently selected parameter trend. Allows the user to
 * pan/zoom in on the chart. This panel also includes buttons for changing the timescales.
 *
 * ![](media://paneltrends.png)
 */
@Component({
  name: "panel-trends",
  components: { Chart, Card },
})
export default class PanelTrends extends Vue {
  // expose the constants/functions so they can be used in the template
  /**
   * Attached so it can be used in the template. See
   * [[src/store/fetcher/summaryStats.statUnits | statUnits]] and
   * [[StatisticCard.unit]].
   */
  statUnits = statUnits;
  /**
   * Attached so it can be used in the template. See
   * [[src/store/fetcher/summaryStats.statPrecision | statPrecision]].
   */
  statPrecision = statPrecision;
  /**
   * Attached so it can be used in the template. See
   * [[src/store/fetcher/trends.availableTimescales | availableTimescales]].
   */
  availableTimescales = availableTimescales;
  /** Expose [[src/components/graphics/utils.normY | normY]] to the template so
   * it can be used for formatting data points. */
  normY = normY;

  // vuex store bindings
  /**
   * See [[State.selectedTrendName | selectedTrendName]]
   * @category Vuex Binding
   */
  @State("selectedTrendName")
  readonly selectedTrendName!: DeviceSummaryStatName;
  /**
   * See [[src/store/internal/getters.selectedTrend | selectedTrend]] getter
   * @category Vuex Binding
   */
  @Getter("selectedTrend") readonly selectedTrend!: DeviceTrendSeries;
  /**
   * See [[State.deviceDUID]]
   * @category Vuex Binding
   */
  @State("deviceDUID") readonly deviceDUID!: string;

  // zoom and translate values, required for displaying the correct bottom ticks
  /**
   * Current X zoom for the [[Chart]], required for displaying the correct bottom ticks. See [[Chart.syncedXZoom]].
   * @category Vue Data
   */
  xZoom: number = 1;
  /**
   * Current X translate for the [[Chart]], required for displaying the correct bottom ticks. See
   * [[Chart.syncedXTranslate]].
   * @category Vue Data
   */
  xTranslate: number = 0;

  /**
   * Changes the zoom/translate of the chart to show this timescale (from the latest record)
   * @param timescale - Timescale in milliseconds to switch to
   */
  transformToTimescale(timescale: number) {
    if (this.$refs.chart) {
      // the parameter here is the new xZoom, but typescript doesn't know what vue component it is
      // @ts-ignore
      this.$refs.chart.panToEnd(this.selectedTrendRange / timescale);
    }
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Sets the default timescale to 1 week, once Vue has fully initialised this component.
   * @category Vue Lifecycle
   */
  mounted() {
    // once vue has updated all its DOM ($nextTick), set the default timescale (1 week)
    this.$nextTick(() => this.transformToTimescale(oneWeek));
  }

  /**
   * Current range of times displayed on chart if the zoom were 1 (i.e. no zoom)
   * @returns Current timescale in milliseconds
   * @category Vue Computed
   */
  get selectedTrendRange(): number {
    const [min, max] = this.selectedTrend.scales.x;
    return max - min;
  }

  /**
   * Gets a multiplier for the zoom based on the current timescale, ensures chart zooms evenly as timescale changes.
   * See [[Chart.xZoomMultiplier]].
   * @returns Multiplier for [[xZoom]]
   * @category Vue Computed
   */
  get selectedTrendZoomMultiplier(): number {
    return this.selectedTrendRange / (6 * oneMonth);
  }

  /**
   * Minimum value for [[xZoom]]. Ensures the maximum timescale is visible (not including `ALL`).
   * See [[Chart.minXZoom]].
   * @returns Minimum value for [[xZoom]]
   * @category Vue Computed
   */
  get selectedTrendMinZoom() {
    return Math.min(
      1,
      this.selectedTrendRange /
        availableTimescales[availableTimescales.length - 2].range
    );
  }

  /**
   * Maximum value for [[xZoom]]. Ensures user cannot zoom further than half a day.
   * See [[Chart.maxXZoom]].
   * @returns Maximum value for [[xZoom]]
   * @category Vue Computed
   */
  get selectedTrendMaxZoom() {
    return this.selectedTrendRange / (0.5 * oneDay);
  }

  /**
   * Get the timescale currently being shown on the graph (according to the zoom)
   * @returns Visible time range in milliseconds
   * @category Vue Computed
   */
  get selectedTrendTimescale() {
    return this.selectedTrendRange / this.xZoom;
  }

  /**
   * Gets the name (and active timescale button) for the currently visible timescale
   * @returns One of [[availableTimescales]] names or ALL if the visible range exceeds to maximum
   * @category Vue Computed
   */
  get selectedTrendTimescaleName() {
    if (this.xZoom === 1) return "ALL";
    const timescale = this.selectedTrendTimescale;
    for (const availableTimescale of availableTimescales) {
      if (timescale <= availableTimescale.range) {
        return availableTimescale.name;
      }
    }
    return "ALL";
  }

  /**
   * Sets the selected timescale and adjusts the zoom/translate accordingly.
   * @param timescale - Named timescale with millisecond range to show on trends graph
   */
  setSelectedTimescale(timescale: { name: string; range: number }) {
    if (timescale.name === "ALL") {
      this.xZoom = 1;
      this.xTranslate = 0;
    } else {
      this.transformToTimescale(timescale.range);
    }
  }

  /**
   * Get ticks labels for the x-axis and their positions, based on the current zoom and translate. Only ticks that
   * would be visible in the graph are shown.
   * @returns List of ticks and their series space positions to display at the bottom of the chart
   * @category Vue Computed
   */
  get selectedTrendXAxisTicks(): { x: number; label: string }[] {
    const chart: any = this.$refs.chart;

    // find the minimum/maximum time that's currently visible
    let minVisible = 0;
    let maxVisible = Infinity;
    if (chart) {
      minVisible = chart.inverseTransformAndDescaleX(0);
      maxVisible = chart.inverseTransformAndDescaleX(chart.sizes.width);
    }

    const min = Math.max(this.selectedTrend.scales.x[0], minVisible);
    const timescale = this.selectedTrendTimescale;

    // get the final date
    const lastPoint =
      this.selectedTrend.series.data[this.selectedTrend.series.data.length - 1];
    // if we don't have a last point, don't return any ticks
    if (!lastPoint) return [];
    const end = lastPoint.xDate;

    // get tick interval
    let date: (i: number) => Date;
    let positionOffset: number;
    let formatter: (t: Date) => string;
    if (timescale > 2 * oneYear) {
      date = (i) => new Date(end.getFullYear() + 1 - i, 0);
      positionOffset = 0.5 * oneYear;
      formatter = (t) => t.getFullYear().toString();
    } else if (timescale > 2 * oneWeek) {
      date = (i) => new Date(end.getFullYear(), end.getMonth() + 1 - i);
      positionOffset = 0.5 * oneMonth;
      formatter = (t) => shortMonthNames[t.getMonth()].toUpperCase();
    } else if (timescale > 2 * oneDay) {
      date = (i) =>
        new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1 - i);
      positionOffset = 0.5 * oneDay;
      formatter = (t) =>
        `${shortMonthNames[t.getMonth()].toUpperCase()} ${t.getDate()}`;
    } else {
      date = (i) =>
        new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate() + 1,
          24 - i * 8
        );
      positionOffset = 0.5 * oneHour;
      formatter = (t) =>
        `${shortMonthNames[
          t.getMonth()
        ].toUpperCase()} ${t.getDate()}, ${t.getHours()}:00`;
    }

    // build tick list
    const ticks: { x: number; label: string }[] = [];
    let i = 0;
    let time: number;
    do {
      const thisDate = date(i);
      time = thisDate.getTime();
      if (time <= maxVisible) {
        ticks.push({
          x: time + positionOffset,
          label: formatter(thisDate),
        });
      }
      i++;
    } while (time > min);

    return ticks;
  }

  /**
   * Y-axis scales and ticks. The ticks will be displayed with flex
   * [space-between](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content) so as long as they uniformly
   * cover the entire range, we don't need to calculate additional positioning information, just the labels.
   * @returns Y-axis scales and tick labels that should be evenly spaced on the left axis
   * @category Vue Computed
   */
  get selectedTrendYAxisScalesTicks(): { scales: number[]; ticks: string[] } {
    return niceYScalesTicks(
      this.selectedTrend.series.data,
      // Maximum ticks
      10,
      // Max maximum
      this.selectedTrend.scales.y[1]
    );
  }

  /**
   * Scales that should be passed to the chart. For the x-axis, uses the scales provided by the [[selectedTrend]].
   * For the y-axis, uses the nice scales provided by [[selectedTrendYAxisScalesTicks]].
   * @returns Scales array that can be passed to the [[Chart]] component
   * @category Vue Computed
   */
  get selectedTrendScales(): ChartDataScales[] {
    return [
      {
        x: this.selectedTrend.scales.x,
        y: this.selectedTrendYAxisScalesTicks.scales,
      },
    ];
  }

  /**
   * Build [[ChartAnnotation]]s for the chart from the lines we're required to show. See [[fetchTrends]] for how these
   * lines are determined.
   * @returns [[ChartAnnotation]]s for each of value markers
   * @category Vue Computed
   */
  get selectedTrendAnnotations(): ChartAnnotation[] {
    return this.selectedTrend.lines.map((line) => ({
      type: "y-line",
      y: line.value,
      colour: line.colour,
      dashed: line.dashed,
    }));
  }
}
