import { defineComponent } from "vue";
import {
  ntdPrefix,
  GetterTypes,
  EnrichedCapnogram,
} from "@/store/internal/modules/ntd";
import { HandsetV2 } from "@/store/types";
import { formatDate } from "@/components/graphics/date";

const formattedResultsMixin = defineComponent({
  computed: {
    // Here for type-safety. Should be implemented on the parent component.
    capnogramId(): string {
      return "";
    },
    capnogram(): EnrichedCapnogram | null {
      return this.$store.getters[ntdPrefix(GetterTypes.GET_CAPNOGRAM_BY_ID)](
        this.capnogramId
      );
    },
    testIdentifier(): string | null {
      return this.capnogram?.raw?.testIdentifier ?? null;
    },
    handsetSerial(): HandsetV2 | null {
      return (
        this.$store.getters[ntdPrefix(GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID)](
          this.capnogramId
        )?.serialNumberStr ?? null
      );
    },
    formattedDate(): string | null {
      const raw = this.capnogram?.raw;
      if (!raw) return null;

      return formatDate(raw.capturedAt);
    },

    formattedProbability(): string | null {
      const diagnosticResult = this.capnogram?.diagnosticResult;
      if (!diagnosticResult) return null;

      return `${(diagnosticResult.assessmentProbability * 100).toFixed(0)}%`;
    },
  },
});

export { formattedResultsMixin };
