import {
  APIUserInfoResponse,
  DUID,
  ErrorLevel,
  HandsetUDI,
  User,
} from "@/store/types";
import {
  defaultAdditionalInfo,
  DeviceAdditionalInfo,
} from "@/store/additionalInfo";
import {
  DeviceSummaryStatName,
  DeviceSummaryStats,
  statNames,
} from "@/store/fetcher/summaryStats";
import { ChartAverageWaveform } from "@/store/fetcher/waveform";
import { ChartCapnogramWithMetadata } from "@/store/fetcher/capnogram";
import { DeviceTrends } from "@/store/fetcher/trends";

/**
 * Data object representing an error to be displayed by the [[App]] component. See [[ADD_ERROR]] for the mutation that
 * adds these.
 */
export interface ErrorMessage {
  /** Auto-incrementing unique (within the current JavaScript context) identifier for this message */
  id: number;
  /** Message text to be displayed */
  message: string;
  /** Level of this error. Determines the icon shown in the dialog and whether it's recoverable. */
  level: ErrorLevel;
  /** Indicates if the user should be logged out if the error message is dismissed */
  logout: boolean;
}

/**
 * Type definition of the encryption keys for additional data in each of the user's groups. Maps group primary keys
 * to fernet keys. Will be `null` if the user isn't logged in, or they don't have any user keys (e.g. in CRIL group).
 */
export type UserKeys = { [key: number]: string } | null;

/** Visible state of the EULA dialog, with buttons */
export enum DialogEULAState {
  HIDDEN,
  VISIBLE_CLOSE,
  VISIBLE_ACCEPT_REJECT,
}

export interface State {
  /** Currently logged in user or `null` if a user isn't logged in */
  user: User | null;
  /** Encryption keys for additional data in each of the user's groups. See [[UserKeys]] for more information. */
  userKeys: UserKeys;
  /** List of currently visible error messages. Once dismissed, they will be removed from this list. */
  errors: ErrorMessage[];
  /**
   * ID of the next error message, incremented when a new error is added. Used to generate unique identifiers for new
   * error messages.
   */
  errorCount: number;
  /**
   * Information about the currently logged in user. This includes their [[APIUserInfoResponse.username | username]],
   * [[APIUserInfoResponse.groups | groups]] they belong to and [[APIUserInfoResponse.perms | permissions]] they have.
   * (`null` if a user isn't logged in)
   */
  userInfo: APIUserInfoResponse | null;
  /** List of all the UDIs the user has access to (`null` if a user isn't logged in) */
  userUDIs: HandsetUDI[] | null;
  /** List of all the DUIDs the user has access to (`null` if a user isn't logged in) */
  userDUIDs: DUID[] | null;
  /** Identifier of the latest capnogram of the currently selected device
   * (`null` if a DUID isn't selected) */
  deviceCapnogramId: string | null;
  /** DUID of the currently selected device (`null` if a DUID isn't selected) */
  deviceDUID: string | null;
  /** Decrypted additional info for the device (`null` if a DUID isn't selected) */
  deviceAdditionalInfo: DeviceAdditionalInfo;
  /** Stats of current device (stat name is key) (`null` if a DUID isn't selected) */
  deviceSummaryStats: DeviceSummaryStats | null;
  /** Latest waveform of current device (`null` if a DUID isn't selected) */
  deviceWaveform: ChartAverageWaveform | null;
  /** Latest capnogram of current device (`null` if a DUID isn't selected) in
   * form compatible with [[PanelCapnogram]]  */
  deviceCapnogram: ChartCapnogramWithMetadata | null;
  /** Formatted date of start time of capnogram (`null` if a DUID isn't selected) */
  deviceCapnogramStartTime: string | null;
  /** Trends of current device (stat name is key) (`null` if a DUID isn't selected) */
  deviceTrends: DeviceTrends | null;
  /** Currently selected trend tab (should also be stored in the route hash) */
  selectedTrendName: DeviceSummaryStatName;
  /** Whether the EULA dialog is currently being shown to the user */
  dialogEULA: DialogEULAState;
  /** Whether the product labelling dialog is currently open */
  dialogProductLabelling: boolean;
}

/**
 * Gets a copy of the applications initial state
 * @returns Initial state
 */
function state(): State {
  return {
    user: null,
    userKeys: null,
    errors: [],
    errorCount: 0,
    userInfo: null,
    userUDIs: null,
    userDUIDs: null,
    deviceCapnogramId: null,
    deviceDUID: null,
    deviceAdditionalInfo: defaultAdditionalInfo(),
    deviceSummaryStats: null,
    deviceWaveform: null,
    deviceCapnogram: null,
    deviceCapnogramStartTime: null,
    deviceTrends: null,
    selectedTrendName: statNames[0],
    dialogEULA: DialogEULAState.HIDDEN,
    dialogProductLabelling: false,
  };
}

export default state;
