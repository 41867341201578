// millisecond count for each time unit
/** Milliseconds in a second */
export const oneSecond = 1000;
/** Milliseconds in a minute */
export const oneMinute = oneSecond * 60;
/** Milliseconds in a hour */
export const oneHour = oneMinute * 60;
/** Milliseconds in a day */
export const oneDay = oneHour * 24;
/** Milliseconds in a week */
export const oneWeek = oneDay * 7;
/** Milliseconds in a year */
export const oneYear = oneDay * 365;
/** Approximation of milliseconds in a month *(one twelfth of a year)* */
export const oneMonth = Math.round(oneYear / 12); // approx

/** Map mapping the first character of time units to the number of milliseconds in that unit */
export const timescaleCodes: { [key: string]: number } = {
  /** See [[oneHour]] */
  H: oneHour,
  /** See [[oneDay]] */
  D: oneDay,
  /** See [[oneWeek]] */
  W: oneWeek,
  /** See [[oneMonth]] */
  M: oneMonth,
  /** See [[oneYear]] */
  Y: oneYear,
};

/** Array containing long month names, indexed by
 * [`Date.getMonth()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth).
 * For example, `monthNames[1] === "February"`.
 */
export const monthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * Array containing first 3 letters of each month name, indexed by
 * [`Date.getMonth()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth).
 * For example, `shortMonthNames[1] === "Feb"`.
 */
export const shortMonthNames = monthsNames.map((name) => name.substring(0, 3));

/**
 * Array containing first 3 letters of each day name, indexed by
 * [`Date.getDay()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay),
 * hence why "Sun" is first. 🇺🇸 For example, `shortDayNames[1] === "Mon"`.
 */
export const shortDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

/**
 * Converts a number to a string, prefixing a 0 if it's less than 10. For example, `padZeros(15) === "15"` whilst
 * `padZeros(5) === "05"`.
 * @param number - Number to convert to a string
 * @returns - The number as a string, potentially with a 0 added to the left.
 */
export function padZeros(number: number): string {
  return number < 10 ? "0" + number : number.toString();
}

/**
 * Formats and returns the passed date as human readable string, using either full month names or just the first 3
 * letters. This function will return values like "03 August 2018, 21:32", or "03 Aug 2018, 21:32" if `shortMonths` is
 * `true`.
 * @param date - Date to format
 * @param shortMonths - Whether to use just the first 3 letters of the month name
 * @returns Human readable datetime string
 */
export function formatDate(date: Date, shortMonths: boolean = false): string {
  return `${padZeros(date.getDate())} ${
    (shortMonths ? shortMonthNames : monthsNames)[date.getMonth()]
  } ${date.getFullYear()}, ${padZeros(date.getHours())}:${padZeros(
    date.getMinutes()
  )}`;
}
