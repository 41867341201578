// Local Storage key constants for storing tokens
/** ID of current user (only used for testing) */
export const LS_USER_ID = "cril-user-id";
/** Raw encoded access JWT */
export const LS_ACCESS_TOKEN = "cril-access-token";
/** Raw encoded refresh JWT (not set if not remembering me) */
export const LS_REFRESH_TOKEN = "cril-refresh-token";
/** User's encryption keys for additional info */
export const LS_USER_KEY = "cril-user-key";

// constants for store mutation/action names, helpful for avoiding typos when committing/dispatching to the vuex store

// mutations (synchronous)
/** See [[SET_USER]] */
export const MUTATION_SET_USER = "SET_USER";
/** See [[SET_USER_KEY]] */
export const MUTATION_SET_USER_KEY = "SET_USER_KEY";
/** See [[ADD_ERROR]] */
export const MUTATION_ADD_ERROR = "ADD_ERROR";
/** See [[DISMISS_ERROR]] */
export const MUTATION_DISMISS_ERROR = "DISMISS_ERROR";
/** See [[SET_USER_INFO]] */
export const MUTATION_SET_USER_INFO = "SET_USER_INFO";
/** See [[SET_USER_UDIS]] */
export const MUTATION_SET_USER_UDIS = "SET_USER_UDIS";
/** See [[SET_USER_DUIDS]] */
export const MUTATION_SET_USER_DUIDS = "SET_USER_DUIDS";
/** See [[SET_USER_DATA_DUID]] */
export const MUTATION_SET_USER_DATA_DUID = "SET_USER_DATA_DUID";
/** See [[SET_DEVICE_DATA]] */
export const MUTATION_SET_DEVICE_DATA = "SET_DEVICE_DATA";
/** See [[UPDATE_USER_DUID]] */
export const MUTATION_UPDATE_USER_DUID = "UPDATE_USER_DUID";
/** See [[SET_DEVICE_ADDITIONAL_INFO]] */
export const MUTATION_SET_DEVICE_ADDITIONAL_INFO = "SET_DEVICE_ADDITIONAL_INFO";
/** See [[SET_SELECTED_TREND]] */
export const MUTATION_SET_SELECTED_TREND = "SET_SELECTED_TREND";
/** See [[SET_LIST_VIEW_FILTER]] */
export const MUTATION_SET_LIST_VIEW_FILTER = "listView/SET_FILTER";
/** See [[SET_LIST_VIEW_SORT]] */
export const MUTATION_SET_LIST_VIEW_SORT = "listView/SET_SORT";
/** See [[LIST_RESET_STATE]] */
export const MUTATION_LIST_RESET_STATE = "listView/RESET_STATE";
/** See [[SET_LIST_VIEW_SELECTED_STUDY]] */
export const MUTATION_SET_LIST_VIEW_SELECTED_STUDY =
  "listView/SET_SELECTED_STUDY";
/** See [[SHOW_EULA_DIALOG]] */
export const MUTATION_SHOW_EULA_DIALOG = "SHOW_EULA_DIALOG";
/** See [[SET_PRODUCT_LABELING_DIALOG]] */
export const MUTATION_SET_PRODUCT_LABELING_DIALOG =
  "SET_PRODUCT_LABELING_DIALOG";
/** See [[RESET_STATE]] */
export const MUTATION_RESET_STATE = "RESET_STATE";

// actions (asynchronous, long running)
/** See [[FETCH_USER_DATA]] */
export const ACTION_FETCH_USER_DATA = "FETCH_USER_DATA";
/** See [[FETCH_DEVICE_DATA]] */
export const ACTION_FETCH_DEVICE_DATA = "FETCH_DEVICE_DATA";
/** See [[UPDATE_DEVICE_DATA]] */
export const ACTION_UPDATE_DEVICE_DATA = "UPDATE_DEVICE_DATA";
/** See [[ALLOCATE_DEVICE]] */
export const ACTION_ALLOCATE_DEVICE = "ALLOCATE_DEVICE";
/** See [[DEALLOCATE_DEVICE]] */
export const ACTION_DEALLOCATE_DEVICE = "DEALLOCATE_DEVICE";
/** See [[LOGIN]] */
export const ACTION_LOGIN = "LOGIN";
/** See [[LOGOUT]] */
export const ACTION_LOGOUT = "LOGOUT";
/** See [[FETCH_DUIDS]] */
export const ACTION_FETCH_DUIDS = "FETCH_DUIDS";
/** See [[FETCH_UDIS]] */
export const ACTION_FETCH_UDIS = "FETCH_UDIS";
/** See [[CLEAR]] */
export const ACTION_RESET_STATE = "RESET_STATE";
