import { Route } from "vue-router";

// noinspection JSCommentMatchesSignature
/**
 * After logging in, select where the user should go. If the user has a page in their query,
 * send them to that page, else send them to the product page.
 * @param route - The route selected.
 * @returns Struct for the new route
 * @category login
 */
function eulaRedirect(route: Route) {
  // go to the desired path if specified otherwise default to the dashboard
  if (route.query.to) {
    return { path: route.query.to.toString() };
  } else {
    // Select NTD vs NTC
    return { name: "product" };
  }
}

export { eulaRedirect };
