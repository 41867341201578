import Vue from "vue";
import Vuex, { ActionTree, Store as VStore, StoreOptions } from "vuex";
import APIClient from "@/store/api";
import state, { State } from "@/store/internal/state";
import * as getters from "@/store/internal/getters";
import * as mutations from "@/store/internal/mutations";
import * as actions from "@/store/internal/actions";
import modules from "@/store/internal/modules";
import Pusher from "pusher-js/with-encryption";
import { DeviceDataFetcher } from "@/store/fetcher";
import { ENABLE_PUSHER_LOG_TO_CONSOLE, PUSHER_KEY } from "@/config";
import {
  ChannelAuthorizationCallback,
  ChannelAuthorizationRequestParams,
} from "pusher-js/types/src/core/auth/options";

Vue.use(Vuex);
Pusher.logToConsole = ENABLE_PUSHER_LOG_TO_CONSOLE;

class Store<State> extends VStore<State> {
  // Custom API client is used within actions.
  apiClient: APIClient;
  pusher?: Pusher;
  deviceDataFetcher?: DeviceDataFetcher;

  constructor(opt: StoreOptions<State>) {
    super(opt);

    this.apiClient = new APIClient(this);
  }

  initPusher() {
    // eslint-disable-next-line no-console
    console.log("Setting Pusher instance...");
    this.pusher = new Pusher(PUSHER_KEY, {
      cluster: "eu",
      channelAuthorization: {
        // Both endpoint and transport are not used when customHandler is specified
        // but are required for typing.
        // See https://pusher.com/docs/channels/using_channels/connection/#channelauthorizationcustomhandler-567625879
        endpoint: "NOT_USED",
        transport: "ajax",
        customHandler: (params, callback) =>
          this.customPusherAuthorizerHandler(params, callback, this.apiClient),
      },
    });
  }

  /**
   * Custom authZ handler which communicates using [[APIClient]] so we always have
   * valid tokens in requests.
   */
  async customPusherAuthorizerHandler(
    params: ChannelAuthorizationRequestParams,
    callback: ChannelAuthorizationCallback,
    apiClient: APIClient
  ) {
    const { channelName, socketId } = params;

    // send API authenticate request
    const res = await apiClient.authenticatePusherChannel({
      socketId,
      channelName: channelName,
    });

    // callback with appropriate response
    if (res.status === 200) {
      callback(null, res.data!);
    } else {
      callback(Error(`Unable to authenticate ${channelName}!`), null);
    }
  }
}

/**
 * [Vuex](https://vuex.vuejs.org/) store instance. Contains most application
 * state and logic for fetching/updating data. See files in the
 * [[src/store/internal/state]], [[src/store/internal/mutations]],
 * [[src/store/internal/actions]], [[src/store/internal/getters]].
 *
 * ![](https://vuex.vuejs.org/vuex.png)
 */
const store = new Store<State>({
  state,
  getters,
  mutations,
  // prevents ts complaining about ActionHandler using different types of stores
  actions: actions as unknown as ActionTree<State, State>,
  modules,
});

/**
 * [[APIClient]] instance for use around the application with strongly-typed functions for possible HTTP requests.
 */
const apiClient = store.apiClient;

export default store;
export { Store, apiClient };
