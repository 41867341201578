
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Gets the `[x,y]` coordinates around a unit circle centered on the origin to represent a fraction 0 to 1.
 *
 * #### Examples
 *
 * *(`f` inputs are in red, output coordinates are in blue)*
 *
 * ![](media://percentcirclecoords.png)
 *
 * @returns Coordinates around a unit circle centered on the origin
 */
function coordinatesForFraction(f: number): number[] {
  return [Math.cos(2 * Math.PI * f), Math.sin(2 * Math.PI * f)]; /* [x,y] */
}

(window as any).coordinatesForFraction = coordinatesForFraction;

/**
 * Component for displaying a circular progress bar with a percentage inside it. Doesn't animate any changes.
 * [This article](https://medium.com/hackernoon/a-simple-pie-chart-in-svg-dbdd653b6936) was very helpful when building
 * this component:
 *
 * ![](media://percentcircle.png)
 */
@Component({
  name: "percent-circle",
})
export default class PercentCircle extends Vue {
  /**
   * Diameter of the circle.
   * @category Vue Prop
   */
  @Prop({ type: Number, default: 40 }) readonly size!: number;
  /**
   * Percentage of the circle to fill in, and what to display in the center.
   * @category Vue Prop
   */
  @Prop({ type: Number, required: true }) readonly percent!: number;

  /**
   * SVG path [d attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d) describing the arc
   * around the circle that fills in a [[percent]] of it.
   * @returns SVG path d attribute of the arc
   * @category Vue Computed
   */
  get arcPath(): string {
    const f = (this.percent as number) / 100; // fraction

    const [startX, startY] = coordinatesForFraction(0);
    const [endX, endY] = coordinatesForFraction(f);
    const largeArcFlag = f > 0.5 ? 1 : 0;

    return [
      `M ${startX} ${startY}`,
      `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
      `L 0 0`,
    ].join(" ");
  }
}
