var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dropdown",class:{ 'is-disabled': _vm.disabled },on:{"click":_vm.toggleOpen}},[_c('span',[_vm._v(_vm._s(_vm.value ? (_vm.value.name ? _vm.value.name : _vm.value) : "Select..."))]),_c('transition',{attrs:{"name":"fly-down"}},[(_vm.optionsShown)?_c('div',{staticClass:"dropdown-menu"},[_c('p',{staticClass:"search-input",class:{ 'has-results': _vm.filteredOptions.length > 0 },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],ref:"filter",attrs:{"type":"text","placeholder":"Type to search"},domProps:{"value":(_vm.filter)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"keydown":_vm.onKeyDown,"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"options"},_vm._l((_vm.filteredOptions),function(option){return _c('a',{key:option.value ? option.value : option,class:{
            selected:
              (option.value ? option.value : option) ===
              (_vm.value && _vm.value.value ? _vm.value.value : _vm.value),
          },attrs:{"title":option.tooltip
              ? option.tooltip
              : option.value
              ? option.value
              : option},on:{"click":function($event){$event.stopPropagation();return _vm.select(option)}}},[_vm._v(" "+_vm._s(option.name ? option.name : option)+" ")])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }