/**
 * Annotation to be rendered on a [[Chart]]. Only some of the properties are required for each [[type]]. See the table
 * below. All coordinates should be given in series space, which will be transformed by [[Chart]] to offset space.
 *
 * #### Types
 *
 * |Image|Name|Description|Required Properties|Optional Properties|
 * |-|-|-|-|-|
 * |![](media://annotationxline.png)|`x-line`|Line $x =$ `x`|`x`|`colour`, `dashed`|
 * |![](media://annotationyline.png)|`y-line`|Line $y =$ `y`, optionally with text above|`y`|`colour`, `dashed`, `topText`|
 * |![](media://annotationline.png)|`line`|Line segment from (`x`, `y`) to (`x2`, `y2`)|`x`, `y`, `x2`, `y2`||
 * |![](media://annotationtriangle.png)|`triangle`|Blue triangle centered on (`x`, `y`)|`x`, `y`||
 * |![](media://annotationsquare.png)|`square`|Pink square centered on (`x`, `y`)|`x`, `y`||
 * |![](media://annotationrange.png)|`range`|Rounded line at bottom from `x` to `x2`|`x`, `x2`||
 * |![](media://annotationbackgroundrect.png)|`background-rect`|Shaded rectangle behind chart from `x` to `x2`|`x`, `x2`||
 */
export interface ChartAnnotation {
  /** See [ChartAnnotation](#types) for examples of each type and required properties. */
  type:
    | "x-line"
    | "y-line"
    | "line"
    | "triangle"
    | "square"
    | "range"
    | "background-rect";
  /** Series space X-coordinate. Used by: `x-line`, `line`, `triangle`, `square`, `range`, `background-rect`. */
  x?: number;
  /** Series space Y-coordinate. Used by: `y-line`, `line`, `triangle`, `square`. */
  y?: number;
  /** To series space X-coordinate. Used by: `line`, `range`, `background-rect`. */
  x2?: number;
  /** To series space Y-coordinate. Used by: `line`. */
  y2?: number;
  /** Text to display above the line. Used by: `y-line`. */
  topText?: string;
  /** Colour to use when drawing the line. Used by: `x-line`, `y-line`. */
  colour?: string;
  /** Whether the line should be dashed, not solid. Used by: `x-line`, `y-line`. */
  dashed?: boolean;
}

/**
 * Available colours for indicators. Used by [[NormalisedPlot]], [[StatisticCard]], etc.
 * The CSS colours these colours represent are defined as `$colours` in `src/styles/_variables.sass`. `"dark"` isn't
 * actually defined in CSS, so it should be the default colour, with other colour classes overriding this.
 */
export type IndicatorColour =
  | "blue"
  | "dark-blue"
  | "green"
  | "yellow"
  | "red"
  | "primary"
  | "dark";

/**
 * Represents a set of scales for a chart series. Each property should be a length 2 array of the form `[min, max]` for
 * that axis, with coordinates given in series space.
 *
 * ![](media://series.png)
 */
export interface ChartDataScales {
  /** Length 2 array of the form `[xMin, xMax]` */
  x: number[];
  /** Length 2 array of the form `[yMin, yMax]` */
  y: number[];
}

/** Represents a point in the series. It may be annotated or coloured. */
export interface ChartDataPoint {
  /** Series space X-coordinate of the point */
  x: number;
  /**
   * Series space Y-coordinate of the point. This can either be a single coordinate or an array of the form
   * `[min, value, max]`, where `min` and `max` will be used when plotting the variation.
   * */
  y: number | number[];
  /** Annotation to display at the same place as this point. Coordinates from the [[ChartAnnotation]] can be omitted. */
  annotation?: ChartAnnotation;
  /** Colour of this point. Should be one of the keys in [[bandColours]]. */
  colour?: string;
}

/**
 * Represents just the data for a chart series. This is just an array of points that should be sorted by X-coordinate.
 * Used by helper functions such as [[autoScale]] that don't need to know colour information.
 */
export type ChartDataSeriesData = ChartDataPoint[];
/**
 * Represents a chart series, optionally with a custom colour that should be one of the keys in [[bandColours]].
 * This is generic to allow a custom [[ChartDataPoint]] to be used to store additional information with points.
 * @typeParam T - Custom [[ChartDataPoint]] used to store additional information with points. Useful for storing stuff
 *                to display in the chart overlay.
 */
export type ChartDataSeries<T extends ChartDataPoint> = {
  /** CSS colour to use for the line stroke. */
  lineColour?: string;
  /** CSS colour to use for the variation fill. */
  areaColour?: string;
  /** Data for the series that should be sorted by X-coordinate. See [[ChartDataPoint]]. */
  data: T[];
};

/* eslint-disable tsdoc/syntax */
/**
 * Rounds a number up to the nearest multiple of a power of ten.
 *
 * #### Examples
 *
 * |Input|Output|Explanation|
 * |-|-|-|
 * |0.5|1|$1 \times 10^0$|
 * |5|5|$5 \times 10^0$|
 * |10|10|$1 \times 10^1$|
 * |15|20|$2 \times 10^1$|
 * |101|200|$2 \times 10^2$|
 * |500|500|$5 \times 10^2$|
 *
 * @param v - Number to round up
 * @returns The number rounded up to the nearest multiple of a power of ten
 */
/* eslint-enable tsdoc/syntax */
export function roundUpToPowerOfTen(v: number) {
  if (v < 1) return 1;
  const powerOfTen = Math.pow(10, Math.floor(Math.log10(v)));
  return Math.ceil(v / powerOfTen) * powerOfTen;
}

/**
 * Creates a set of scales that will show all of the series in the chart viewport. No rounding will be performed on
 * the minimum/maximum values.
 *
 * @param series - Series to generate scales for
 * @returns Scales that show all the data in the viewport
 */
export function perfectScales(series: ChartDataSeriesData): ChartDataScales {
  const xs = series.map((value: ChartDataPoint) => value.x);
  const minYs = series.map((value) =>
    Array.isArray(value.y) ? value.y[0] : value.y
  );
  const maxYs = series.map((value) =>
    Array.isArray(value.y) ? value.y[2] : value.y
  );
  return {
    x: [Math.min(...xs), Math.max(...xs)],
    y: [Math.min(...minYs), Math.max(...maxYs)],
  };
}

/**
 * Creates a set of scales for **positive y-values** that will show all of the series in the chart viewport. The
 * maximum y-value will be rounded up using [[roundUpToPowerOfTen]].
 *
 * @param series - Positive series to generate scales for
 * @returns Scales that show all the data in the viewport
 */
export function autoScale(series: ChartDataSeriesData): ChartDataScales {
  const scales = perfectScales(series);

  // Adjust y scales
  scales.y[0] = 0; // input series assumed positive for all x's
  scales.y[1] = roundUpToPowerOfTen(Math.ceil(scales.y[1]));

  return scales;
}

/* eslint-disable tsdoc/syntax */
/**
 * Produces up to 11 evenly spaced tick labels from 0 to `max` inclusive. Produces ticks according to the following
 * rules:
 *
 * If `max` $\le 2$: produce ticks with an interval of `max` / 10, showing 2 decimal places. Example: if `max` is
 * `2`, use `0.2` as the interval, returning
 * `["0.00", "0.20", "0.40", "0.60", "0.80", "1.00", "1.20", "1.40", "1.60", "1.80", "2.00"]` *(11 ticks)*.
 *
 * If $2 \lt$ `max` $\le 10$: produce ticks with an interval of `1`. Example: if `max` is `5`, use `1` as the
 * interval, returning `["0", "1", "2", "3", "4", "5"]` *(6 ticks)*.
 *
 * If $10 \lt$ `max`: produce ticks with an interval of `max` / 10. Example: if `max` is `20`, use `2` as the
 * interval, returning `["0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20"]` *(11 ticks)*.
 *
 * The output of this function is intended to used with CSS flexbox's `space-between` for positioning.
 *
 * @param max - Maximum value to create ticks up to *(inclusive)*
 * @returns Array of up to 11 evenly spaced string tick labels
 */
/* eslint-enable tsdoc/syntax */
export function autoTick(max: number): string[] {
  let interval: number;
  let decimal = false;
  if (max <= 2) {
    interval = max / 10;
    decimal = true;
  } else if (max <= 10) {
    interval = 1;
  } else {
    interval = max / 10;
  }
  const ticks: string[] = [];
  for (let i = 0; i <= 10; i++) {
    const value = interval * i;
    if (value > max) break;
    // include 2 decimal places if this is a decimal value
    ticks.push(decimal ? value.toFixed(2) : value.toString());
  }
  return ticks;
}

/**
 * Produces nice numbers based off the code here: https://stackoverflow.com/a/16363437
 * @returns Nice number for the range
 */
export function niceNumber(range: number, round: boolean): number {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);

  let niceFraction;
  if (round) {
    if (fraction < 1.5) {
      niceFraction = 1;
    } else if (fraction < 3) {
      niceFraction = 2;
    } else if (fraction < 7) {
      niceFraction = 5;
    } else {
      niceFraction = 10;
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (fraction <= 1) {
      niceFraction = 1;
    } else if (fraction <= 2) {
      niceFraction = 2;
    } else if (fraction <= 5) {
      niceFraction = 5;
    } else {
      niceFraction = 10;
    }
  }

  return niceFraction * Math.pow(10, exponent);
}

/**
 * Generates a set of scales and ticks for the y-axis using [[niceNumber]]s.
 * @param series - Series to generate scales for
 * @param maxTicks - Maximum number of ticks to generate
 * @param maxMaximum - Maximum maximum value for the scales
 * @returns Scales that show all the data in the viewport, but use nice numbers
 */
export function niceYScalesTicks(
  series: ChartDataSeriesData,
  maxTicks: number = 10,
  maxMaximum?: number
): { scales: number[]; ticks: string[] } {
  // Get minimum and maximum values for the series axis
  let {
    y: [min, max],
  } = perfectScales(series);

  // Clamp maximum and minimum values to [0, maxMaximum]
  if (min < 0) min = 0;
  if (maxMaximum !== undefined && max > maxMaximum) max = maxMaximum;

  // Get nice min, max and spacing
  const range = niceNumber(max - min, false);
  const tickSpacing = niceNumber(range / (maxTicks - 1), true);
  const niceMin = Math.floor(min / tickSpacing) * tickSpacing;
  const niceMax = Math.ceil(max / tickSpacing) * tickSpacing;

  // Build tick list
  const ticks: string[] = [];
  for (let v = niceMin; v <= niceMax; v += tickSpacing) {
    // Convert value to human friendly label
    let label: string | undefined;
    if (tickSpacing < 1) {
      label = v.toFixed(2);
    } else {
      label = v.toString();
    }
    // Add tick with label to list
    ticks.push(label);
  }

  return { scales: [niceMin, niceMax], ticks };
}

/**
 * Calculates where `value` would be positioned within `size` units given the `scale` `[min, max]`.
 *
 * #### Example
 *
 * ![](media://scalevalue.png)
 *
 * The dark blue and light blue rectangles represent series and offset space respectively. The green and red arrows
 * represent `min` and `max` respectively. The light blue arrow represents `size`. The pink arrow represents the input
 * `value` and the purple arrow represents the output.
 *
 * @param value - Number in series space to position
 * @param scale - Scale of the form `[min, max]` to position `value` within
 * @param size - Size of `max` - `min` in offset space
 * @returns Position of `value` in offset space
 */
export function scaleValue(
  value: number,
  scale: number[],
  size: number
): number {
  return ((value - scale[0]) / (scale[1] - scale[0])) * size;
}

/**
 * Calculates which value would the position `value` within `size` units given the `scale`. This is the inverse of
 * [[scaleValue]].
 *
 * @param value - Number in offset space to get value of
 * @param scale - Scale of the form `[min, max]` `value` is positioned within
 * @param size - Size of `max` - `min` in offset space
 * @returns Value of `value` in series space
 */
export function descaleValue(
  value: number,
  scale: number[],
  size: number
): number {
  return (value / size) * (scale[1] - scale[0]) + scale[0];
}

/**
 * Gets the actual value of a Y-coordinate. Normalises the different forms of Y-coordinate: a number or an
 * an array of the form `[min, value, max]`. Either returns the number, or `value`.
 * @param y - Y-coordinate to normalise
 * @returns Actual Y-value at the coordinate
 */
export function normY(y: number | number[]): number {
  return Array.isArray(y) ? y[1] : y;
}

// TODO: this function is disgusting and confusing, remove it
/**
 * Builds a chart with no data. This is generic so optional values can also be added. Whilst this breaks type safety,
 * (meaning `@ts-ignore` needs to be used), it makes writing functions that need this much easier.
 * @param arrays - Whether to wrap the return series and scales with `[]`.
 * @returns Empty chart with no data, typed with `T`.
 * @typeParam T - Return type
 */
export function emptyChart<T>(arrays: boolean): T {
  const series = { data: [] };
  const scales = { x: [0, 1], y: [0, 1] };
  // @ts-ignore
  return {
    series: arrays ? [series] : series,
    scales: arrays ? [scales] : scales,
  };
}
