
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { DropdownOption } from "@/store/types";

/**
 * Component for a dropdown button with a list of options to select from. Also supports filtering options by name.
 * See also [[DropdownOption]] for the format of options.
 *
 * ![](media://dropdown.png)
 */
@Component({
  name: "dropdown",
})
export default class Dropdown extends Vue {
  /**
   * Currently selected option. If this isn't selected, will default to showing "Select...".
   * @category Vue Prop
   */
  @Prop({ default: "" }) readonly value!: DropdownOption;
  /**
   * Whether to prevent the dropdown from being opened. Will still respond to changes in `value`, just not inputs.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  /**
   * Array of options the dropdown will present to the user when clicked.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  readonly options!: DropdownOption[];
  /**
   * Ignore whitespace and zeroes when filtering
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false })
  readonly filterIgnoresWhitespaceZeros!: boolean;

  /**
   * Whether the user has requested to see the options. Updated when the user clicks the dropdown, elsewhere on
   * the screen, or an option in the menu.
   * @category Vue Data
   */
  showOptions: boolean = false;
  /**
   * Current text filter for dropdown options. Only options matching containing an upper-case version of this string
   * will be displayed. If this is empty, all options are displayed.
   * @category Vue Data
   */
  filter: string = "";

  /**
   * Whether the dropdown menu is visible. Only show options if the user has requested it (see [[showOptions]]), there
   * are options to show and the dropdown is not disabled.
   * @returns `true` if and only if the menu should be shown
   * @category Vue Computed
   */
  get optionsShown() {
    return (
      this.showOptions && this.options && this.options.length && !this.disabled
    );
  }

  /**
   * Gets the options that match the currently entered [[filter]] or all of them if the filter is empty. Will
   * transform the filter to upper case before matching against items. *(designed for DUIDs, could be a prop in the
   * future, or all options could be converted to uppercase too before matching)*
   * @returns Options matching the [[filter]].
   * @category Vue Computed
   */
  get filteredOptions(): DropdownOption[] {
    if (this.filter === "") {
      return this.options;
    } else {
      const normaliseString = (s: string): string =>
        (this.filterIgnoresWhitespaceZeros
          ? s.replace(/[ 0]/g, "")
          : s
        ).toUpperCase();

      const normalisedFilter = normaliseString(this.filter);
      return this.options.filter((option) => {
        // @ts-ignore
        if (option.value && option.name) {
          return (
            // @ts-ignore
            normaliseString(option.name).includes(normalisedFilter)
          );
        } else {
          // @ts-ignore
          return normaliseString(option).includes(normalisedFilter);
        }
      });
    }
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Registers the [[windowClickHandler]] to listen for clicks outside of this dropdown.
   * @category Vue Lifecycle
   */
  created() {
    window.addEventListener("click", this.windowClickHandler);
  }
  // noinspection JSUnusedGlobalSymbols
  /**
   * Unregisters the [[windowClickHandler]] to stop listening for clicks outside of this dropdown.
   * @category Vue Lifecycle
   */
  beforeDestroy() {
    window.removeEventListener("click", this.windowClickHandler);
  }

  /**
   * Toggles the user's requested [[showOptions | open state]] of this dropdown, clearing the [[filter]] so that when
   * they open it again, all items are shown. This will only change the state if the dropdown is not [[disabled]].
   */
  toggleOpen() {
    if (!this.disabled) {
      if (!this.showOptions) {
        this.filter = "";
      }
      this.showOptions = !this.showOptions;
    }
  }

  /**
   * Automatically focuses the dropdown's search input when it's clicked to allow the user to start typing immediately
   * when they open it.
   * @param nowOpen - Whether the dropdown has gone from being closed to open, or the other way round
   * @category Vue Watch
   */
  @Watch("showOptions")
  onOpenChanged(nowOpen: boolean) {
    if (nowOpen) {
      this.$nextTick(() => {
        if (this.$refs.filter) {
          // @ts-ignore
          this.$refs.filter.focus();
        }
      });
    }
  }

  /**
   * Handler for keyboard events on the filter input. If the enter key is pressed, the first of [[filteredOptions]]
   * will be selected.
   * @param e - Event object containing the key that was pressed
   */
  onKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter" && this.filteredOptions.length > 0) {
      this.select(this.filteredOptions[0]);
    }
  }

  /**
   * Handler for clicking a dropdown item. Hides the dropdown menu and emits an `input` event with the selected option.
   * @param option - Option that was selected by the user
   */
  select(option: DropdownOption) {
    if (!this.disabled) {
      this.showOptions = false;
      this.$emit("input", option);
    }
  }

  /**
   * Handler for click events anywhere on the page. If something other than this dropdown is clicked, this dropdown's
   * menu is hidden.
   * @param e - Event object containing the element that was clicked
   */
  windowClickHandler(e: MouseEvent) {
    // hide the options if something other than THIS dropdown is clicked
    // @ts-ignore
    if (e.target !== this.$el && e.target.parentNode !== this.$el) {
      this.showOptions = false;
    }
  }
}
