
import Vue from "vue";
import Component from "vue-class-component";
import {
  NTC_PRODUCT_LABEL_FILE,
  DASHBOARD_ASSET_BUCKET_PREFIX,
} from "@/config";
import CardDialog from "@/components/CardDialog.vue";
import { MUTATION_SET_PRODUCT_LABELING_DIALOG } from "@/store/constants";

@Component({
  name: "dialog-product-labelling",
  components: { CardDialog },
})
export default class DialogProductLabelling extends Vue {
  productLabelSrc = `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTC_PRODUCT_LABEL_FILE}`;

  /** Close the dialog */
  close() {
    this.$store.commit(MUTATION_SET_PRODUCT_LABELING_DIALOG, false);
  }
}
