
import Vue from "vue";
import IndicatorArrow from "@/components/IndicatorArrow.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/** Represents the current sorting key and direction for a table. */
export interface Sort {
  /** Object key name to sort on */
  on: string;
  /** Whether to sort in ascending/descending order */
  ascending: boolean;
}

/**
 * Component for a table header that allows you to select it as the key to sort on. When clicking on it, will cycle
 * through the following states in order:
 *
 * 1. Sorting in descending order
 * 2. Sorting in ascending order
 * 3. Not sorting using this key
 *
 * Will show an arrow next to the header text (passed via the default slot) indicating the sorting direction.
 *
 * ![](media://sortheader.png)
 */
@Component({
  name: "sort-header",
  components: {
    IndicatorArrow,
  },
})
export default class SortHeader extends Vue {
  /**
   * [[Sort]] object currently being used to sort table data. If this matches the current [[sortOn]] an arrow will
   * be shown. This is intended to be used with `v-model`, with `input` events being emitted by this component
   * when the selected sort changes.
   * @category Vue Prop
   */
  @Prop({ type: Object, required: true }) readonly value!: Sort;
  /**
   * What key to sort on if this header is selected. If this matches [[value]], an arrow will be shown.
   * @category Vue Prop
   */
  @Prop({ type: String, required: true }) readonly sortOn!: string;
  /**
   * Whether the arrow should be hidden (used when the column this component is for is really thin and wouldn't have
   * space for an arrow)
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly hideArrow!: boolean;

  /**
   * [[IndicatorArrow.change | change]] value passed to
   * [[IndicatorArrow]] to show the correct arrow for the current sorting
   * direction.
   *
   * - If we're sorting on this key in the ascending direction: show an up arrow.
   * - If we're sorting on this key in the descending direction: show a down arrow.
   * - If we're not sorting on this key: show no arrow
   *
   * Because we're using [[IndicatorArrow]], changes here will be animated.
   *
   * @returns Change to be passed to [[IndicatorArrow]]
   * @category Vue Computed
   */
  get arrowChange() {
    return this.value.on === this.sortOn ? (this.value.ascending ? 1 : -1) : 0;
  }

  /**
   * Handler for when this header is clicked. Will cycle through the sorting options based on the current state in
   * this order, emitting an `input` event with the new sort:
   *
   * 1. Sorting in descending order
   * 2. Sorting in ascending order
   * 3. Not sorting using this key
   */
  onClick() {
    let res: Sort;
    if (this.value.on === this.sortOn) {
      // if we're already sorting on this key...
      if (this.value.ascending) {
        // ...and we're sorting by ascending order, sort by descending order
        res = { on: this.sortOn, ascending: false };
      } else {
        // otherwise, don't sort by this key any more
        res = { on: "", ascending: true };
      }
    } else {
      // otherwise, sort by this key in descending order
      res = { on: this.sortOn, ascending: true };
    }

    // emit the new sort object so it can be stored in the parent component
    // ("input" is the required event name for v-model to work)
    this.$emit("input", res);
  }
}
