
import Vue from "vue";
import Animation from "./Animation.vue";
import loading from "@/assets/animations/loading.json";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component that displays a loader using the Animation component. Optionally allows some text to be
 * displayed beneath the animation about what is being loaded. When this is changed, the old text will
 * fade out before the new text fades in.
 *
 * ![](media://loader.png)
 */
@Component({
  name: "loader",
  components: { Animation },
})
export default class Loader extends Vue {
  /**
   * Text to be displayed under the loading animation. Changes to this will be faded in/out.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly text!: string;

  /**
   * Loading animation data attached so it can be accessed in the template and accessed via the [[Animation]]
   * component.
   */
  loading: any = loading;
}
