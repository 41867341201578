import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import {
  ENABLE_SENTRY,
  ENV_NAME,
  GIT_SHA,
  SENTRY_DSN,
  VERSION,
} from "@/config";
import { HTTPMethod } from "@/store/api";

/**
 * Interface representing an error reporter. This will be mocked during
 * development as we don't want to report errors then.
 */
interface ErrorReporter {
  /**
   * Reports an arbitrary error to the error reporting service.
   * @param error - Error to report or string message
   */
  report: (error: Error | string) => void;
  /**
   * Sets the user to be attached to all error reports so we can see how many people an error is affecting
   * @param user - User to attach to reports, if this is undefined, don't attach a user
   */
  setUser: (user?: string) => void;
}

/**
 * Instance of the error reporter. Will send to Sentry in production  and log to the console in development.
 */
let errorReporter: ErrorReporter;

if (ENABLE_SENTRY) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    environment: ENV_NAME,
    release: `${VERSION}-${GIT_SHA}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    logErrors: true,
  });
  errorReporter = {
    report(error) {
      Sentry.captureException(error);
    },
    setUser(user) {
      Sentry.setUser(user ? { id: user } : null);
    },
  };
} else {
  errorReporter = {
    report(error) {
      // eslint-disable-next-line no-console
      console.warn("Would've reported error in production:", error);
    },
    setUser(user) {
      // eslint-disable-next-line no-console
      console.log("Would've set error reporting user in production:", user);
    },
  };
}

class HttpResponseError extends Error {
  requestUrl: string;
  requestHTTPMethod: HTTPMethod;
  statusCode: number;

  constructor(
    requestUrl: string,
    requestHTTPMethod: HTTPMethod,
    statusCode: number
  ) {
    super(`${statusCode}: ${requestHTTPMethod} ${requestUrl}`);
    this.name = "HttpResponseError";
    this.requestUrl = requestUrl;
    this.requestHTTPMethod = requestHTTPMethod;
    this.statusCode = statusCode;
  }
}

class NotFoundError extends HttpResponseError {
  constructor(requestUrl: string, requestHTTPMethod: HTTPMethod) {
    super(requestUrl, requestHTTPMethod, 404);
  }
}

export default errorReporter;
export { HttpResponseError, NotFoundError };
