
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import { ENABLE_TRANSITIONS } from "./config";
import { MUTATION_DISMISS_ERROR } from "./store/constants";
import CardDialogBackground from "./components/CardDialogBackground.vue";
import DialogInformation from "./views/panels/dashboard/DialogInformation.vue";
import EnvironmentBanner from "@/components/EnvironmentBanner.vue";
import { ErrorMessage } from "@/store/internal/state";
import { ErrorLevel } from "@/store/types";
import { Route } from "vue-router";

/**
 * Prefix for all titles
 *
 * ![](media://titleprefix.png)
 */
const titleBase = "Dashboard";

/**
 * Main application entry-point component. Contains the root `<router-view>` and displays any errors added to the
 * store via [[ADD_ERROR]].
 */
@Component({
  name: "app",
  components: { CardDialogBackground, DialogInformation, EnvironmentBanner },
})
export default class App extends Vue {
  /**
   * Default route transition, updated with `$route` watcher. Ensures the animations make sense given the route
   * hierarchy (e.g. slide one way on login, the other on logout)
   */
  transitionName: string = "slide-left";

  /**
   * See [[State.errors]]. Displayed in a single dialog if there are any defined.
   * @category Vuex Binding
   */
  @State("errors") readonly errors!: ErrorMessage[];

  /**
   * Highest error level in the current [[errors]] list. This will control what the dialog looks like and whether a
   * reload button is shown instead of a back one.
   * @returns Integer representing the greatest error level or `-Infinity` if there aren't any [[errors]]
   * @category Vue Computed
   */
  get highestErrorLevel(): ErrorLevel {
    return Math.max(...this.errors.map((error) => error.level));
  }

  /**
   * Checks whether we've got a fatal error requiring a page reload to recover from.
   * @returns `true` if and only if the [[highestErrorLevel]] is greater than or equal [[FATAL]].
   * @category Vue Computed
   */
  get hasFatalError() {
    return this.highestErrorLevel >= ErrorLevel.FATAL;
  }

  /**
   * Click handler for the button in the error dialog. If there are fatal errors, will refresh the entire page,
   * otherwise, will just dismiss all currently displayed errors.
   */
  dismissErrors() {
    if (this.hasFatalError) {
      window.location.reload();
    } else {
      for (const error of this.errors) {
        this.$store.commit(MUTATION_DISMISS_ERROR, error.id);
      }
    }
  }

  /**
   * Watcher for the current route object. Also called immediately when the component is created. Updates the page
   * title and the route transition based on the `order` of the `to` and `from` routes.
   * @param to - Route we're moving to
   * @param from - Route we're coming from (may be undefined if this is the initial route)
   */
  @Watch("$route", { immediate: true })
  onRouteChange(to: Route, from: Route) {
    // update page title based on route
    if (to.meta?.title) {
      document.title = `${titleBase} - ${to.meta.title(this.$route)}`;
    } else {
      document.title = titleBase;
    }

    if (from) {
      // make the route transition appear natural according to route order
      const toOrder = to.meta?.order;
      const fromOrder = from.meta?.order;
      this.transitionName = toOrder > fromOrder ? "slide-left" : "slide-right";
    }
  }

  /**
   * Disable transitions in testing. May help with WebDriver (BrowserStack) tests.
   * @returns `true` if we're running tests in a Docker container
   * @category Vue Computed
   */
  get disableTransitions(): boolean {
    return !ENABLE_TRANSITIONS;
  }
}
