
import { defineComponent } from "vue";

import IconInfo from "@/assets/icons/IconInfo.vue";
import Loader from "@/components/Loader.vue";
import { formattedResultsMixin } from "@/components/mixins/formattedResultsMixin";
import errorReporter, { HttpResponseError, NotFoundError } from "@/errors";
import { ChartCapnogram } from "@/store/fetcher/capnogram";
import { ChartAverageWaveform } from "@/store/fetcher/waveform";
import {
  ntdPrefix,
  ActionTypes,
  GetterTypes,
} from "@/store/internal/modules/ntd";
import PanelCapnogram from "@/views/panels/dashboard/detail/PanelCapnogram.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import PanelWaveform from "@/views/panels/dashboard/detail/PanelWaveform.vue";
import { MUTATION_ADD_ERROR } from "@/store/constants";
import { ErrorLevel, HandsetV2 } from "@/store/types";

export default defineComponent({
  name: "results-page",

  components: {
    IconInfo,
    Loader,
    PanelCapnogram,
    PanelFooter,
    PanelWaveform,
  },
  data() {
    return {
      loading: true,
      probabilityInfoIconHover: false,
    };
  },
  mixins: [formattedResultsMixin],

  computed: {
    printRoute(): { name: string; params: { capnogramId: string } } {
      return {
        name: "print-test-result",
        params: { capnogramId: this.capnogramId },
      };
    },
    backButton(): {
      text: "Go back" | "Finish test";
      route: {
        name: "ntd-handset-results" | "ntd-start-test";
        params: { udi?: string };
      };
    } {
      const route: {
        name: "ntd-handset-results" | "ntd-start-test";
        params: { udi?: string };
      } = {
        name: "ntd-start-test",
        params: { udi: this.handset?.udi },
      };
      let text: "Go back" | "Finish test" = "Finish test";

      if (this.$route.query.results) {
        route.name = "ntd-handset-results";
        text = "Go back";
      }

      return { text, route };
    },
    averageWaveform(): ChartAverageWaveform | null {
      return this.$store.getters[
        ntdPrefix(GetterTypes.GET_AVERAGE_WAVEFORM_CHART_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
    },
    capnogramId(): string {
      return this.$route.params.capnogramId;
    },

    capnogramChart(): ChartCapnogram | null {
      return this.$store.getters[
        ntdPrefix(GetterTypes.GET_CHART_CAPNOGRAM_BY_ID)
      ](this.capnogramId);
    },
    capnogramStartTime(): string | null {
      return this.$store.getters[
        ntdPrefix(GetterTypes.GET_CAPNOGRAM_START_TIME_BY_ID)
      ](this.capnogramId);
    },

    handset(): HandsetV2 | null {
      return this.$store.getters[
        ntdPrefix(GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
    },
  },

  created() {
    // here just to be explicit - the initial value is true
    this.loading = true;
    // Fetch all the capnogram data
    Promise.all([
      this.$store.dispatch(
        ntdPrefix(ActionTypes.GET_CAPNOGRAM_RAW),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntdPrefix(ActionTypes.GET_CAPNOGRAM_COMPUTED_FEATURES),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntdPrefix(ActionTypes.GET_CAPNOGRAM_DIAGNOSTIC_RESULT),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(ntdPrefix(ActionTypes.GET_HANDSETS)),
    ])
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);

        // open custom modal when the test result was not found
        if (error instanceof NotFoundError) {
          this.$store.commit(MUTATION_ADD_ERROR, {
            message: "The test results were not found",
            level: ErrorLevel.ERROR,
          });
        }

        // redirect if any HttpResponseErrors are thrown
        if (error instanceof HttpResponseError) {
          // redirect to the default page
          this.$router.push({ name: "ntd" });
        }

        errorReporter.report(error);
      })
      .finally(() => (this.loading = false));
  },
});
