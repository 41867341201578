
import Vue from "vue";
import Component from "vue-class-component";
import CardDialogBackground from "../../../components/CardDialogBackground.vue";
import DialogInformation from "../dashboard/DialogInformation.vue";
import TextInput from "../../../components/TextInput.vue";
import store, { apiClient } from "@/store";
import { MUTATION_ADD_ERROR } from "@/store/constants";

/**
 * Panel embedded in [[ViewLoginWrapper]] for resetting a password, shown when the link in the email is clicked.
 * Requires a password reset key (VEK) to be included in the URL.
 */
@Component({
  name: "panel-password-reset",
  components: { TextInput, DialogInformation, CardDialogBackground },
  beforeRouteEnter(route, from, next) {
    // make sure the password reset key is included in the url before allowing access to this page
    if (!route.query.vek) {
      store.commit(MUTATION_ADD_ERROR, {
        message: "Invalid password reset link!",
      });
      next({ name: "login" });
    } else {
      next();
    }
  },
})
export default class PanelPasswordReset extends Vue {
  /**
   * New password. Must match [[passwordRepeat]] before the reset button is enabled.
   * @category Vue Data
   */
  password: string = "";
  /**
   * Copy of the new password. Must match [[password]] before the rest button is enabled.
   * @category Vue Data
   */
  passwordRepeat: string = "";
  // invitationCode: string = "";
  /**
   * Whether we're currently resetting the user's password, disable the reset button if we are to prevent spamming.
   * @category Vue Data
   */
  resetting: boolean = false;
  /**
   * Whether to show the success dialog
   * @category Vue Data
   */
  resetDialogVisible: boolean = false;
  /**
   * Raw error response from the API. Maps field names to a list of associated verification errors.
   * @category Vue Data
   */
  rawErrors: { [key: string]: string[] } = {};

  /**
   * Helper for extracting the correctly typed password reset key from the URL. This is guaranteed to be set because
   * of the `beforeRouteEnter` function in the component definition.
   * @returns String version of the VEK in the query
   * @category Vue Computed
   */
  get passwordResetKey(): string {
    return this.$route.query.vek.toString();
  }

  /**
   * Whether we're **set**ting the password not **reset**ting it. This is the case for initial password creation and is
   * determined by the existence of the `set` query parameter. If we are, change some of the text contents.
   * @returns `true` if the `set` query parameter is truthy
   * @category Vue Computed
   */
  get setting(): boolean {
    return !!this.$route.query.set;
  }

  /**
   * Whether the login button is enabled. Disable the button if the new passwords are empty or don't match, or if we're
   * missing other credentials.
   * @returns `true` if any data isn't set, or passwords don't match
   * @category Vue Computed
   */
  get disabled() {
    return (
      this.password === "" ||
      this.passwordRepeat === "" ||
      this.password !== this.passwordRepeat ||
      // this.invitationCode === "" ||
      this.resetting ||
      !this.passwordResetKey
    );
  }

  /**
   * Joins each of the arrays in the raw API error response ([[rawErrors]]) to a single string.
   * @returns Object mapping field names to single error string containing all verification errors for that field
   * @category Vue Computed
   */
  get errors(): { [key: string]: string } {
    // convert each mapped to error string[] to a string
    return Object.fromEntries(
      Object.entries(this.rawErrors).map(([k, v]) => [k, v.join(" ")])
    );
  }

  /**
   * Click handler for the reset button. After checking if the button is disabled, tries to reset the user's password.
   * If there are any errors, they will be stored in [[rawErrors]] and shown under the relevant fields. See
   * [[APIClient.resetPassword]].
   */
  reset() {
    if (!this.disabled) {
      this.resetting = true; // stop the user being able to reset their password whilst resetting

      // try to reset the password
      apiClient
        .resetPassword({
          passwordResetKey: this.passwordResetKey,
          //invitationCode: this.invitationCode,
          invitationCodes: {},
          password: this.password,
        })
        .then((res) => {
          // if this was successful, so the success dialog
          if (res.status === 200) {
            this.resetDialogVisible = true;
          } else {
            // store any errors that occurred
            this.rawErrors = res.data || {};
          }
        })
        .finally(() => {
          // make sure to always reactivate the reset button whatever happens
          this.resetting = false;
        });
    }
  }

  /**
   * Click handler for next button on success dialog. Redirects the user to the login page to login with their new
   * password.
   */
  resetDialogNext() {
    this.resetDialogVisible = false;
    this.$router.replace({ name: "login" });
  }
}
