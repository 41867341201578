var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"text-input",class:{
    // both strings & arrays (for tags) have a length property
    'has-text': _vm.value.length !== 0 || _vm.tagsInputHasText,
    'has-focus': _vm.focused,
    'has-error': _vm.error,
    'is-pinned': _vm.pinned || _vm.type === 'date', // date inputs always have data in them, so pin the label
    'is-disabled': _vm.disabled,
    'has-disabled-underline': _vm.disabledUnderline,
  },on:{"mouseenter":_vm.startHover,"mouseleave":_vm.cancelHover}},[_c('span',{staticClass:"text-input-label"},[_vm._v(_vm._s(_vm.label))]),(_vm.type === 'tags')?_c('TagsInput',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)},"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"input-new-tag":function($event){_vm.tagsInputHasText = !!$event}}}):(_vm.type === 'static')?_c('p',{staticClass:"static-input"},[_vm._v(" "+_vm._s(_vm.value)+" ")]):_c('input',{style:({ maxWidth: _vm.computedDynamicMaxWidth }),attrs:{"type":_vm.type,"id":_vm.computedName,"name":_vm.computedName,"disabled":_vm.disabled,"required":_vm.type === 'date'},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"keydown":_vm.onKeyDown}}),(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('transition',{attrs:{"name":"tooltip-slide"}},[(_vm.$slots.tooltip && _vm.longHover)?_c('Tooltip',[_vm._t("tooltip")],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }