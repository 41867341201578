import { State } from "@/store/internal/state";
import { DeviceTrendSeries } from "@/store/fetcher/trends";
import { Route } from "vue-router";

/**
 * Checks if any of the DUID/user data (summary stats, waveform, capnogram, trends) is unset. If it is, a loading
 * indicator should be shown on the detail view.
 * @param state - State of the application [[src/store]]
 * @returns `true` if any of the DUID data isn't defined
 * @category Vuex Getter
 */
export function deviceDataLoading(state: State): boolean {
  return !(
    state.deviceSummaryStats &&
    state.deviceWaveform &&
    state.deviceCapnogram &&
    state.deviceTrends
  );
}

/**
 * Currently selected trend series based on the
 * [[src/store/internal/state.State.selectedTrendName | selectedTrendName]]
 * @param state - State of the application [[src/store]]
 * @returns The selected trend's series or null if trends haven't loaded yet, or
 * the [[src/store/internal/state.State.selectedTrendName | selectedTrendName]]
 * is invalid
 * @category Vuex Getter
 */
export function selectedTrend(state: State): DeviceTrendSeries | null {
  // get the currently selected series
  if (!state.deviceTrends) return null;
  const trend = state.deviceTrends[state.selectedTrendName];
  if (!trend) return null;
  return trend;
}

/**
 * Whether the user is logged in
 * @param state - State of the application [[src/store]]
 * @returns `true` if the [[State.user | user]] in the [[src/store]]'s state is defined
 * @category Vuex Getter
 */
export function loggedIn(state: State): boolean {
  return !!state.user;
}

// noinspection JSUnusedGlobalSymbols
/**
 * Whether the currently logged in user has the permission to access the detail view.
 * @param state - State of the application [[src/store]]
 * @returns `true` if the user has the `view_detail` permission
 * @category Vuex Getter
 */
export function hasDetailPermission(state: State): boolean {
  return !!state.userInfo?.perms.includes("camrest.view_detail");
}

/**
 * Whether the currently logged in user has the permission to allocate handsets.
 * @param state - State of the application [[src/store]]
 * @returns `true` if the user has the `device_assign` permission
 * @category Vuex Getter
 */
export function hasDeviceAssignPermission(state: State): boolean {
  return !!state.userInfo?.perms.includes("camrest.device_assign");
}

/**
 * Whether the currently logged in user has the permission to view the NTD interface.
 * @param state - State of the application [[src/store]]
 * @returns `true` if the user has the `ntd_ui` permission
 * @category Vuex Getter
 */
export function hasNtdUiPermission(state: State): boolean {
  return !!state.userInfo?.perms.includes("camrest.ntd_ui");
}

/**
 * Whether the currently logged in user has the permission to view the NTC interface.
 * @param state - State of the application [[src/store]]
 * @returns `true` if the user has the `rw_dash` permission
 * @category Vuex Getter
 */
export function hasRwDashPermission(state: State): boolean {
  return !!state.userInfo?.perms.includes("camrest.rw_dash");
}

/**
 * Returns a function for checking if the product is selected
 * @param state - State of the application [[src/store]]
 * @returns function (route, product): boolean
 */

export function isCurrentProduct(state: State) {
  return (currentRoute: Route, product: string) => {
    if (hasNtdUiPermission(state) && hasRwDashPermission(state)) {
      if (currentRoute.meta?.selectedProduct)
        return currentRoute.meta.selectedProduct === product;
      return currentRoute.matched.some(
        (route) => route.meta.selectedProduct === product
      );
    }
    // If the user only has permission for one product,
    // use that product (i.e. for formatting).
    if (hasNtdUiPermission(state)) return "ntd" === product;
    if (hasRwDashPermission(state)) return "ntc" === product;
    return false;
  };
}
