
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IndicatorColour } from "@/components/graphics/utils";

/**
 * Component for displaying the normalised population of a statistic. Shown as a rounded bar with percentiles
 * highlighted and a circle indicating the position of the current value. See also [[percentPointFunction]].
 *
 * ![Normalised Plot](media://normalisedplot.png)
 */
@Component({
  name: "normalised-plot",
})
export default class NormalisedPlot extends Vue {
  /**
   * Colour of the circle indicating the current value. Should be one of [[IndicatorColour]]s.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "success" }) readonly type!: IndicatorColour;
  /**
   * Percentage position along the population, where 0% is the leftmost and 100% is the rightmost. This value will
   * be clamped between 0 and 100 before being displayed. See [[percentPointFunction]] for how to get this value.
   * @category Vue Prop
   */
  @Prop({ default: 50 }) readonly percentPosition!: number;
  /**
   * Whether to hide the current value indicator circle. Used when there is no data to display.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly hideDot!: boolean;
  /**
   * Text to display under the plot. Defaults to "Normalised population".
   * @category Vue Prop
   */
  @Prop({ type: String, default: "Normalised population" })
  readonly name!: string;

  /**
   * Clamped copy of [[percentPosition]] between 0% and 100%.
   * @returns Clamped position between 0 and 100
   * @category Vue Computed
   */
  get clampedPercentPosition(): number {
    // ensures the circle isn't rendered outside the bar
    return Math.min(100, Math.max(0, this.percentPosition));
  }
}
