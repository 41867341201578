import {
  autoScale,
  ChartAnnotation,
  ChartDataPoint,
  ChartDataScales,
  ChartDataSeries,
  emptyChart,
} from "@/components/graphics/utils";
import { DeviceDataFetchContext } from "@/store/fetcher/index";

/** Processed [[APICapnogramResponse]] to be rendered in a [[Chart]]. See [[fetchCapnogram]]. */
export interface ChartCapnogram {
  /** Scales for the [[Chart]], showing all the data. */
  scales: ChartDataScales;
  /** Series containing the capnogram data in series space. X-coordinates are seconds, Y-coordinates are kPa. */
  series: ChartDataSeries<ChartDataPoint>;
  /**
   * Array of annotations to be displayed on the chart, for capnograms, this is just background rectangles for errors.
   */
  annotations: ChartAnnotation[];
  /** Breath quality from 0-100 of this capnogram, or null if it failed parameterisation **/
  quality: number | null;
}

/** Data associated with the capnogram no used directly for plotting the
 * capnogram chart  */
interface CapnogramMetadata {
  /** UUID of the capnogram */
  capnogramId: string;
  /** UDI of the handset which recording the capnogram */
  handsetUdi: string;
}

/** Processed [[APICapnogramResponse]] to be rendered in a [[Chart]] with the
 * associated metadata. See [[fetchCapnogram]]. */
export interface ChartCapnogramWithMetadata {
  /** See [[ChartCapnogram]] */
  chartCapnogram: ChartCapnogram;
  /** See [[CapnogramMetadata]] */
  metadata: CapnogramMetadata;
}

/**
 * Calculates the breath quality for a capnogram. Intended to behave exactly the same way of the
 * `capnogram_breath_quality` SQL function in the database. Used to calculate quality of capnograms received during
 * user training.
 * @param errors - [[data_par_error]] of [[APICapnogramResponse]]
 * @returns Breath quality percent from 0-100 of the passed capnogram or null if it failed parameterisation
 */
export function capnogramBreathQuality(errors: string[] | null): number | null {
  if (errors) {
    // ignore first and last breaths
    const includedBreathCount = errors.length - 2;
    // prevent division by 0 or negative number
    if (includedBreathCount <= 0) {
      return 0;
    } else {
      // count number of breaths with error === "" (no error)
      const breathsWithoutError = errors.filter((error) => error === "").length;
      // calculate and return quality, clamping result to 0-100%
      const percentQuality = (breathsWithoutError / includedBreathCount) * 100;
      return Math.max(0, Math.min(100, percentQuality));
    }
  } else {
    return null;
  }
}

/**
 * Extracts and processes the capnogram from the [[DeviceDataFetchContext]]. Spaces the data every 0.02s and builds
 * background rectangles for error breaths.
 * @param ctx - Context to get the capnogram from
 * @returns Processed capnogram with scales, series and annotations
 */
export async function fetchCapnogram(
  ctx: DeviceDataFetchContext
): Promise<ChartCapnogramWithMetadata> {
  const metadata: CapnogramMetadata = {
    capnogramId: ctx.capnogram.id,
    handsetUdi: ctx.capnogram.handset_udi,
  };

  // if there isn't any breath data, just return an empty capnogram
  if (ctx.capnogram.data_breath === null) {
    const empty = {
      ...emptyChart<ChartCapnogram>(false),
      annotations: [],
      quality: null,
    };

    return {
      chartCapnogram: empty,
      metadata,
    };
  }

  const capnogramData = ctx.capnogram.data_breath.map((value, index) => ({
    x: index * 0.02, // data points are every 0.02 seconds
    y: value,
  }));

  // add background rects to indicate parameterisation errors
  const annotations: ChartAnnotation[] = [];
  if (ctx.capnogram.data_splits && ctx.capnogram.data_par_error) {
    // make sure the split coordinates have time units
    const splits = ctx.capnogram.data_splits.map((value) => value * 0.02);
    for (let i = 0; i < ctx.capnogram.data_par_error.length; i++) {
      const error = ctx.capnogram.data_par_error[i];
      // when there was an error for a breath...
      if (error !== "") {
        // get the start and end times of that breath
        const startSplit = splits[i];
        const endSplit = splits[i + 1];
        // try to add a rectangle there
        if (startSplit !== undefined && endSplit !== undefined) {
          annotations.push({
            type: "background-rect",
            x: startSplit,
            x2: endSplit,
          });
        }
      }
    }
  }

  const chartCapnogram = {
    scales: autoScale(capnogramData),
    series: {
      data: capnogramData,
    },
    annotations,
    quality: capnogramBreathQuality(ctx.capnogram.data_par_error),
  };

  return { chartCapnogram, metadata };
}
