
import Vue from "vue";
import Card from "./Card.vue";
import Component from "vue-class-component";

/**
 * Component representing a dialog that is made up of a card. The dialog will appear above all other elements
 * and be centered on the screen. This component also specifies some default styling for text and buttons
 * within the dialog. The default slot will be rendered on top of the card's "paper". See also [[CardDialogBackground]]
 * for the modal background that goes with this component.
 */
@Component({
  name: "card-dialog",
  components: { Card },
})
export default class CardDialog extends Vue {}
