
import Vue from "vue";
import Component from "vue-class-component";

/**
 * Component used as the backdrop for [[CardDialog]]s. Provides some sense of depth on the page when dialogs are visible
 * and also prevents the user from clicking on other components when the dialog is visible. This is meant to be included
 * before the [[CardDialog]] but in the same level of the element tree. The reason this is a separate component, and not
 * part of [[CardDialog]] *(it used to be)*, was because when transitioning between dialogs, you would get flickering
 * backgrounds.
 */
@Component({
  name: "card-dialog-background",
})
export default class CardDialogBackground extends Vue {}
