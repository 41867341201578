
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter } from "vuex-class";
import Dropdown from "@/components/Dropdown.vue";
import IconDown from "@/assets/icons/IconDown.vue";
import IconSettings from "@/assets/icons/IconSettings.vue";
import IconBars from "@/assets/icons/IconBars.vue";
import IconTrends from "@/assets/icons/IconTrends.vue";
import IconBlank from "@/assets/icons/IconBlank.vue";
import IconExit from "@/assets/icons/IconExit.vue";
import { APIUserInfoResponse, DUID } from "@/store/types";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";

/**
 * Panel displayed at the top of dashboard views. If the logged in has permissions, allows the user to allocate
 * handsets, select DUIDs to see detail views of, navigate to the detail & list views, see documentation and log out.
 * Displays compressed on mobile so everything fits.
 *
 * ![](media://header.png)
 *
 * ![](media://headermobile.png)
 */
@Component({
  name: "panel-header",
  components: {
    IconBars,
    IconTrends,
    IconBlank,
    IconExit,
    IconSettings,
    IconDown,
    Dropdown,
  },
})
export default class PanelHeader extends Vue {
  // vuex store bindings
  /**
   * See [[State.userInfo]]
   * @category Vuex Binding
   */
  @State("userInfo") readonly userInfo!: APIUserInfoResponse | null;
  /**
   * See [[State.userDUIDs]]
   * @category Vuex Binding
   */
  @State("userDUIDs") readonly userDUIDs!: DUID[] | null;
  /**
   * See [[State.deviceDUID]]
   * @category Vuex Binding
   */
  @State("deviceDUID") readonly deviceDUID!: string;
  /**
   * See [[State.deviceAdditionalInfo]]. Used to label the selected DUIDs with the participant's name if its available.
   * @category Vuex Binding
   */
  @State("deviceAdditionalInfo")
  readonly deviceAdditionalInfo!: DeviceAdditionalInfo;
  /**
   * See [[src/store/internal/getters.hasDeviceAssignPermission |
   * hasDeviceAssignPermission]] getter. Used to determine whether the allocate
   * handset button should be shown. Even though we're just hiding the button,
   * Django still requires this permission for the device allocation route, so
   * they wouldn't be able to allocate anything.
   * @category Vuex Binding
   */
  @Getter("hasDeviceAssignPermission")
  readonly hasDeviceAssignPermission!: boolean;

  /**
   * Whether the avatar menu currently has a pointer hovering over it and should be shown
   * @category Vue Data
   */
  avatarHover: boolean = false;

  /**
   * Whether the content of the header should be visible. Wait until we've fetched [[userInfo]] before showing the
   * header.
   * @returns `true` when [[userInfo]] has been fetched
   * @category Vue Computed
   */
  get headerVisible(): boolean {
    return !!this.userInfo;
  }

  /**
   * Gets the title of the application from the configuration.
   * @returns title
   */
  get title(): string {
    if (this.isProductNtc) {
      return "N-Tidal C";
    }
    if (this.isProductNtd) {
      return "N-Tidal Screen";
    }
    return "N-Tidal";
  }

  /**
   * Gets the user's initials to display in the avatar or an empty string if [[userInfo]] hasn't been fetched.
   * @returns 2 uppercase characters representing the user's name.
   */
  get userInitials(): string {
    if (!this.userInfo) return "";
    // String.prototype[@@iterator]() is unicode aware so [...<string>] will
    // create an array of symbols not characters. If users have emojis in their
    // names, these will be preserved. 😁
    const first = this.userInfo.first_name[Symbol.iterator]().next();
    const last = this.userInfo.last_name[Symbol.iterator]().next();
    return (first.value || "").toUpperCase() + (last.value || "").toUpperCase();
  }
  get isProductNtc(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntc");
  }
  get isProductNtd(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntd");
  }
}
