
import Vue from "vue";
import Component from "vue-class-component";
import Hammer from "hammerjs";
import {
  ChartAnnotation,
  ChartDataPoint,
  ChartDataScales,
  ChartDataSeries,
  descaleValue as _descaleValue,
  normY,
  scaleValue as _scaleValue,
} from "./utils";
import { Prop, PropSync, Ref, Watch } from "vue-property-decorator";

// TODO: this entire component needs to be tidied up a bit, it's had lots of features over time and has grown organically as a result

/**
 * Whether to split the line in the chart into coloured sections based on the colour of each point. With this disabled,
 * as it is, charts will just be one line of the same colour.
 *
 * #### Enabled
 *
 * ![Enabled](media://bandcoloursenabled.png)
 *
 * #### Disabled
 *
 * ![Disabled](media://bandcoloursdisabled.png)
 */
const enableBandColours = false;

/**
 * See [[Chart.bandColours]].
 */
const bandColours = {
  default: ["#427AC9", "rgba(76,143,241,0.2)"],
  red: ["#D94646", "#F5D1D1"],
  yellow: ["#F5AB55", "#FCEAD4"],
  green: ["#249D7C", "#C8E6DE"],
} as { [key: string]: string[] };

/**
 * Gets the CSS colour strings for a point's colour name. If the name is unknown, returns the default colours.
 *
 * @param colour - Name of the colour set to get
 * @returns Colour set for that name in the form `[line stroke, variation fill]`
 */
function hexColoursForColourString(colour?: string): string[] {
  return colour && colour in bandColours
    ? bandColours[colour]
    : bandColours["default"];
}

/**
 * Represents a path segment for a band to be drawn on the chart, containing the SVG paths for the line and varation
 * along with the colours they should be.
 *
 * For example, the graphic below is made up of 10 `BandPath`s separated by blue lines.
 *
 * ![Bands](media://bandpaths.png)
 */
export interface BandPath {
  /**
   * SVG path [d attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d) for variation in values.
   * This should be a closed shape.
   */
  areaPath: string;
  /** CSS colour to use when filling in [[areaPath]]. Should be a lighter version of [[lineStroke]]. */
  areaFill: string;
  /** SVG path [d attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d) for the line on the chart. */
  linePath: string;
  /** CSS colour to use when drawing the stroke for [[linePath]]. */
  lineStroke: string;
}

/**
 * Represents a point on the chart that has coordinates and a colour.
 *
 * ![Point](media://colouredpoint.png)
 */
interface ColouredDataPoint {
  /** Scaled and transformed X-coordinate of the point. */
  x: number;
  /** Scaled and transformed Y-coordinate of the point. */
  y: number;
  /** CSS colour to use for the point. */
  hexColour: string;
}

/**
 * See [[Chart.bandColours]].
 */
const vectorEffectSupported =
  // @ts-ignore
  document.documentElement.style.vectorEffect !== undefined &&
  !navigator.userAgent.includes("Firefox");

/**
 * Number of pixels the user is able to scroll horizontally either side of the chart boundaries.
 *
 * ![Padding](media://translatepadding.png)
 */
const clampedTranslatePadding = 10;

/**
 * Component for displaying a line chart, optionally displaying the variation in values too behind the line. Positions
 * headers, axis and overlays on and around the chart too. When hovering over data points, shows a crosshair, along
 * with lines showing where the point is on the axis. Also allows zooming/panning the chart area, if enabled.
 *
 * ![Chart](media://chart.png)
 *
 * #### Coordinate Spaces
 *
 * Throughout the documentation of this component, different coordinate spaces are referenced. These are what they
 * are referring to.
 *
 * |Space|Description|
 * |-|-|
 * |Screen|Position from the top-left corner of the whole document, including any that isn't visible|
 * |Offset|Position from the top-left corner of the chart area|
 * |Series|Position as defined in the actual chart data before any scaling takes place|
 *
 * <br>
 *
 * #### General Overview
 *
 * The component user will give an array of [[series]] and [[scales]], each of the same length. For each series, a
 * non-reactive copy of it will be generated. Whenever this changes, a computed property for SVG paths to display on
 * the chart area will be updated.
 *
 * This component also accepts slots for headers, axis and overlays. The bottom
 * axis slot is scoped with a `scale` function, that accepts a series space X-coordinate and transforms it into offset
 * space, taking into account the current zoom and translate.
 *
 * Whenever the user moves their mouse over this component, the screen space coordinates are recorded, and the point
 * on the top series that is closest to the cursor is marked as the [[cursorPoint]]. This point is found using a
 * binary search, so it's important the passed series are sorted by X-coordinate.
 */
@Component({
  name: "chart",
})
export default class Chart extends Vue {
  /**
   * Line stroke and variation fill colours of each named colour. The colour is the key and is one of `default`, `red`,
   * `yellow` or `green`. These map to arrays of length 2 containing CSS colour codes of the form
   * `[line stroke, variation fill]`.
   *
   * ![Colours](media://bandcoloursenabled.png)
   *
   * @category Vue Data
   */
  bandColours = bandColours;
  /**
   * True if the [SVG `vector-effect` attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/vector-effect)
   * is supported. If it is, we can use `non-scaling-stroke` and SVG transforms when zooming instead of rebuilding the
   * entire path in JavaScript each time leading to much better performance.
   *
   * We disable this explicitly for Firefox even though it's technically supported as it's quite glitchy
   * *(as of 18/06/2020)*.
   *
   * @category Vue Data
   */
  vectorEffectSupported = vectorEffectSupported;

  /**
   * Chart area SVG where lines/areas will be rendered to. See the pink outlined box below.
   *
   * ![Chart](media://chartref.png)
   *
   * @category Vue Ref
   */
  @Ref() readonly chart!: SVGSVGElement;

  /**
   * XY-scales used when positioning each series in the chart. Should be an array of the same
   * length as the [[series]] array, where each series will match to the XY-scales at the same index.
   * See [[ChartDataScales]] for the format of scales.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [
        {
          x: [0, 100],
          y: [0, 100],
        },
      ];
    },
  })
  readonly scales!: ChartDataScales[];

  /**
   * Series to plot on the chart. Should be an array containing all series to plot, with each
   * having a corresponding [[ChartDataScales]] object in the [[scales]] property. The data array must be sorted
   * by increasing x-value for the crosshair to display correctly. Series may/may not have minimum/maximum values
   * associated with data points. See [[ChartDataSeries]] for the format of series.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [
        {
          data: [
            { x: 40, y: [40, 50, 60] },
            { x: 50, y: [30, 55, 70] },
            { x: 60, y: [40, 50, 60] },
          ],
        },
      ];
    },
  })
  readonly series!: ChartDataSeries<ChartDataPoint>[];

  /**
   * Names of [[series]] currently displayed on the chart. This is only required if you're
   * dynamically changing which series you're displaying, in which case the names will be used as prefixes for keys
   * for the rendered paths in the SVG. This ensures that when series are removed, the correct paths are transitioned
   * out. If this isn't specified, the indices of series will be used instead.
   * @category Vue Prop
   */
  // TODO: consider moving this to the series prop
  @Prop({
    type: Array,
    default() {
      return null;
    },
  })
  readonly seriesNames!: string[] | null;

  /**
   * Annotations to display on the chart. See [[ChartAnnotation]] for the different types
   * available. These will be automatically split into those rendered behind the plot, and those in front.
   * @category Vue Prop
   */
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  readonly annotations!: ChartAnnotation[];

  /**
   * Prevents the chart header from being rendered.
   * Defaults to `false`.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false })
  readonly disableHeader!: boolean;

  /**
   * Prevents annotations defined in individual points in [[series]] from being rendered. Only
   * annotations defined in the [[annotations]] property will be rendered. This improves cursor hover performance
   * as the series point array doesn't need to be filtered and concatenated with the [[annotations]] array.
   * Defaults to `false`.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false })
  readonly disablePointAnnotations!: boolean;

  /**
   * Enables the user to drag/scroll to pan/zoom the graph. Defaults to `false`.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly transformable!: boolean;

  /**
   * Describes the thickness of plotted lines. Defaults to `1.5` pixels.
   * @category Vue Prop
   */
  @Prop({ type: Number, default: 1.5 }) readonly strokeWidth!: number;

  /**
   * Controls the display of data point circles on the graph. Defaults to `false`.
   *
   * #### Enabled
   *
   * ![Point](media://colouredpoint.png)
   *
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) readonly showPoints!: boolean;

  /**
   * Controls current x-zoom level. It's the responsibility of the parent component to handle
   * `update:xZoom` events whose payload will be a new x-zoom level. This is done to allow parents to adjust displayed
   * data such as axis ticks in response to the current zoom. Defaults to `1`.
   * @category Vue Prop
   */
  @PropSync("xZoom", { type: Number, default: 1 }) syncedXZoom!: number;
  /**
   * Factor by which to increase x-zoom rate. Defaults to `1`.
   * @category Vue Prop
   */
  @Prop({ type: Number, default: 1 }) readonly xZoomMultiplier!: number;
  /**
   * Minimum x-zoom which [[xZoom]] is clamped too when updated. Defaults to `1`.
   * @category Vue Prop
   */
  @Prop({ type: Number, default: 1 }) readonly minXZoom!: number;
  /**
   * Maximum x-zoom which [[xZoom]] is clamped too when updated. Defaults to `15`.
   * @category Vue Prop
   */
  @Prop({ type: Number, default: 15 }) readonly maxXZoom!: number;
  /**
   * Controls current x-translate. It's the responsibility of the parent component to handle
   * `update:xTranslate` events whose payload will be a new x-translate. This is done to allow parents to adjust
   * displayed data such as axis ticks in response to the current translate. Defaults to `0`.
   * @category Vue Prop
   */
  @PropSync("xTranslate", { type: Number, default: 0 })
  syncedXTranslate!: number;

  /**
   * Message shown in the chart area instead of a plot when the series is empty. Defaults to
   * `We couldn't find any data`.
   * @category Vue Prop
   */
  @Prop({ type: String, default: "We couldn't find any data" })
  readonly noDataMessage!: string;

  /**
   * Current data point in the top series that was the closest to the mouse cursor, or null if the chart hasn't been
   * hovered over yet.
   * @category Vue Data
   */
  cursorPoint: ChartDataPoint | null = null;
  /**
   * Size of the chart area in pixels, automatically updated when the window resizes.
   * @category Vue Data
   */
  sizes: { width: number; height: number } = {
    /** Width of the chart area in pixels */
    width: 0,
    /** Height of the chart area in pixels */
    height: 0,
  };

  /**
   * Arbitrary point attached to the [[chart]] SVG that can be have its coordinates set and then be manipulated using
   * matrices. Used for calculating offset coordinates from mouse positions.
   */
  point?: SVGPoint = undefined;
  /**
   * X-coordinate of the cursor currently hovered over the chart. [[cursorPoint]] will try to get as close to this as
   * possible if it's defined.
   * @category Vue Data
   */
  targetCursorPageX?: number = undefined;
  /**
   * Y-coordinate of the cursor currently hovered over the chart. [[cursorPoint]] will try to get as close to this as
   * possible if it's defined.
   * @category Vue Data
   */
  targetCursorPageY?: number = undefined;
  /**
   * [Hammer.js](https://hammerjs.github.io/) instance for managing touch events/gestures (e.g. zooming/panning).
   * Initialised in the [[mounted]] hook once the [[chart]] ref has been set.
   */
  hammer?: HammerManager = undefined;

  /**
   * Non-reactive copy of the data series. Doing this means Vue doesn't have to watch all data points (sometimes over
   * 2000 especially for capnograms) which massively improved performance. Instead it can just observe [[seriesKey]]
   * which is updated whenever this series watcher [[copySeries]] is called.
   */
  nonReactiveSeries?: ChartDataSeries<ChartDataPoint>[] = undefined;
  /**
   * Reactive series key that gets updated whenever the series changes. Computed properties should depend on this
   * and use [[nonReactiveSeries]] for getting series data to update.
   * @category Vue Data
   */
  seriesKey: number = 0;
  /**
   * Updates [[nonReactiveSeries]] and [[seriesKey]] when [[series]] changes. This is called immediately when the
   * component is created ensuring the initial [[series]] from props is copied to [[nonReactiveSeries]].
   * @category Vue Watch
   */
  @Watch("series", { immediate: true })
  copySeries() {
    // creating a copy of the series that isn't reactive drastically improves performance as vue doesn't have to
    // watch all data points, just the seriesKey
    this.nonReactiveSeries = this.series.map((series) => ({
      lineColour: series.lineColour,
      areaColour: series.areaColour,
      data: series.data.map((p) => {
        const point = {
          x: p.x,
          y: Array.isArray(p.y) ? [p.y[0], p.y[1], p.y[2]] : p.y,
        };
        if (p.colour) {
          (point as any).colour = p.colour;
        }
        if ((p as any).xDateFormatted) {
          (point as any).xDateFormatted = (p as any).xDateFormatted;
        }
        return point;
      }),
    }));
    this.seriesKey++;
  }

  /**
   * Checks whether the parent component has specified a (scoped) slot template with the given name.
   * @param name - Name of the slot this component may have
   * @returns `true` if and only if the component has a slot or scoped slot with the specified name
   */
  hasSlot(name: string): boolean {
    // whether the component user has defined a template for a slot with a given name
    return name in this.$slots || name in this.$scopedSlots;
  }

  /**
   * Checks whether the parent component has given this chart a header.
   *
   * #### Example
   *
   * ![Header](media://chartheader.png)
   *
   * @returns `true` if and only if this chart has a header
   * @category Vue Computed
   */
  get hasHeader(): boolean {
    return this.hasSlot("chart-header") && !this.disableHeader;
  }

  /**
   * Checks whether the parent component has given this chart a left axis.
   *
   * #### Example
   *
   * ![Left Axis](media://chartaxisleft.png)
   *
   * @returns `true` if and only if this chart has a left axis
   * @category Vue Computed
   */
  get hasAxisLeft(): boolean {
    return this.hasSlot("chart-axis-left");
  }

  /**
   * Checks whether the parent component has given this chart a bottom axis/footer.
   *
   * #### Example
   *
   * ![Bottom Axis](media://chartaxisbottom.png)
   *
   * @returns `true` if and only if this chart has a bottom axis
   * @category Vue Computed
   */
  get hasAxisBottom(): boolean {
    return this.hasSlot("chart-axis-bottom");
  }

  /**
   * Checks whether the first series exists and has data.
   * @returns `true` if and only if this chart has data
   * @category Vue Computed
   */
  get hasData(): boolean {
    return this.series.length > 0 && this.series[0].data.length > 0;
  }

  /* Chart Transforms */
  /**
   * Converts X-coordinates in series space to offset space. Very similar to [[scaleAndTransformX]], but will
   * only apply to zoom transform if SVG vector effect is not supported. If it is supported, zooming will be handled
   * by SVG transforms instead.
   *
   * @param x - X-coordinate in series space to transform
   * @param seriesIndex - Index of the series this coordinate is from for the correct scales
   * @returns X-coordinate in offset space that can be plotted on the chart area
   */
  scaleAndMaybeZoomX(x: number, seriesIndex: number = 0) {
    if (vectorEffectSupported) {
      // no need to zoom as this will be done by SVG transform
      return _scaleValue(x, this.scales[seriesIndex].x, this.sizes.width);
    } else {
      // otherwise, apply the zoom too
      return (
        _scaleValue(x, this.scales[seriesIndex].x, this.sizes.width) *
        this.syncedXZoom
      );
    }
  }

  /**
   * Converts X-coordinates in series space to offset space.
   *
   * @param x - X-coordinate in series space to transform
   * @param usingZoom - Zoom value to use instead of [[syncedXZoom]] when transforming.
   * @param seriesIndex - Index of the series this coordinate is from for the correct scales
   * @returns X-coordinate in offset space that can be plotted on the chart area
   */
  scaleAndTransformX(x: number, usingZoom?: number, seriesIndex: number = 0) {
    return (
      _scaleValue(x, this.scales[seriesIndex].x, this.sizes.width) *
        (usingZoom || this.syncedXZoom) +
      this.syncedXTranslate
    );
  }

  /**
   * Converts X-coordinates in offset space to series space.
   *
   * @param x - X-coordinate in offset space to transform
   * @param seriesIndex - Index of the series this coordinate is from for the correct scales
   * @returns X-coordinate in series space
   */
  inverseTransformAndDescaleX(x: number, seriesIndex: number = 0) {
    return _descaleValue(
      (x - this.syncedXTranslate) / this.syncedXZoom,
      this.scales[seriesIndex].x,
      this.sizes.width
    );
  }

  /**
   * Converts Y-coordinates in series space to offset space.
   *
   * @param y - Y-coordinate in series space to transform
   * @param seriesIndex - Index of the series this coordinate is from for the correct scales
   * @returns Y-coordinate in offset space that can be plotted on the chart area
   */
  scaleY(y: number, seriesIndex: number = 0) {
    return (
      this.sizes.height -
      _scaleValue(y, this.scales[seriesIndex].y, this.sizes.height)
    );
  }

  /**
   * Value of the [SVG `transform` attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform)
   * applied to the group containing chart lines and variation areas. Will only include a `scale` transform if
   * [[vectorEffectSupported]] and `non-scaling-stroke` can be used to undo the stretch that would otherwise be
   * applied to lines.
   *
   * @returns SVG transform to be applied to chart group
   * @category Vue Computed
   */
  get transform(): string {
    if (isNaN(this.syncedXTranslate) || isNaN(this.syncedXZoom)) return "";

    // only apply the zoom here if we're able to use the non-scaling stroke vector effect
    return `translate(${this.syncedXTranslate} 0)${
      vectorEffectSupported ? ` scale(${this.syncedXZoom} 1)` : ""
    }`;
  }

  /**
   * Clamp the passed x translate value to be within the bounds of the chart, taking into account additional padding.
   * Returns the clamped value but doesn't actually update it.
   *
   * @param value - New [[syncedXTranslate]] value to clamp
   * @param usingZoom - Zoom to use when clamping instead of [[syncedXZoom]]
   * @returns Clamped translate value
   */
  clampsyncedXTranslate(value: number, usingZoom?: number) {
    return Math.max(
      Math.min(value, clampedTranslatePadding),
      -((usingZoom || this.syncedXZoom) - 1) * this.sizes.width -
        clampedTranslatePadding
    );
  }

  /**
   * Builds [[BandPath]]s that can then be rendered in this component's template. See [[BandPath]] for more information
   * on what is returned. This is an $O(n)$ operation and is where most of the chart drawing logic is.
   *
   * @returns Array of band path arrays, one array for each [[series]]
   * @category Vue Computed
   */
  get paths(): BandPath[][] {
    const allSeries = this.nonReactiveSeries;
    const width = this.sizes.width;
    const seriesKey = this.seriesKey;

    // this if statement:
    // - checks if the width is 0 (i.e. we haven't received the chart area size yet)
    // - narrows the type of series to never be undefined
    // - marks this computed getter as having a dependency on seriesKey
    // if any of these conditions are true, we're not ready to draw the chart yet, so abort
    // (vue will recall the method when the values update)
    // noinspection JSIncompatibleTypesComparison
    if (width === 0 || allSeries === undefined || seriesKey === -1) return [];

    return allSeries.map((series, seriesIndex) => {
      // start with an empty array of paths to display
      const bandPaths: BandPath[] = [];

      // current states of the state-machine-ish thing we've got here
      let areaPath = "";
      let linePath = "";
      let reverseAreaPath = "";
      let currentBandColour: string | undefined = undefined;

      // adds the currently being worked on band path to the list of paths if it's non-empty
      function recordBand() {
        // add the reverse path on to form a closed shape
        areaPath += reverseAreaPath;
        // if we've got some path, add the "Z" command which closes it (i.e. return from the last point to the first)
        if (areaPath !== "") areaPath += "Z";

        if (linePath !== "") {
          // get the colours for this band
          const [bandLine, bandArea] = enableBandColours
            ? hexColoursForColourString(currentBandColour)
            : bandColours["default"];

          const line = series.lineColour || bandLine;
          const area = series.areaColour || bandArea;

          // add it to the list of paths to generate
          bandPaths.push({
            areaPath,
            areaFill: area,
            linePath,
            lineStroke: line,
          });
        }

        // reset path states
        areaPath = "";
        linePath = "";
        reverseAreaPath = "";
        currentBandColour = undefined;
      }

      // scale all data points in the series now, so we only have to do this once (O(n))
      const scaledSeries = series.data.map((point) => {
        const yCoord = normY(point.y);

        return {
          x: this.scaleAndMaybeZoomX(point.x, seriesIndex),
          y: this.scaleY(yCoord, seriesIndex),
          // we'll only have min/max if this is an array point (e.g. [min, avg, max])
          minY: Array.isArray(point.y)
            ? this.scaleY(point.y[0], seriesIndex)
            : undefined,
          maxY: Array.isArray(point.y)
            ? this.scaleY(point.y[2], seriesIndex)
            : undefined,
          colour: point.colour || "default",
        };
      });

      // adds a point at a specified index to the current band path
      function addScaledPoint(i: number) {
        // check if the point is in-bounds
        if (0 <= i && i < scaledSeries.length) {
          const { x, y, minY, maxY } = scaledSeries[i];

          // for the below code, "M" means move to point, and is used when we don't have anything drawn yet,
          // "L" means line to point, which will draw a line from the previous point to the new coordinates

          // add to the line
          linePath += `${linePath === "" ? "M" : "L"}${x} ${y}`;
          // add the min/max if defined
          if (minY !== undefined && maxY !== undefined) {
            areaPath += `${areaPath === "" ? "M" : "L"}${x} ${minY}`;
            // no need to use "M" for the reverse, as this will be concatenated to a path with that
            reverseAreaPath = `L${x} ${maxY}` + reverseAreaPath;
          }
        }
      }

      // build all bands (O(n))
      for (let i = 0; i < series.data.length; i++) {
        const bandColour = scaledSeries[i].colour;
        // if we don't have a band yet, mark this as the current one (we won't actually record this band until the colour
        // changes or the loop finishes)
        if (currentBandColour === undefined) {
          currentBandColour = bandColour;
        } else if (bandColour !== currentBandColour) {
          // if the band changed, record the current band path
          recordBand();
          // add the previous point to the new band too, to join up the band areas
          addScaledPoint(i - 1);
        }

        // add the current point to the band
        addScaledPoint(i);
      }
      // make sure the last band is recorded
      recordBand();

      return bandPaths;
    });
  }

  /**
   * Gets coloured, scaled points in offset space to display on top of the line for each series.
   *
   * ![Point](media://colouredpoint.png)
   *
   * @returns Array of points arrays for each series.
   * @category Vue Computed
   */
  get points(): ColouredDataPoint[][] {
    const allSeries = this.nonReactiveSeries;
    const width = this.sizes.width;
    const seriesKey = this.seriesKey;

    // see paths() about for what this if statement does
    // noinspection JSIncompatibleTypesComparison
    if (width === 0 || allSeries === undefined || seriesKey === -1) return [];

    return allSeries.map((series, seriesIndex) => {
      return series.data.map((point) => ({
        x: this.scaleAndTransformX(point.x, this.syncedXZoom, seriesIndex),
        y: this.scaleY(normY(point.y), seriesIndex),
        // hexColour: hexColoursForColourString(point.colour)[0]
        hexColour: series.lineColour || bandColours["default"][0],
      }));
    });
  }

  /**
   * Gets a list of scaled annotations with coordinates in offset space instead of series space (as they are in when
   * passed via the [[annotations]] prop.
   *
   * If [[disablePointAnnotations]] is false, this list will also include
   * annotations that have been defined on points in [[series]]. This is an expensive operation as all series must
   * first be filtered to find annotations, then mapped to produce annotations in the correct format, then these must
   * be concatenated ($O(n)$) to the annotations list. Finally, all annotations must be scaled correctly. All this
   * tends towards $O(n^2)$ in the worst case.
   *
   * @returns Array of all annotations to display on the chart.
   * @category Vue Computed
   */
  get scaledAndTransformedAnnotations() {
    // get a list of all annotations to scale
    const annotationsList = [...this.annotations];
    if (!this.disablePointAnnotations) {
      // meaningless statement that will never return, but marks the getter as depending on seriesKey
      // noinspection JSIncompatibleTypesComparison
      if (this.seriesKey === -1 || this.nonReactiveSeries === undefined)
        return [];

      this.nonReactiveSeries.forEach((series) => {
        // find all of the annotations in the data series to add
        // @ts-ignore
        const pointAnnotations: ChartAnnotation[] = series.data // O(n)
          .filter((v) => v.annotation)
          .map((v) => ({
            // copy all the annotation data
            ...v.annotation,
            // ...normalising the x and y coordinates
            x: v.x,
            y: normY(v.y),
          }));
        annotationsList.concat(pointAnnotations); // likely O(n), unlikely to be linked lists
      });
    }

    // scale the positions of annotations to chart space
    // FIXME: using 0 as the series index is incorrect here, when going through and tidying up this class change this
    //  (annotations should be using the correct scales if they're point based)
    return annotationsList.map((a) => ({
      // O(n)
      ...a,
      key: `${a.type}-${a.x}-${a.y}`,
      x:
        a.x === undefined
          ? undefined
          : this.scaleAndTransformX(a.x, this.syncedXZoom, 0),
      x2:
        a.x2 === undefined
          ? undefined
          : this.scaleAndTransformX(a.x2, this.syncedXZoom, 0),
      y: a.y === undefined ? undefined : this.scaleY(a.y, 0),
      y2: a.y2 === undefined ? undefined : this.scaleY(a.y2, 0),
    }));
  }

  /**
   * Gets [[scaledAndTransformedAnnotations]] that should be displayed behind the chart (currently
   * just "background-rect").
   * @returns Scaled annotations to display behind the chart.
   * @category Vue Computed
   */
  get backgroundAnnotations() {
    return this.scaledAndTransformedAnnotations.filter(
      (a) => a.type === "background-rect"
    );
  }

  /**
   * Gets [[scaledAndTransformedAnnotations]] that should be displayed in front of the chart.
   * @returns Scaled annotations to display in front of the chart.
   * @category Vue Computed
   */
  get foregroundAnnotations() {
    return this.scaledAndTransformedAnnotations.filter(
      (a) => a.type !== "background-rect"
    );
  }

  /**
   * Gets the position of the crosshair in offset space (so that it can be rendered on the SVG) or null if the chart
   * hasn't been hovered yet.
   * @returns Position of the crosshair or null
   * @category Vue Computed
   */
  get scaledAndTransformedCursorPoint(): ChartDataPoint | null {
    return this.cursorPoint
      ? {
          x: this.scaleAndTransformX(
            this.cursorPoint.x,
            this.syncedXZoom,
            this.series.length - 1
          ),
          y: this.scaleY(normY(this.cursorPoint.y), this.series.length - 1),
        }
      : null;
  }

  /**
   * Window `resize` event listener which updates the stored [[sizes]] when the window is resized so that scaling
   * is correct.
   */
  recalculateSizes() {
    this.sizes = {
      width: this.chart.clientWidth,
      height: this.chart.clientHeight,
    };
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Registers window `resize` listener and sets up mouse/touch event handling (taps, pans, pinches) with
   * [Hammer.js](https://hammerjs.github.io/).
   * @category Vue Lifecycle
   */
  mounted() {
    // register window size listener
    window.addEventListener("resize", this.recalculateSizes);
    this.recalculateSizes();

    // initialise mouse/touch stuff
    this.point = this.chart.createSVGPoint(); // used as something to transform later
    this.hammer = new Hammer(this.chart); // attached to the chart area

    // disable unused recognisers
    this.hammer.get("doubletap").set({ enable: false });
    this.hammer.get("press").set({ enable: false });
    this.hammer.get("swipe").set({ enable: false });
    this.hammer.get("pinch").set({ enable: true });

    // modify listener options, we only need horizontal panning
    this.hammer
      .get("pan")
      .set({ threshold: 10, direction: Hammer.DIRECTION_HORIZONTAL });

    // register listeners
    this.hammer.on("tap", this.handleTap);
    this.hammer.on("panstart", this.handlePanStart);
    this.hammer.on("pan", this.handlePan);
    this.hammer.on("pinch", this.handlePinch);
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Removes window `resize` listener and cleans up [Hammer.js](https://hammerjs.github.io/).
   * @category Vue Lifecycle
   */
  beforeDestroy() {
    // unregister window size listener
    window.removeEventListener("resize", this.recalculateSizes);

    // cleanup mouse/touch stuff
    if (this.hammer) {
      this.hammer.destroy();
    }
  }

  /**
   * Event handler for tapping/clicking on the screen. Updates the target cursor position and tries to find the new
   * closest point.
   * @param e - Event object containing the position of the tap/click
   */
  handleTap(e: HammerInput) {
    // change where we want the hovered point to be as close to
    this.targetCursorPageX = e.center.x;
    this.targetCursorPageY = e.center.y;
    // update the position of the hovered point
    this.onTargetCursorPagePositionChanged();
  }

  /**
   * Event handler for moving the mouse on the screen. Updates the target cursor position and tries to find the new
   * closest point. Will ignore the event if the user has a button clicked and is therefore transforming the chart.
   * @param e - Event object containing the new position of the mouse on the screen
   */
  handleMouseMove(e: MouseEvent) {
    // change where we want the hovered point to be as close to
    this.targetCursorPageX = e.pageX;
    this.targetCursorPageY = e.pageY;
    // update the position of the hovered point
    // - do this if we're not transformable
    // - or if we are transformable, but we're not pressing any buttons (not transforming)
    if (e.buttons === 0 || !this.transformable) {
      this.onTargetCursorPagePositionChanged();
    }
  }

  /**
   * Initial [[syncedXTranslate]] at the start of panning so we can translate according to this. Hammer only
   * gives us deltas so we need something to add to get new translate values.
   */
  syncedXTranslateAtPanStart: number = 0;

  /**
   * Event handler for when the user starts to pan the chart. Records [[syncedXTranslateAtPanStart]].
   * @param _e - Ignored
   */
  handlePanStart(_e: HammerInput) {
    this.syncedXTranslateAtPanStart = this.syncedXTranslate;
  }

  /**
   * Event handler for when the user is panning the chart. Calculates new clamped [[syncedXTranslate]] values and emits
   * `input-x-translate` events with these. If the user has the shift key down, the translation delta will be doubled.
   * Will also update the cursor target position to the position of this event.
   * @param e - Event object containing the change in X and center position
   */
  handlePan(e: HammerInput) {
    const initialXTranslate = this.syncedXTranslate;
    if (this.transformable) {
      // if this chart is transformable, translate the graph on pan (emit the new value, the parent component
      // is responsible for storing translates as they might want to do something with them)
      // clamp the new translate value to always keep the chart visible
      this.syncedXTranslate = this.clampsyncedXTranslate(
        this.syncedXTranslateAtPanStart +
          e.deltaX * (e.srcEvent.shiftKey ? 2 : 1)
      );
    }

    // update the cursor position if not transformable (or the transform didn't change)
    if (!this.transformable || initialXTranslate === this.syncedXTranslate) {
      this.targetCursorPageX = e.center.x;
      this.targetCursorPageY = e.center.y;
      this.onTargetCursorPagePositionChanged();
    }
  }

  /**
   * Event handler for the scrolling the mouse wheel. Delegates to [[handleScaleDelta]] to zoom the chart.
   * @param e - Event object containing amount scrolled by (a fraction of this will be used to control zoom amount)
   *            and the position about which the scroll took place.
   */
  handleWheel(e: WheelEvent) {
    // called whenever the user scrolls with the mouse wheel
    this.handleScaleDelta(e, -e.deltaY * 0.01, e.offsetX);
  }

  /**
   * Event handler for Hammer pinch-to-zoom events. Delegates to [[handleScaleDelta]] to zoom the chart.
   * @param e - Event object containing the amount zoomed by and the position about which the zoom took place.
   */
  handlePinch(e: HammerInput) {
    // calculates the offset from the top-left corner of the chart of the center of the pinch
    const offset = this.calculateOffsetCoordinates(e.center);
    // on pinch start, scale is always 1, so the change is scale - 1
    this.handleScaleDelta(e, (e.scale - 1) * 0.1, offset.x);
  }

  /**
   * Handler that performs zooming. Called by [[handleWheel]] and [[handlePinch]]. Will zoom the chart whilst
   * attempting to keep the data point the zoom is about in the same position.
   * @param e - Event object that triggered this zoom. Passed so that `preventDefault()` can be called on it if
   *            zooming is enabled.
   * @param scaleDelta - Amount to zoom by. Will be scaled based on [[xZoomMultiplier]] and the current
   *                     [[syncedXZoom]].
   * @param aboutX - X-coordinate to center the zoom on, trying to keep the data point there in the same position.
   */
  handleScaleDelta(
    e: WheelEvent | HammerInput,
    scaleDelta: number,
    aboutX: number
  ) {
    // apply some scaling to the zoom amount based on the multiplier and the current zoom
    scaleDelta = (scaleDelta / this.xZoomMultiplier) * this.syncedXZoom;

    // check if custom scale handler is registered, if it is, stop here and call it instead
    // TODO: this can probably be removed now, don't think it's being used
    if (this.$listeners.scale) {
      e.preventDefault();
      this.$emit("scale", scaleDelta);
      return;
    }

    // if transformations are disabled, don't do anything
    if (!this.transformable) return;
    // otherwise, stop the event scrolling the page
    e.preventDefault();

    const lastSeriesIndex = this.series.length - 1;

    // work out the chart x coordinate at the about coordinate
    const pointAtAboutX = this.inverseTransformAndDescaleX(
      aboutX,
      lastSeriesIndex
    );
    // calculate the new x zoom based on the delta
    const newZoom = Math.max(
      this.minXZoom,
      Math.min(this.maxXZoom, this.syncedXZoom + scaleDelta)
    );
    // calculate the screen coordinates of the chart x coordinate using the new zoom
    const newAboutX = this.scaleAndTransformX(
      pointAtAboutX,
      newZoom,
      lastSeriesIndex
    );
    // work out the change between these coordinates, so it can be applied to the translate to keep the viewport
    // fixed on the about x coordinate
    const xChange = newAboutX - aboutX;

    // emit the new values so they can be stored by parent components
    this.syncedXZoom = newZoom;
    this.syncedXTranslate = this.clampsyncedXTranslate(
      this.syncedXTranslate - xChange,
      newZoom
    );

    // once all props changes have propagated, update the hovered point
    // (the callback inside $nextTick is called once vue has updated the DOM in response to data changes)
    this.$nextTick(() => this.onTargetCursorPagePositionChanged());
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Called by parent components to move the viewport to the end of the chart, with the specified zoom value
   * *(e.g. used by the trends graph when selecting timescales)*. Will emit `input-x-zoom` and `input-x-translate`
   * events with the required transform to pan to the end.
   * @param usingZoom - Target [[syncedXZoom]]
   */
  panToEnd(usingZoom: number) {
    this.syncedXZoom = usingZoom;
    this.syncedXTranslate =
      -(usingZoom - 1) * this.sizes.width - clampedTranslatePadding;
  }

  /**
   * Watches the series and scales, ensuring whenever those change, the chart's crosshair moves to the correct
   * location. This uses the reactive [[seriesKey]] as an alias for [[series]] to improve performance.
   * @category Vue Watch
   */
  @Watch("scales")
  @Watch("seriesKey") // essentially an alias for series, because of the non-reactive series watcher
  onDataChanged() {
    this.cursorPoint = null;
    this.onTargetCursorPagePositionChanged();
  }

  /**
   * Converts the given coordinates in screen space to offset space using the inverse of [[chart]]s transformation
   * matrix.
   * @param x - Screen space X-coordinate
   * @param y - Screen space Y-coordinate
   * @returns XY-coordinates in offset space
   */
  calculateOffsetCoordinates({ x, y }: { x: number; y: number }) {
    // noinspection JSIncompatibleTypesComparison
    if (this.point === undefined) return { x: 0, y: 0 };
    this.point.x = x;
    this.point.y = y;
    if (!this.$refs.chart) return { x: 0, y: 0 };
    // get the chart's matrix and transform the coordinates by it
    const screenCTM = this.chart.getScreenCTM();
    if (screenCTM === null) return { x: 0, y: 0 };
    const offset = this.point.matrixTransform(screenCTM.inverse());
    return { x: offset.x, y: offset.y };
  }

  /* eslint-disable tsdoc/syntax */
  /**
   * Called whenever the target cursor position has changed and the crosshair position needs to be recalculated.
   * This method performs an $O(\log n)$ binary search to find the closest point in the top series to the target
   * coordinates.
   */
  /* eslint-enable tsdoc/syntax */
  onTargetCursorPagePositionChanged() {
    const allSeries = this.nonReactiveSeries;

    // check we've got a SVGPoint for matrix transforms and narrow the types
    // of other instance fields
    // noinspection JSIncompatibleTypesComparison
    if (
      this.point === undefined ||
      this.targetCursorPageX === undefined ||
      this.targetCursorPageY === undefined ||
      allSeries === undefined
    ) {
      return;
    }

    // get and check the last series (the one used for hover points) is defined
    const lastSeriesIndex = this.series.length - 1;
    const lastSeries = allSeries[allSeries.length - 1];
    // noinspection JSIncompatibleTypesComparison
    if (lastSeries === undefined) return;
    const series = lastSeries.data;

    // get the X-position of the mouse relative to the top-left of the graph
    if (!this.$refs.chart) return;
    const screenCTM = this.chart.getScreenCTM();
    if (screenCTM === null) return;
    const offset = this.calculateOffsetCoordinates({
      x: this.targetCursorPageX,
      y: this.targetCursorPageY,
    });
    const offsetX = offset.x;
    const targetX = this.inverseTransformAndDescaleX(offsetX, lastSeriesIndex);

    if (series.length === 0) return;

    // perform a binary search(ish) for the closest point to the mouse
    // (allowed as we've assumed the data is sorted)
    let startIndex = 0;
    let endIndex = series.length;
    do {
      const midIndex = Math.floor((startIndex + endIndex) / 2);
      const x = series[midIndex].x;
      if (targetX >= x) {
        startIndex = midIndex;
      } else {
        endIndex = midIndex;
      }
    } while (endIndex - startIndex > 1);

    const startPoint = series[startIndex];
    const endPoint = series[startIndex + 1];

    // check if this is the closest point or if it's the next one
    // (or just pick the start point if the endPoint isn't defined)
    const potentialCursorPoint =
      !endPoint || targetX - startPoint.x < endPoint.x - targetX
        ? startPoint
        : endPoint;

    // check if the point is in the x range too before updating
    const scaledTransformedX = this.scaleAndTransformX(
      potentialCursorPoint.x,
      this.syncedXZoom,
      lastSeriesIndex
    );
    if (0 <= scaledTransformedX && scaledTransformedX <= this.sizes.width) {
      (potentialCursorPoint as any).hexColour = hexColoursForColourString(
        potentialCursorPoint.colour
      )[0];
      this.cursorPoint = potentialCursorPoint;
    }
  }
}
