/**
 * Approximation of the [percent point function](https://en.wikipedia.org/wiki/Quantile_function) (inverse of the
 * cumulative distribution function) from a list of percentiles calculated by linearly interpolating between the
 * percentiles (output is clamped between 0 and 100).
 *
 * #### Percentiles
 *
 * The percentiles are stored as JSON files in this directory. These are the distributions the percentiles are taken
 * from:
 *
 * ![](media://distributionendtidalco2.png)<br>
 * ![](media://distributionrespiratoryrate.png)<br>
 * ![](media://distributionmetabolicburden.png)<br>
 * ![](media://distributionobstructiveindex.png)
 *
 * @param percentiles - Percentiles of the distribution
 * @param value - Value in the distribution
 * @returns Position of the value in the distribution
 */
export function percentPointFunction(
  percentiles: number[],
  value: number
): number {
  // check percentiles array length is valid
  if (percentiles.length !== 101) {
    throw Error(`invalid percentiles, got ${percentiles.length}, expected 101`);
  }

  // check if this is less than the first percentile, in which case just return 0%
  if (value < percentiles[0]) {
    return 0;
  }

  // otherwise find the first percentile that is greater than the value
  for (let i = 1; i < percentiles.length; i++) {
    if (value < percentiles[i]) {
      // ...and linearly interpolating between the start and end values
      const start = percentiles[i - 1];
      const end = percentiles[i];
      const t = (value - start) / (end - start);
      // assert(0 <= t <= 1)
      // i is the end % of this range so subtract 1% first
      return i - 1 + t;
    }
  }

  // if the value was greater than the last percentile, just return 100%
  return 100;
}
