
import Vue from "vue";
import Component from "vue-class-component";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";

/**
 * Panel for showing privacy policy for the dashboard. Linked to via [[PanelFooter]]s.
 */
@Component({
  name: "panel-privacy",
  components: {
    PanelFooter,
  },
})
export default class PanelPrivacy extends Vue {
  /**
   * Gets the title of the application from the configuration.
   * @returns title
   */
  get title(): string {
    return "N-Tidal Dashboard";
  }
}
