import { formatDate } from "@/components/graphics/date";
import { ChartCapnogramWithMetadata, fetchCapnogram } from "./capnogram";
import { ChartAverageWaveform, fetchWaveform } from "./waveform";
import { DeviceSummaryStats, fetchSummaryStats } from "./summaryStats";
import { DeviceTrends, fetchTrends } from "./trends";
import APIClient from "@/store/api";
import { APICapnogramResponse } from "@/store/types";
import Pusher, { Channel } from "pusher-js/with-encryption";
import errorReporter from "@/errors";
import store from "@/store";
import { ntdPrefix, ActionTypes } from "../internal/modules/ntd";

/**
 * Context to be passed to all `fetch*` functions. See [[fetchCapnogram]], [[fetchSummaryStats]], [[fetchTrends]]
 * and [[fetchWaveform]]. These functions extract the required data from the context and may make more API requests
 * using the [[client]].
 */
export interface DeviceDataFetchContext {
  /** Client to make API requests with */
  client: APIClient;
  /** DUID selected by the user. May not be defined if not filtering by DUIDs (just UDIs). */
  duid?: string;
  /** Capnogram response object: along with the raw capnogram, contains parameter values */
  capnogram: APICapnogramResponse;
  /**
   * Previous capnogram response object: along with the raw capnogram, contains parameter values. May not be defined
   * if there hasn't been a successfully parameterised capnogram before this.
   */
  previousCapnogram?: APICapnogramResponse;
}

/**
 * Data object sent via the [[DeviceDataFetcher.onData | onData]] callback containing all data required to render
 * the raw capnogram, summary stats, average waveform and trends.
 */
export interface DeviceData {
  /** Capnogram identifier the data is associated with */
  deviceCapnogramId: string;
  /** DUID the data is associated with */
  deviceDUID: string;
  /** Summary statistics of the latest capnogram (includes previous values/changes if defined) */
  deviceSummaryStats: DeviceSummaryStats;
  /**
   * Average waveform to be displayed in the waveform topology section. This series data array contains the
   * previous waveform first if one exists, followed by the current. See [[DeviceWaveform]] for more details.
   */
  deviceWaveform: ChartAverageWaveform;
  /** Processed raw capnogram with annotations for invalid breaths */
  deviceCapnogram: ChartCapnogramWithMetadata;
  /** Start time of the capnogram, formatted using [[formatDate]] (with full length month names) */
  deviceCapnogramStartTime: string;
  /** Data of previous summary statistics of capnograms */
  deviceTrends: DeviceTrends;
}

export interface UploadLifecycle {
  event: {
    stage: "RECEIVE" | "DECODE" | "PROCESS" | "DIAGNOSE";
    state: "STARTED" | "SUCCEEDED" | "FAILED";
  };
  uploadId: null | string;
  duid: null | string;
  capnogramId: null | string;
  handsetId: string;
  serverTime: string;
  capturedAt: null | string;
  errors: Array<{ message: string; code: string }>;
}

export type DeviceDataFetcherDataCallback = (
  data: DeviceData | null,
  fromPusher: boolean
) => void;

export type DeviceDataFetcherProgressCallback = (
  progress: number | null
) => void;
/**
 * Class responsible for watching updates in the lifecycle of processing an upload
 */
export class UploadLifecycleWatcher {
  private channel: Channel;

  constructor(handset_udi: string, pusher: Pusher) {
    const validUdi = handset_udi.replace(/\(/g, "-=").replace(/\)/g, "=-");
    const channelName = "private-encrypted-handset-" + validUdi;

    // eslint-disable-next-line no-console
    console.log(`Subscribing to ${channelName} channel...`);
    this.channel = pusher.subscribe(channelName);
    this.channel.bind("upload_lifecycle", (data: UploadLifecycle) => {
      store.dispatch(
        ntdPrefix(ActionTypes.UPLOAD_LIFECYCLE_NOTIFICATION_RECEIVED),
        data
      );
    });
  }

  dispose() {
    this.channel.unsubscribe();
    // eslint-disable-next-line no-console
    console.log(`Unsubscribed from ${this.channel.name}`);
  }
}

/**
 * Class responsible for watching and fetching a DUIDs data to be displayed in the detail view. This class is also used
 * in user training to watch for capnograms to display. Subscribes to a [Pusher Channel](https://pusher.com/channels)
 * with a name derived from the passed [[udi]], which emits events when new capnograms arrive. When a new capnogram
 * arrives, builds a [[DeviceDataFetchContext]] object and calls all the `fetch*` functions in this directory. See
 * [[fetchCapnogram]], [[fetchSummaryStats]], [[fetchTrends]] and [[fetchWaveform]]. If we're not just looking for
 * new data (i.e. we want to fetch current data for the detail view), this class will also fetch a list of existing
 * capnograms and process the latest one (potentially using the previous to calculate changes in parameters).
 *
 * ![](media://fetcher.png)
 */
export class DeviceDataFetcher {
  /** Client to use for making API requests */
  private readonly client: APIClient;
  /** Optional DUID to filter incoming data on */
  private readonly duid?: string;
  /** Optional UDI to filter incoming data on (if this is specified, the UDI's pusher channel will be subscribed to) */
  private readonly udi?: string;
  /** Function to call with new data (or null if there is no data yet) */
  private readonly onData: DeviceDataFetcherDataCallback;
  /** Function to call with data progress percent (or null if the new data has been received) */
  private readonly onProgress?: DeviceDataFetcherProgressCallback;
  /** Date of the capnogram that was last fetched or `null` if none have been fetched yet */
  private lastDate: Date | null;
  /**
   * Last capnogram id that was fetched, used when capnograms arrive via the pusher channel as messages don't include
   * the previous
   */
  private _lastFetchedCapnogramId?: string;
  /** Currently active [Pusher Channel](https://pusher.com/channels) */
  private readonly _channel?: Channel;

  /**
   * @param client - Client to use for making API requests
   * @param pusher - [Pusher](https://github.com/pusher/pusher-js) instance to use when subscribing to channel
   * @param duid - DUID to filter incoming events on. Required when fetching existing data too.
   * @param udi - UDI to derive channel name from
   * @param onData - Callback function called with new data
   * @param onProgress - Callback function called with data progress percent
   * @param newDataOnly - Whether to skip fetching existing data and only subscribe to the channel
   */
  constructor({
    client,
    pusher,
    duid,
    udi,
    onData,
    onProgress,
    newDataOnly = false,
  }: {
    client: APIClient;
    pusher: Pusher;
    duid?: string;
    udi?: string;
    onData: DeviceDataFetcherDataCallback;
    onProgress?: DeviceDataFetcherProgressCallback;
    newDataOnly?: boolean;
  }) {
    this.client = client;
    this.duid = duid;
    this.udi = udi;
    this.onData = onData;
    this.onProgress = onProgress;
    this.lastDate = newDataOnly ? new Date() : null;

    if (!newDataOnly) {
      // noinspection JSIgnoredPromiseFromCall
      this._checkForNewCapnograms();
    }

    this._lastFetchedCapnogramId = undefined;
    if (udi) {
      // brackets aren't valid in channel names so they're replace
      const validUdi = udi.replace(/\(/g, "-=").replace(/\)/g, "=-");
      const channelName = "private-encrypted-handset-" + validUdi;

      // eslint-disable-next-line no-console
      console.log(`Subscribing to ${channelName} channel...`);
      this._channel = pusher.subscribe(channelName);
      this._channel.bind(
        "capnogram",
        (data: { duid: string; capnogram_id: string }) => {
          // eslint-disable-next-line no-console
          console.log('Received "capnogram" event', data);

          if (data.duid === "") {
            // eslint-disable-next-line no-console
            console.error(
              "Capnogram from pusher is not allocated to a duid",
              data.capnogram_id
            );
          }

          // only accept events if we're not filtering on DUIDs, or the DUID matches
          if (!this.duid || data.duid === this.duid) {
            // noinspection JSIgnoredPromiseFromCall
            this._updateData(
              data.capnogram_id,
              this._lastFetchedCapnogramId,
              true
            );
          }
          // reset progress now that we've got new data
          this.onProgress && this.onProgress(null);
        }
      );

      // subscribe to progress updates if we're requesting them
      if (this.onProgress) {
        this._channel.bind(
          // TODO: this event name/payload format is tbd
          "capnogram-progress",
          (data: { progress: number }) => {
            // eslint-disable-next-line no-console
            console.log('Received "capnogram-progress" event', data);
            this.onProgress && this.onProgress(data.progress);
          }
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.log("Skipping channel subscription...");
    }
  }

  /**
   * Checks for existing capnograms associated with the [[duid]]. Calls [[_updateData]] with the latest capnogram
   * IDs to actually do the fetching/processing. If there aren't any capnograms, will call [[onData]] with `null`.
   * This function will look at all capnograms for the latest, but only those without processing errors for previous
   * capnograms to compare against.
   */
  private async _checkForNewCapnograms() {
    // make sure we have a duid before proceeding
    if (!this.duid) return;

    // get list of capnogram information
    const capnogramsRes = await this.client.getCapnogramsForDUID(this.duid);
    if (capnogramsRes.data) {
      const capnograms = capnogramsRes.data
        .map((capnogram) => ({
          ...capnogram,
          // parse the capnogram date
          capnogramdt: new Date(capnogram.capnogram_dt),
        }))
        .sort((a, b) => b.capnogramdt.getTime() - a.capnogramdt.getTime());

      // check there are capnograms
      if (capnograms.length === 0) {
        // notify the user if there is no data
        this.onData(null, false);
      } else {
        if (
          // check this is either the first capnogram, or later than the last
          this.lastDate === null ||
          this.lastDate < capnograms[0].capnogramdt
        ) {
          // find the last capnogram that had data
          const lastDataCapnogram = capnograms
            // ignore the first capnogram (the one we're displaying)
            .slice(1)
            // find the next capnogram with data (capnograms are sorted by date so the first one is the previous)
            .find(
              (capnogram) =>
                capnogram.data_par_ver &&
                !capnogram.data_par_ver.startsWith("ERROR")
            );

          await this._updateData(
            capnograms[0].id,
            lastDataCapnogram ? lastDataCapnogram.id : undefined,
            false
          );
        }
      }
    }
  }

  /**
   * Fetches and processes the passed capnogram, optionally comparing it against the passed previous capnogram. Whilst
   * this class doesn't store the previous capnogram, the [[APIClient]] contains a [[capnocache]] that will avoid
   * fetching the same capnogram twice from the server. Once processing is complete, this method will call the
   * [[onData]] callback with the data.
   * @param capnogramId - UUID of the current capnogram
   * @param previousCapnogramId - UUID of the previous capnogram to compare against
   * @param fromPusher - Whether this update originates from a Pusher channels event, if it does, we'll want to update
   * the DUID in the list view
   */
  private async _updateData(
    capnogramId: string,
    previousCapnogramId?: string,
    fromPusher: boolean = false
  ) {
    try {
      this._lastFetchedCapnogramId = capnogramId;

      // eslint-disable-next-line no-console
      console.log(
        `Capnogram for ${
          this.duid || "UNKNOWN"
        }:\nCurrent:  ${capnogramId}\n%cPrevious: ${previousCapnogramId}`,
        "color: #AAAAAA"
      );

      // fetch data
      const capnogramRes = await this.client.getCapnogram(capnogramId);
      if (!capnogramRes.data) return;
      const capnogramStartDate = new Date(capnogramRes.data.capnogram_dt);
      this.lastDate = capnogramStartDate;
      const capnogramStartTime = formatDate(capnogramStartDate);

      // build context to pass to the rest of the data fetching functions
      const ctx: DeviceDataFetchContext = {
        client: this.client,
        duid: this.duid,
        capnogram: capnogramRes.data,
      };

      // fetch previous data if any exists (will use the cache if it has already been fetched and the user
      // hasn't logged out)
      if (previousCapnogramId) {
        const previousCapnogramRes = await this.client.getCapnogram(
          previousCapnogramId
        );
        if (previousCapnogramRes.data) {
          ctx.previousCapnogram = previousCapnogramRes.data;
        }
      }

      const capnogram = await fetchCapnogram(ctx);
      const waveform = await fetchWaveform(ctx); // currently, must be fetched after capnogram
      const summaryStats = await fetchSummaryStats(ctx);
      const trends = await fetchTrends(ctx);

      // call data callback with new data
      this.onData(
        {
          deviceDUID: this.duid || "UNKNOWN",
          deviceSummaryStats: summaryStats,
          deviceWaveform: waveform,
          deviceCapnogram: capnogram,
          deviceCapnogramStartTime: capnogramStartTime,
          deviceTrends: trends,
          deviceCapnogramId: capnogramId,
        },
        fromPusher
      );
    } catch (e) {
      errorReporter.report(e as Error);
      // eslint-disable-next-line no-console
      console.error("Error updating data: ", e);
    }
  }

  // noinspection JSUnusedGlobalSymbols
  /** Unsubscribes from the Pusher Channel, if one was subscribed to. */
  dispose() {
    // tidy up the channel if there was one
    if (this._channel) {
      // eslint-disable-next-line no-console
      console.log(`Unsubscribing from channel...`);
      this._channel.unsubscribe();
    }
  }
}
