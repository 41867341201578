import { Commit, GetterTree } from "vuex";

import {
  ChartAnnotation,
  autoScale,
  emptyChart,
  normY,
  scaleValue,
} from "@/components/graphics/utils";
import { HttpResponseError, NotFoundError } from "@/errors";
import { apiClient } from "@/store";
import { UploadLifecycle } from "@/store/fetcher";
import { ChartCapnogram } from "@/store/fetcher/capnogram";
import {
  CapnogramComputedFeatures,
  DiagnosticResult,
  CapnogramRaw,
  HandsetV2,
} from "@/store/types";
import { formatDate } from "@/components/graphics/date";
import {
  ChartAverageWaveform,
  DeviceWaveformBottomAnnotation,
  THRESHOLD,
} from "@/store/fetcher/waveform";
import { getResultCutOffTime } from "@/config";

interface EnrichedCapnogram {
  id: string;
  raw: CapnogramRaw | null;
  computedFeatures: CapnogramComputedFeatures | null;
  diagnosticResult: DiagnosticResult | null;
}

interface HandsetTestResults {
  testIdentifier: string;
  capturedAt: string;
  testOutcome: "Completed" | "Failed" | "Pending";
  capnogramId: string;
}

export interface HandsetTestResultsParsed
  extends Omit<HandsetTestResults, "capturedAt"> {
  capturedAt: Date | null;
}

export interface UploadLifecycleParsed
  extends Omit<UploadLifecycle, "capturedAt" | "serverTime"> {
  capturedAt: Date;
  serverTime: Date;
}

export interface LocalState {
  capnograms: EnrichedCapnogram[];
  handsets: HandsetV2[] | null;
  upload_lifecycle_notification_history: UploadLifecycleParsed[];
  handsetTestResultsParsed: {
    [handsetUdi: string]: HandsetTestResultsParsed[];
  };
  breathRecordFailed: boolean;
}

interface ContainsCapnogramId {
  capnogramId: string;
}

const moduleName = "ntd";

const enum ActionTypes {
  UPLOAD_LIFECYCLE_NOTIFICATION_RECEIVED = "UPLOAD_LIFECYCLE_NOTIFICATION_RECEIVED",
  GET_CAPNOGRAM_RAW = "GET_CAPNOGRAM_RAW",
  GET_CAPNOGRAM_COMPUTED_FEATURES = "GET_CAPNOGRAM_COMPUTED_FEATURES",
  GET_CAPNOGRAM_DIAGNOSTIC_RESULT = "GET_CAPNOGRAM_DIAGNOSTIC_RESULT",
  GET_HANDSETS = "GET_HANDSETS",
  GET_HANDSET_TEST_RESULTS = "GET_HANDSET_TEST_RESULTS",
  REPORT_BREATH_RECORD_FAILED_STATUS = "REPORT_BREATH_RECORD_FAILED_STATUS",
}

const enum MutationTypes {
  ADD_UPLOAD_LIFECYCLE_NOTIFICATION = "ADD_UPLOAD_LIFECYCLE_NOTIFICATION",
  SET_CAPNOGRAM_RAW = "SET_CAPNOGRAM_RAW",
  SET_CAPNOGRAM_COMPUTED_FEATURES = "SET_CAPNOGRAM_COMPUTED_FEATURES",
  SET_CAPNOGRAM_DIAGNOSTIC_RESULT = "SET_CAPNOGRAM_DIAGNOSTIC_RESULT",
  SET_HANDSETS = "SET_HANDSETS",
  SET_HANDSET_TEST_RESULTS = "SET_HANDSET_TEST_RESULTS",
  SET_BREATH_RECORD_FAILED = "REPORT_BREATH_RECORD_FAILED",
  RESET_STATE = "RESET_STATE",
}

const enum GetterTypes {
  GET_CAPNOGRAM_BY_ID = "GET_CAPNOGRAM_BY_ID",
  GET_CAPNOGRAM_START_TIME_BY_ID = "GET_CAPNOGRAM_START_TIME_BY_ID",
  GET_CHART_CAPNOGRAM_BY_ID = "GET_CHART_CAPNOGRAM_BY_ID",
  GET_AVERAGE_WAVEFORM_CHART_BY_CAPNOGRAM_ID = "GET_AVERAGE_WAVEFORM_CHART_BY_CAPNOGRAM_ID",
  GET_HANDSET_BY_UDI = "GET_HANDSET_BY_UDI",
  GET_HANDSET_BY_CAPNOGRAM_ID = "GET_HANDSET_BY_CAPNOGRAM_ID",
  HANDSETS_SORTED_BY_SERIAL_NUMBER = "HANDSETS_SORTED_BY_SERIAL_NUMBER",
  MOST_RECENT_LIFE_CYCLE_NOTIFICATION = "MOST_RECENT_LIFE_CYCLE_NOTIFICATION",
  TESTS_SORTED_BY_CAPTURED_AT = "TESTS_SORTED_BY_CAPTURED_AT",
}

/** These are the interfaces of the getters from the point of view of other
 * getters. The getters interface would include state and getter externally. */
interface InternalGetters {
  [GetterTypes.GET_CAPNOGRAM_BY_ID](id: string): EnrichedCapnogram | null;
  [GetterTypes.GET_CAPNOGRAM_START_TIME_BY_ID](id: string): string | null;
  [GetterTypes.GET_CHART_CAPNOGRAM_BY_ID](id: string): ChartCapnogram | null;
  [GetterTypes.GET_AVERAGE_WAVEFORM_CHART_BY_CAPNOGRAM_ID](
    capnogramId: string
  ): ChartAverageWaveform | null;
  [GetterTypes.GET_HANDSET_BY_UDI](id: string): HandsetV2 | null;
  [GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID](
    capnogramId: string
  ): HandsetV2 | null;
  [GetterTypes.HANDSETS_SORTED_BY_SERIAL_NUMBER]: HandsetV2[] | null;
  [GetterTypes.MOST_RECENT_LIFE_CYCLE_NOTIFICATION]: UploadLifecycle | null;
  [GetterTypes.TESTS_SORTED_BY_CAPTURED_AT](
    id: string
  ): HandsetTestResultsParsed[] | null;
}

function ntdPrefix(name: ActionTypes | MutationTypes | GetterTypes) {
  return `${moduleName}/${name}`;
}

const emptyCapnogram: EnrichedCapnogram = {
  id: "",
  raw: null,
  computedFeatures: null,
  diagnosticResult: null,
};

const genericCapnogramMutation =
  <
    T extends (CapnogramRaw | CapnogramComputedFeatures | DiagnosticResult) &
      ContainsCapnogramId
  >(
    prop: keyof EnrichedCapnogram
  ) =>
  (state: LocalState, payload: T) => {
    const { capnogramId, ...payloadWithoutCapnogramId } = payload;

    const index = state.capnograms.findIndex((c) => c.id === capnogramId);

    if (index === -1) {
      // create new enriched capnogram in state
      state.capnograms.push({
        ...emptyCapnogram,
        id: capnogramId,
        [prop]: payloadWithoutCapnogramId,
      });
    } else {
      // update an existing enriched capnogram
      state.capnograms[index] = {
        ...state.capnograms[index],
        [prop]: payloadWithoutCapnogramId,
      };
    }
  };

const genericCapnogramAction =
  (
    field: keyof EnrichedCapnogram,
    route: string,
    mutation: MutationTypes,
    parseResponse = (responseData: any) => responseData
  ) =>
  async (
    { commit, state }: { commit: Commit; state: LocalState },
    capnogramId: string
  ) => {
    const capnogram = state.capnograms.find((c) => c.id === capnogramId);

    if (capnogram?.[field]) {
      // eslint-disable-next-line no-console
      console.log(`Capnogram "${field}" already in state: ${capnogramId}`);
      return;
    }

    const response = await apiClient.request<DiagnosticResult>({
      method: "GET",
      route: route,
      // prevent the API client from generating models for 404s
      expectedErrorCodes: [404],
    });

    if (response.status === 404) throw new NotFoundError(route, "GET");

    if (!response.ok)
      throw new HttpResponseError(route, "GET", response.status);

    const parsedData = parseResponse(response.data);

    commit(mutation, {
      capnogramId,
      ...parsedData,
    });
  };

function state(): LocalState {
  return {
    capnograms: [],
    handsets: null,
    upload_lifecycle_notification_history: [],
    handsetTestResultsParsed: {},
    breathRecordFailed: false,
  };
}

export default {
  namespaced: true,
  state,
  mutations: {
    [MutationTypes.RESET_STATE](
      _state: LocalState,
      exempt: Array<keyof LocalState>
    ) {
      const newState = state();
      for (const key of exempt) {
        delete newState[key];
      }
      Object.assign(_state, newState);
    },
    [MutationTypes.SET_BREATH_RECORD_FAILED](
      state: LocalState,
      status: boolean
    ) {
      state.breathRecordFailed = status;
    },
    [MutationTypes.SET_CAPNOGRAM_RAW](
      state: LocalState,
      rawCapnogram: ContainsCapnogramId & CapnogramRaw
    ) {
      genericCapnogramMutation("raw")(state, rawCapnogram);
    },
    [MutationTypes.SET_CAPNOGRAM_COMPUTED_FEATURES](
      state: LocalState,
      computedFeatures: ContainsCapnogramId & CapnogramComputedFeatures
    ) {
      genericCapnogramMutation("computedFeatures")(state, computedFeatures);
    },
    [MutationTypes.SET_CAPNOGRAM_DIAGNOSTIC_RESULT](
      state: LocalState,
      diagnosticResult: ContainsCapnogramId & DiagnosticResult
    ) {
      genericCapnogramMutation("diagnosticResult")(state, diagnosticResult);
    },
    [MutationTypes.SET_HANDSETS](state: LocalState, handsets: HandsetV2[]) {
      state.handsets = handsets;
    },
    /**
     * Stores pusher notification for uploads
     * @param state - State of the application [[src/store]] to mutate
     * @param notification - payload received from pusher
     * @category Vuex Mutation
     */
    [MutationTypes.ADD_UPLOAD_LIFECYCLE_NOTIFICATION](
      state: LocalState,
      notification: UploadLifecycleParsed
    ) {
      state.upload_lifecycle_notification_history.push(notification);
    },
    [MutationTypes.SET_HANDSET_TEST_RESULTS](
      state: LocalState,
      {
        testResults,
        handsetUdi,
      }: {
        testResults: HandsetTestResultsParsed[];
        handsetUdi: string;
      }
    ) {
      state.handsetTestResultsParsed = {
        ...state.handsetTestResultsParsed,
        [handsetUdi]: testResults,
      };
    },
  },
  actions: {
    [ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS](
      context: { commit: Commit; state: LocalState },
      status: boolean
    ) {
      if (context.state.breathRecordFailed === status) return;
      context.commit(MutationTypes.SET_BREATH_RECORD_FAILED, status);
    },
    async [ActionTypes.GET_CAPNOGRAM_RAW](
      context: { commit: Commit; state: LocalState },
      capnogramId: string
    ) {
      const parseResponse = (responseData: any) => {
        return {
          ...responseData,
          capturedAt: new Date(responseData.capturedAt),
          receivedAt: new Date(responseData.receivedAt),
        };
      };

      return genericCapnogramAction(
        "raw",
        `/v2/capnograms/${capnogramId}/`,
        MutationTypes.SET_CAPNOGRAM_RAW,
        parseResponse
      )(context, capnogramId);
    },

    async [ActionTypes.GET_CAPNOGRAM_COMPUTED_FEATURES](
      context: { commit: Commit; state: LocalState },
      capnogramId: string
    ) {
      return genericCapnogramAction(
        "computedFeatures",
        `/v2/capnograms/${capnogramId}/computedFeatures/`,
        MutationTypes.SET_CAPNOGRAM_COMPUTED_FEATURES
      )(context, capnogramId);
    },
    async [ActionTypes.GET_CAPNOGRAM_DIAGNOSTIC_RESULT](
      context: { commit: Commit; state: LocalState },
      capnogramId: string
    ) {
      return genericCapnogramAction(
        "diagnosticResult",
        `/v2/capnograms/${capnogramId}/diagnosis/`,
        MutationTypes.SET_CAPNOGRAM_DIAGNOSTIC_RESULT
      )(context, capnogramId);
    },
    async [ActionTypes.GET_HANDSETS]({
      commit,
      state,
    }: {
      commit: Commit;
      state: LocalState;
    }) {
      if (state.handsets !== null) {
        // eslint-disable-next-line no-console
        console.log("Using cached handsets");
      }

      const response = await apiClient.request<HandsetV2[]>({
        method: "GET",
        route: `/v2/handsets/`,
      });

      if (!response.ok) return;

      const handsets =
        response.data?.map((handset: HandsetV2) => ({
          ...handset,
          serialNumberStr: handset.serialNumberStr.slice(-5),
        })) ?? [];

      commit(MutationTypes.SET_HANDSETS, handsets);
    },
    // noinspection JSCommentMatchesSignature
    /**
     * Saves pusher notifications for the lifecycle of an upload
     * @param payload - data from pusher
     * @category Vuex Action
     */
    [ActionTypes.UPLOAD_LIFECYCLE_NOTIFICATION_RECEIVED](
      { commit }: { commit: Commit },
      payload: UploadLifecycle
    ) {
      commit(MutationTypes.ADD_UPLOAD_LIFECYCLE_NOTIFICATION, {
        ...payload,
        capturedAt: payload.capturedAt && new Date(payload.capturedAt),
        serverTime: payload.serverTime && new Date(payload.serverTime),
      });
    },
    async [ActionTypes.GET_HANDSET_TEST_RESULTS](
      {
        commit,
      }: {
        commit: Commit;
      },
      handsetUdi: string
    ) {
      const response = await apiClient.request<HandsetTestResults[]>({
        method: "GET",
        route: `/v2/handsets/${handsetUdi}/tests/`,
      });
      if (!response.ok || !response.data) return;
      const parsedResponse: HandsetTestResultsParsed[] = response.data.map(
        (testResult) => {
          return {
            ...testResult,
            capturedAt: new Date(testResult.capturedAt),
          };
        }
      );

      commit(MutationTypes.SET_HANDSET_TEST_RESULTS, {
        testResults: parsedResponse,
        handsetUdi,
      });
    },
  },
  getters: {
    [GetterTypes.GET_CAPNOGRAM_BY_ID](state: LocalState) {
      return (id: string): EnrichedCapnogram | null => {
        return state.capnograms.find((c) => c.id === id) ?? null;
      };
    },
    [GetterTypes.GET_CAPNOGRAM_START_TIME_BY_ID](
      _: LocalState,
      getters: GetterTree<LocalState, LocalState> & InternalGetters
    ): (id: string) => string | null {
      return (id) => {
        const capnogram: EnrichedCapnogram | null =
          getters[GetterTypes.GET_CAPNOGRAM_BY_ID](id);

        if (!capnogram?.raw) return null;

        return formatDate(capnogram.raw.capturedAt);
      };
    },
    [GetterTypes.GET_AVERAGE_WAVEFORM_CHART_BY_CAPNOGRAM_ID](
      _: LocalState,
      getters: GetterTree<LocalState, LocalState> & InternalGetters
    ): (capnogramId: string) => ChartAverageWaveform | null {
      return (capnogramId) => {
        const capnogram: EnrichedCapnogram | null =
          getters[GetterTypes.GET_CAPNOGRAM_BY_ID](capnogramId);

        if (!capnogram?.computedFeatures?.breathWaveformAverage) {
          return {
            ...emptyChart<ChartAverageWaveform>(true),
            bottomAnnotations: [],
          };
        }

        const { sampleTimes, sampleValues, sampleStdDeviations } =
          capnogram.computedFeatures.breathWaveformAverage;

        if (
          sampleTimes.length === 0 ||
          sampleValues.length === 0 ||
          sampleStdDeviations.length === 0
        ) {
          return {
            ...emptyChart<ChartAverageWaveform>(true),
            bottomAnnotations: [],
          };
        }

        // convert time series its cartesian coordinates
        const waveformData = sampleTimes.map((sampleTime, index) => {
          const y = sampleValues[index];
          const stdev = sampleStdDeviations[index];

          return {
            x: sampleTime,
            y: [y - stdev, y, y + stdev],
          };
        });

        const startX = waveformData[0].x;
        const endX = waveformData[waveformData.length - 1].x;

        // init empty array ready for pushes
        const bottomAnnotations: DeviceWaveformBottomAnnotation[] = [];
        // previous x location of the last change in the threshold state
        let previousThresholdX = 0;
        // whether the last point was above the threshold
        let previouslyAboveThreshold = false;

        // iterates through the average waveform points and finds the positions
        // of "Expiration" and "Inspiration" transitions
        waveformData.forEach((samplePoint, index) => {
          const y = normY(samplePoint.y);
          const currentlyAboveThreshold = y > THRESHOLD;
          // if the threshold state changed or last point in the series, then
          // record an annotation
          if (
            currentlyAboveThreshold !== previouslyAboveThreshold ||
            index === waveformData.length - 1
          ) {
            const x = scaleValue(samplePoint.x, [startX, endX], 100);
            bottomAnnotations.push({
              type: previouslyAboveThreshold ? "Expiration" : "Inspiration",
              width: x - previousThresholdX,
            });

            previousThresholdX = x;
          }
          previouslyAboveThreshold = currentlyAboveThreshold;
        });

        return {
          scales: [autoScale(waveformData)],
          series: [
            {
              data: waveformData,
            },
          ],
          bottomAnnotations,
        };
      };
    },
    [GetterTypes.GET_CHART_CAPNOGRAM_BY_ID](
      _: LocalState,
      getters: GetterTree<LocalState, LocalState> & InternalGetters
    ): (id: string) => ChartCapnogram | null {
      return (id) => {
        const capnogram: EnrichedCapnogram | null =
          getters[GetterTypes.GET_CAPNOGRAM_BY_ID](id);

        // if we have no capnogram nor the breath data return an empty chart
        if (!capnogram?.raw?.breathWaveform)
          return {
            ...emptyChart<ChartCapnogram>(false),
            annotations: [],
            quality: null,
          };

        const { sampleTimes, sampleValues } = capnogram.raw.breathWaveform;

        const data = sampleTimes.map((sampleTime, index) => ({
          x: sampleTime,
          y: sampleValues[index],
        }));

        const annotations: ChartAnnotation[] = [];

        const { computedFeatures } = capnogram;
        if (
          computedFeatures?.breathWaveformSplits &&
          computedFeatures?.breathErrors
        ) {
          computedFeatures.breathErrors.forEach((error, index) => {
            // ignore the non-errors indicated by empty string
            if (error === "") return;

            const errorStartInTime =
              sampleTimes[computedFeatures.breathWaveformSplits[index]];
            const errorEndInTime =
              sampleTimes[computedFeatures.breathWaveformSplits[index + 1]];

            annotations.push({
              type: "background-rect",
              x: errorStartInTime,
              x2: errorEndInTime,
            });
          });
        }

        return {
          scales: autoScale(data),
          series: { data },
          annotations,
          quality: null,
        };
      };
    },
    [GetterTypes.GET_HANDSET_BY_UDI](state: LocalState) {
      return (udi: string): HandsetV2 | null => {
        if (state.handsets === null) return null;

        return state.handsets.find((h) => h.udi === udi) || null;
      };
    },
    [GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID](
      _: LocalState,
      getters: GetterTree<LocalState, LocalState> & InternalGetters
    ): (capnogramId: string) => HandsetV2 | null {
      return (capnogramId) => {
        const capnogram = getters[GetterTypes.GET_CAPNOGRAM_BY_ID](capnogramId);

        if (!capnogram?.raw) return null;

        return getters[GetterTypes.GET_HANDSET_BY_UDI](
          capnogram.raw.handsetUdi
        );
      };
    },
    [GetterTypes.HANDSETS_SORTED_BY_SERIAL_NUMBER](state: LocalState) {
      if (state.handsets === null) return null;

      return [...state.handsets].sort(
        (a, b) => a.serialNumber - b.serialNumber
      );
    },
    [GetterTypes.MOST_RECENT_LIFE_CYCLE_NOTIFICATION](state: LocalState) {
      if (state.upload_lifecycle_notification_history.length === 0) return null;

      return state.upload_lifecycle_notification_history.slice(-1)[0];
    },
    [GetterTypes.TESTS_SORTED_BY_CAPTURED_AT](state: LocalState) {
      return (handsetUdi: string) => {
        if (!state.handsetTestResultsParsed[handsetUdi]) return null;
        return [...state.handsetTestResultsParsed[handsetUdi]]
          .filter(
            (test) =>
              !test.capturedAt || test.capturedAt >= getResultCutOffTime()
          )
          .sort(
            (
              test1: HandsetTestResultsParsed,
              test2: HandsetTestResultsParsed
            ) => Number(test2.capturedAt) - Number(test1.capturedAt)
          );
      };
    },
  },
};

export {
  ActionTypes,
  MutationTypes,
  GetterTypes,
  ntdPrefix,
  EnrichedCapnogram,
};
