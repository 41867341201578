
import { defineComponent } from "vue";
import Loader from "@/components/Loader.vue";
import { HandsetUDI } from "@/store/types";
import { NTD_LOAD_TEST_TIMEOUT_MINS } from "@/config";
import { ntdPrefix, ActionTypes } from "@/store/internal/modules/ntd";

export default defineComponent({
  components: {
    Loader,
  },
  computed: {
    handset(): HandsetUDI | undefined {
      return this.$store.state.userUDIs.find(
        (handset: HandsetUDI) => handset.udi === this.$route.params.udi
      );
    },
  },
  data() {
    let interval: NodeJS.Timeout | undefined;
    return {
      interval,
    };
  },
  methods: {
    failureCallback() {
      this.$store.dispatch(
        ntdPrefix(ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS),
        true
      );
    },
  },
  mounted() {
    this.interval = setTimeout(
      this.failureCallback,
      NTD_LOAD_TEST_TIMEOUT_MINS * 60 * 1000
    );
  },
  destroyed() {
    clearTimeout(this.interval);
  },
});
