
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

/**
 * Component for an individual tab header, designed to look like a paper divider. Expects a default slot for the text
 * to display within the header. When active, coloured white, otherwise pale blue. Will emit a `click` event when
 * clicked. See [[Tabs]] for a group of [[Tab]]s.
 *
 * ![](media://tab.png)
 */
@Component({
  name: "tab",
})
export default class Tab extends Vue {
  /**
   * Whether this is the currently selected tab. If it is, it will be coloured white not pale blue.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false }) active!: boolean;
}
