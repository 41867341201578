
import { defineComponent } from "vue";
import { formattedResultsMixin } from "@/components/mixins/formattedResultsMixin";
import { ntdPrefix, ActionTypes } from "@/store/internal/modules/ntd";
import PanelHeader from "@/views/common/PanelHeader.vue";
import Sidebar from "@/views/common/Sidebar.vue";

export default defineComponent({
  components: { PanelHeader, Sidebar },
  mixins: [formattedResultsMixin],
  data() {
    return { loading: true };
  },
  computed: {
    capnogramId(): string {
      return this.$route.params.capnogramId;
    },
  },
  mounted() {
    // Purposefully being explicit
    this.loading = true;

    const promises = Promise.all([
      this.$store.dispatch(
        ntdPrefix(ActionTypes.GET_CAPNOGRAM_RAW),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntdPrefix(ActionTypes.GET_CAPNOGRAM_COMPUTED_FEATURES),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntdPrefix(ActionTypes.GET_CAPNOGRAM_DIAGNOSTIC_RESULT),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(ntdPrefix(ActionTypes.GET_HANDSETS)),
    ])
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
          this.$router.go(-1);
        });
      });
  },
});
